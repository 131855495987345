import s from '../placesOfBusiness/places.module.scss';

export const optionFields = [
  { field: 'subjectName', inRussion: 'Имя субъекта' },
  { field: 'issuerName', inRussion: 'Имя эмитента' },
  { field: 'thumbprint', inRussion: 'Отпечаток' },
  { field: 'validFromDate', inRussion: 'Действителен с даты' },
  { field: 'validToDate', inRussion: 'Действителен по дату' },
  { field: 'isValid', inRussion: 'Действителен' },
  { field: 'validInfoMessage', inRussion: 'Сообщение о действительности' }
];

export const getValue = (certObject, fieldName) => {
  if (fieldName === 'isValid') {
    if (certObject[fieldName] === true) return 'да';
    else return 'нет';
  } else if (fieldName.slice(-4) === 'Date') {
    return new Intl.DateTimeFormat('ru-RU').format(new Date(certObject[fieldName]));
  } else {
    return certObject[fieldName];
  }
};

const CertificateSelect = ({ certificates, handleSelect }) => {
  return (
    <main className={s.optionMainWrapper} style={{ marginTop: '5px' }}>
      {certificates.map((cert, ind) => {
        return (
          <section
            key={cert.thumbprint}
            className={!!(ind % 2) ? s.singleOption_gray : s.singleOption_blue}
            onClick={() => handleSelect(cert.thumbprint, cert.isValid)}
          >
            {optionFields.map((field) => (
              <div className={s.optionRow} key={field.field}>
                <span className={s.optionFieldName}>{field.inRussion}</span>
                <span className={s.optionFieldValue}>{getValue(cert, field.field)}</span>
              </div>
            ))}
          </section>
        );
      })}
    </main>
  );
};

export default CertificateSelect;
