import clsx from 'clsx';
import { Checkbox, SearchBox } from 'office-ui-fabric-react';
import React, { useEffect, useState } from 'react';
import ConsignmentsAPI from '../../api/consignmentsAPI';
import { ConsignmentsFilter } from '../../components/Filters/ConsignmentsFilter/ConsignmentsFilter';
import { FiltersPanel } from '../../components/FiltersPanel/FiltersPanel';
import { Pagination } from '../../components/Pagination/Pagination';
import { Table } from '../../components/Table/Table';
import { TableAction } from '../../components/TableAction/TableAction';
import { addIcon, consignmentStatuses, moreInfoIcon, searchBoxStyles } from '../../constants';
import { getDateFromTimestamp, getStatusById } from '../../functions';
import { useCheckboxFilter, usePaginationAndSearch, useDataFilter } from '../../hooks';
import g from '../../assets/scss/Main.module.scss';
import s from './Consignments.module.scss';

const tableHeader = [
  {
    title: 'Номер',
    width: '0.1'
  },
  {
    title: 'Дата производства',
    width: '0.15'
  },
  {
    title: 'Продукт',
    width: '0.20'
  },
  {
    title: 'Производственная линия',
    width: '0.2'
  },
  {
    title: 'План. кол-во',
    width: '0.1'
  },
  {
    title: 'Факт. кол-во',
    width: '0.07'
  },
  {
    title: 'Статус',
    width: '0.08'
  },
  {
    title: 'Действия',
    width: '0.1'
  }
];

export const Consignments = ({ setIsOpenFilters, isOpenFilters, status, statusParamName }) => {
  const [consignments, setConsignments] = useState([]);
  const [loading, setLoading] = useState(false);
  const [query, setQuery] = useDataFilter('consignments');

  useEffect(() => {
    if (status && !query.status) {
      setQuery((prev) => ({ ...prev, status: status }), 'replace');
    }
  }, [status]);

  const [selectedFilters, onChangeFilter] = useCheckboxFilter(
    consignmentStatuses,
    'status',
    setQuery,
    query
  );

  const getConsignments = async (query) => {
    setLoading(true);
    let res = await ConsignmentsAPI.getConsignments(query);

    if (!res.statusCode) {
      const { list, quantity } = res;
      await setConsignments(list);
      await setTotalItems(quantity);
    }

    await setLoading(false);
  };

  const { onPageChanged, onSearchTermChanged, totalPages, setTotalItems, currentPage } =
    usePaginationAndSearch({ callback: getConsignments, query, setQuery, status, statusParamName });

  return (
    <div>
      <div className={g.titleWrapper}>
        <h1 className={g.title}>Задания</h1>
        <SearchBox
          styles={searchBoxStyles}
          value={query.searchValue}
          placeholder="Поиск по номеру задания"
          onClear={() => {
            onSearchTermChanged('');
          }}
          onSearch={(newValue) => {
            onSearchTermChanged(newValue);
          }}
        />
      </div>
      <div className={clsx(g.headerFilters)} style={{ marginTop: '1.25rem', width: '320px' }}>
        {consignmentStatuses.map((item) => {
          return (
            <Checkbox
              key={item.key}
              label={item.text}
              checked={selectedFilters?.has(item.key)}
              onChange={() => onChangeFilter(item.key)}
            />
          );
        })}
      </div>
      <Table headerItems={tableHeader} maxHeight="67vh" loading={loading}>
        {consignments.map((item) => {
          return (
            <div key={item.id} className={g.tableItem}>
              <div
                className={s.tableCell}
                style={{ width: `calc((100vw - 180px - 3.75rem) * ${tableHeader[0].width})` }}
              >
                {item.taskNumber}
              </div>
              <div
                className={s.tableCell}
                style={{ width: `calc((100vw - 180px - 3.75rem) * ${tableHeader[1].width})` }}
              >
                {getDateFromTimestamp(item.productCreationDate, null, { withoutTime: true })}
              </div>
              <div
                className={s.tableCell}
                style={{ width: `calc((100vw - 180px - 3.75rem) * ${tableHeader[2].width})` }}
              >
                {item.product?.name}
              </div>
              <div
                className={s.tableCell}
                style={{ width: `calc((100vw - 180px - 3.75rem) * ${tableHeader[3].width})` }}
              >
                {item.productLine?.name}{' '}
              </div>
              <div
                className={s.tableCell}
                style={{ width: `calc((100vw - 180px - 3.75rem) * ${tableHeader[4].width})` }}
              >
                {item.plannedValue}
              </div>
              <div
                className={s.tableCell}
                style={{ width: `calc((100vw - 180px - 3.75rem) * ${tableHeader[5].width})` }}
              >
                {item.actualValue}
              </div>
              <div
                className={s.tableCell}
                style={{ width: `calc((100vw - 180px - 3.75rem) * ${tableHeader[6].width})` }}
              >
                {getStatusById(item.status, consignmentStatuses)}
              </div>
              <div
                className={s.tableActionCell}
                style={{ width: `calc((100vw - 180px - 3.75rem) * ${tableHeader[7].width})` }}
              >
                <TableAction
                  link={`/consignments/${item.id}`}
                  text={'Подробнее'}
                  tooltipText={'Подробнее'}
                  iconName={moreInfoIcon}
                />
              </div>
            </div>
          );
        })}
      </Table>
      <div className={g.footer}>
        {totalPages > 1 && (
          <Pagination
            pageCount={totalPages}
            onPageChange={onPageChanged}
            selectedPage={currentPage}
          />
        )}
        <TableAction
          iconName={addIcon}
          link={`consignments/add`}
          text="Создать задание"
          addStyles={{ marginTop: '0.9375rem' }}
        />
      </div>
      <FiltersPanel
        isOpenFilters={isOpenFilters}
        setIsOpenFilters={setIsOpenFilters}
        query={query}
        setQuery={setQuery}
      >
        <ConsignmentsFilter />
      </FiltersPanel>
    </div>
  );
};
