import { accent, AGGREGATION_TYPE, MARKING_TYPE } from './constants';
import { orderStatuses, pairedOrderStatuses, orderStatuses20 } from './constants';

export const getDataFromLocalStorage = (key) => {
  return localStorage.getItem(key);
};

export const deleteDataFromLocalStorage = (key) => {
  return localStorage.removeItem(key);
};

export const convertMillisecondsToHMS = (milliseconds) => {
  if (!milliseconds) {
    return undefined
  }
  const totalSeconds = Math.floor(milliseconds / 1000);
  const hours = Math.floor(totalSeconds / 3600);
  const minutes = Math.floor((totalSeconds % 3600) / 60);
  const seconds = totalSeconds % 60;

  return `${String(hours).padStart(2, '0')}:${String(minutes).padStart(2, '0')}:${String(seconds).padStart(2, '0')}`;
}

export const getDateFromTimestamp = (timestamp, forOrder, options) => {
  let withoutTime = options?.withoutTime,
    delimiter = options?.delimiter || '/';

  if (!timestamp) {
    return '';
  }

  let timeZone = new Date().getTimezoneOffset() * 60 * 1000;
  let date;
  if (typeof timestamp === 'number') {
    date = new Date(timestamp);
  } else {
    date = new Date(Date.parse(timestamp) + timeZone * -1);
  }

  let day = formatDate(date.getDate());
  let month = formatDate(date.getMonth() + 1);
  let year = date.getFullYear();
  let hours = formatDate(date.getHours());
  let minutes = formatDate(date.getMinutes());
  let seconds = formatDate(date.getSeconds());

  if (forOrder) {
    return `${year.toString().slice(0, 2)}${month}${day}`;
  }

  return `${day}${delimiter}${month}${delimiter}${year} ${!withoutTime ? `${hours}:${minutes}:${seconds}` : ''
    }`;
};

export const formatDate = (date) => {
  return date.toString().length === 1 ? `0${date}` : date;
};

export const isShipped = (status) => {
  return status === 'ACCEPTED' || status === 'SENDED' || status === 'DISAGGREGATED';
};

export const formatLineStatus = (status) => {
  switch (status) {
    case 'PAUSE':
      return { status: 'Пауза/Пусконаладка', style: { color: accent } };
    case 'STOP':
      return { status: 'Остановлена', style: { color: 'red' } };
    default:
      return { status: 'Запущена', style: { color: 'green' } };
  }
};

export const mapItemsForDropdown = (items) => {
  return items.map((item) => ({
    text: item.name,
    key: item.id
  }));
};

export const mapLinesForDropdown = (items) => {
  return items.map((item) => ({
    label: `${item.name} (${item.lineNumber})`,
    value: +item.lineNumber,
    id: item.id
  }));
};

export const validateForm = (fields, errors, setErrors) => {
  let newErrors = new Set(errors);
  console.log('Validating fields:', fields);

  for (let key in fields) {
    const isValid = validateField(key, fields[key]);
    console.log(`Validating ${key}:`, { value: fields[key], isValid });
    
    if (!isValid) {
      newErrors.add(key);
    } else {
      newErrors.delete(key);
    }
  }

  console.log('Validation errors:', Array.from(newErrors));
  setErrors(newErrors);
  return newErrors.size === 0;
};

// Объект с правилами валидации
const validationRules = {
  // Базовые правила
  required: (value) => {
    if (value === null || value === undefined) return false;
    return value.toString().length > 0;
  },
  minLength: (value, min) => value && value.toString().length >= min,
  positiveNumber: (value) => Number(value) > 0,
  username: (value) => value && value.toString().length > 3 && value.match(/^[A-Za-z][A-Za-z0-9]*$/),
  dateAsNumber: (value) => !!value && value.toString().length && typeof value === 'number',
  
  // Специфичные правила для полей
  range: (value, min, max) => {
    if (!value && value !== 0) return true;
    const num = parseFloat(value);
    return !isNaN(num) && num >= min && num <= max;
  }
};

// Конфигурация валидации для полей
const fieldValidations = {
  firstName: ['required'],
  surname: ['required'],
  patronymic: ['required'],
  expDate: ['required'],
  lineName: ['required'],
  lineNumber: ['required'],
  newPassword: [['minLength', 5]],
  newPasswordAgain: [['minLength', 5]],
  quantity: ['positiveNumber'],
  copyQuantity: ['positiveNumber'],
  numerationStartIndex: ['positiveNumber'],
  userName: ['username'],
  productCreationDate: ['dateAsNumber'],
  productPackingDate: ['dateAsNumber'],
  product: ['required'],
  serialNumber: ['required'],
  taskNumber: ['required'],
  alcoholVolume: [['range', 0, 99.9]],
  temperature: [['range', -50, 100]]
};

export const validateField = (name, value) => {
  // Если для поля нет правил валидации, считаем его валидным
  if (!fieldValidations[name]) {
    return true;
  }

  // Проверяем все правила валидации для поля
  return fieldValidations[name].every(rule => {
    // Если правило - массив, то первый элемент - имя правила, остальные - параметры
    if (Array.isArray(rule)) {
      const [ruleName, ...params] = rule;
      const isValid = validationRules[ruleName](value, ...params);
      console.log(`Validating ${name} with rule ${ruleName}:`, { value, params, isValid });
      return isValid;
    }
    // Если правило - строка, то это имя правила без параметров
    const isValid = validationRules[rule](value);
    console.log(`Validating ${name} with rule ${rule}:`, { value, isValid });
    return isValid;
  });
};

export const serializeQueryObj = (queryObj) => {
  let str = [];
  for (let p in queryObj) {
    if ((queryObj.hasOwnProperty(p) && queryObj[p]) || queryObj[p] === 0) {
      str.push(encodeURIComponent(p) + '=' + encodeURIComponent(queryObj[p]));
    } else if (
      (p === 'maxRemainderOfMarkingCodes' && !queryObj[p]) ||
      (p === 'minRemainderOfMarkingCodes' && !queryObj[p])
    ) {
      str.push(encodeURIComponent(p) + '=' + encodeURIComponent(-1));
    }
  }
  return str.join('&');
};

export const getErrorTextByCode = (err) => {
  let errorCode = parseInt(err.message.replace(/[^\d]/g, ''));
  if (!isNaN(errorCode)) {
    return errorCode;
  } else {
    return err.message;
  }
};

export const findIntersectionOfArrays = (allItems, selectedItems, prop = 'key') => {
  const comparer = (otherArray) => {
    return function (current) {
      return (
        otherArray.filter((other) => {
          return other[prop] === current[prop];
        }).length === 0
      );
    };
  };

  let onlyInA = allItems.filter(comparer(selectedItems));
  let onlyInB = selectedItems.filter(comparer(allItems));

  return onlyInA.concat(onlyInB);
};

export const isNullishObj = (obj) => {
  let res = true;
  for (let key in obj) {
    if (obj[key]) {
      res = false;
      break;
    }
  }
  return res;
};

export const getStatusByEnum = (status) => {
  switch (status) {
    case 'REJECTED':
      return <span style={{ color: 'red' }}>Обработан с ошибкой</span>;
    case 'ACCEPTED':
      return <span style={{ color: 'green' }}>Успешно обработан</span>;
    case 'CREATED':
      return <span>Создан</span>;
    case 'SENDED':
      return <span>Отправлен</span>;
    case 'DISAGGREGATED':
      return <span>Разагрегирован</span>;
    case 'ACCEPTED_ARCHIVED':
      return <span>Успешно обработан и КМ архивированы</span>;
    case 'CREATED_ARCHIVED':
      return <span>Создан и КМ архивированы</span>;
    case 'REJECTED_ARCHIVED':
      return <span>Обработан с ошибкой и КМ архивированы</span>;
    default:
      return <span>Не определен</span>;
  }
};

export const getDropoutReasonByEnum = (status) => {
  switch (status) {
    case 'DEFECT':
      return <span>Дефект</span>;
    case 'EXPIRY':
      return <span>Истечение срока</span>;
    case 'QA_SAMPLES':
      return <span>Ээ</span>;
    case 'PRODUCT_RECALL':
      return <span>Продукт отозван</span>;
    case 'COMPLAINTS':
      return <span>Жалоба</span>;
    case 'PRODUCT_TESTING':
      return <span>Продукт на тестировании</span>;
    case 'DEMO_SAMPLES':
      return <span></span>;
    case 'DAMAGE_LOSS':
      return <span></span>;
    case 'DESTRUCTION':
      return <span>Уничтожен</span>;
    case 'LIQUIDATION':
      return <span>Ликвидирован</span>;
    case 'CONFISCATION':
      return <span>Конфискован</span>;
    case 'OTHER':
      return <span>Другое</span>;
    default:
      return <span>Не определен</span>;
  }
};

export const getStatusById = (statusId, statuses, propName = 'id') => {
  const rightStatus = statuses.find((st) => st.id === statusId);
  if (rightStatus) return rightStatus.text;
  else return '';
};

export const getStatusByName = (statusName, obj) => {
  for (let i = 0; i < obj.length; i++) {
    if (obj[i].key === statusName.toUpperCase()) {
      return obj[i].text;
    }
  }
};

export const mapProductsForDropdown = (items) => {
  return items.map((item) => ({
    label: `${item.name} (${item.gtin})`,
    text: `${item.name} (${item.gtin})`,
    name: item.name,
    value: item.id,
    key: item.id,
    gtin: item.gtin,
    brand: item.brand,
    category: item.category,
    subcategory: item.subcategory
  }));
};

export const checkDateError = (datesObj, errors, setError) => {
  const newErrors = new Set(errors);

  for (let dateObj in datesObj) {
    if (datesObj.hasOwnProperty(dateObj)) {
      const errorName = dateObj;
      const minDate = datesObj[dateObj][0];
      const maxDate = datesObj[dateObj][1];

      if ((minDate || maxDate) && !(minDate && maxDate)) {
        newErrors.add(errorName);
      } else {
        newErrors.delete(errorName);
      }
    }
  }

  setError(newErrors);
};

export const convertToUTCDate = (localDate) => {
  return Date.parse(localDate) - new Date().getTimezoneOffset() * 60 * 1000;
};

export const getCodeType = (code) => {
  if (code) {
    if (code.includes('\u001d')) {
      return MARKING_TYPE;
    }

    return AGGREGATION_TYPE;
  }
};

export const cropText = (text, maxLength = 20) => {
  if (!text) {
    return '';
  }

  let trimmedString = text.substr(0, maxLength);

  return trimmedString.length < text.length ? `${trimmedString}...` : trimmedString;
};

export const tableCellWidth = (tableHead) => (ind) =>
  `calc((100vw - 180px - 3.75rem)*${tableHead[ind]?.width}`;
export const cellWidth = (headerWidths, ind) =>
  `calc((100vw - 180px - 3.75rem)*${headerWidths[ind]?.width}`;

// export const tableCellWidth = (tableHead) => (ind) =>
//   `calc((100vw - 180px - 3.75rem)*${tableHead[ind]?.width}`;

export const isResourseLink = (path, resourseName) => {
  const regex = new RegExp(`/${resourseName}/.+`, 'gm');
  return regex.test(path);
};

export const formatTwoPartDate = (tStamp) => {
  if (!tStamp) return '';
  const date = new Date(tStamp);
  const mainPartFormatFunc = { year: 'numeric', day: '2-digit', month: '2-digit' };
  const toolTipPartFormatFunc = { hour: '2-digit', minute: '2-digit', second: '2-digit' };
  const mainPartFormat = new Intl.DateTimeFormat('ru-RS', mainPartFormatFunc);
  const toolTipPartFormat = new Intl.DateTimeFormat('ru-RS', toolTipPartFormatFunc);
  const mainPart = mainPartFormat.format(date);
  const toolTipPart = toolTipPartFormat.format(date);
  return { mainPart, toolTipPart };
};

const errorsToIgnore = [[204, 'Файл конфигурации не найден']];
export const ignoreErrors = (sCode, msg) => {
  return !!errorsToIgnore.find((error) => {
    return error[0] === sCode && error[1] === msg;
  });
};

export const seperateeThousands = (str) => {
  if (str.length === 0) return str;
  if (str.includes(',')) str.replace(',', '.');
  const newStr = Number(str).toString();
  let right = newStr.includes(',') ? newStr.indexOf(',') : newStr.length;
  const arr = newStr.split('');
  while (right > 0) {
    right = right - 3;
    if (right > 0) arr.splice(right, 0, ' ');
  }
  const finalStr = arr.join('');
  if (finalStr.includes('.')) finalStr.replace('.', ',');
  return finalStr;
};

export const changeButtonText = (set, type, reports, setterFunc) => {
  if (type === 'entering') {
    const statuses = [];
    for (const id of set) {
      if (reports.find((rep) => rep.id === id).status === 'ACCEPTED') {
        statuses.push(id);
      }
    }
    if (statuses.length > 0) setterFunc('Отправить корректировку');
    else setterFunc('Отправить вбанные');
  }
};
