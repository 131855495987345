import { TextField } from 'office-ui-fabric-react';
import React, { useEffect, useState } from 'react';
import { useHistory, useLocation, useParams } from 'react-router-dom';
import LinesAPI from '../../api/linesAPI';
import { AddItemLineModal } from '../../components/AddItemLineModal/AddItemLineModal';
import { Alert } from '../../components/Alert/Alert';
import { BottomButtons } from '../../components/BottomButtons/BottomButtons';
import { Confirm } from '../../components/Confirm/Confirm';
import { Loader } from '../../components/Loader/Loader';
import { Table } from '../../components/Table/Table';
import { TableAction } from '../../components/TableAction/TableAction';
import { addIcon, deleteIcon } from '../../constants';
import { useAppContext } from '../../context/AppContext';
import { useCrumbsContext } from '../../context/CrumbsContext';
import { validateForm, cellWidth } from '../../functions';
import { useAlert, useSendRequest } from '../../hooks';
import g from '../../assets/scss/Main.module.scss';
import s from './Line.module.scss';

const productsTableHeader = [
  {
    title: 'Название',
    width: '0.34'
  },
  {
    title: 'Gtin',
    width: '0.33'
  },
  {
    title: 'Действия',
    width: '0.33'
  }
];

const storageTableHeader = [
  {
    title: 'Наименование',
    width: '50%'
  },
  {
    title: 'Действия',
    width: '4.375rem'
  }
];

const usersTableHeader = [
  {
    title: 'Логин',
    width: '0.3'
  },
  {
    title: 'ФИО',
    width: '0.5'
  },
  {
    title: 'Действия',
    width: '0.2'
  }
];

const emptyLine = {
  lineName: '',
  lineNumber: '',
  products: [],
  storage: [],
  users: [],
  status: 'STOP'
};

export const Line = () => {
  const [loading, setLoading] = useState(false);
  const [line, setLine] = useState(emptyLine);
  const [editMode, setEditMode] = useState(true);
  const { setCrumbs } = useCrumbsContext();
  const [isAddItemModalOpened, setIsAddItemModalOpened] = useState(false);
  const [addedType, setAddedType] = useState('');
  const [isConfirmShowed, setIsConfirmShowed] = useState(false);
  const [selectedItems, setSelectedItems] = useState([]);
  const [lineErrors, setLineErrors] = useState(new Set([]));

  const { setIsMenuDisabled } = useAppContext();
  const { lineID } = useParams();
  const location = useLocation();
  const history = useHistory();

  const { alertTitle, alertText, isAlertShowed, hideAlert, showAlert } = useAlert();
  const { afterSending } = useSendRequest(setLoading);

  // useBackBtn('/lines')

  const getLine = async () => {
    setLoading(true);
    const res = await LinesAPI.getLineByID(lineID);

    if (res.statusCode) {
      history.push('/lines');
    } else {
      await setLine(res);
    }

    setLoading(false);
  };

  useEffect(() => {
    if (lineID === 'add') {
      setEditMode(false);
    } else {
      getLine(lineID);
    }

    setIsMenuDisabled(true);

    return () => {
      setIsMenuDisabled(false);
    };
  }, []);

  useEffect(() => {
    setCrumbs([
      location.pathname.slice(0, location.pathname.lastIndexOf('/')),
      `Линия ${line.lineName || ''}`
    ]);
  }, [line]);

  const displayAddItemModal = (type) => {
    setAddedType(type);
    setSelectedItems(getSelectedItems(type));
    setIsAddItemModalOpened(true);
  };

  const addItem = (type, item) => {
    if (type === 'product') {
      setLine((prev) => ({
        ...prev,
        products: [
          ...prev.products,
          {
            name: item.text.slice(0, item.text.indexOf('(') - 1),
            id: item.key,
            gtin: item.gtin
          }
        ]
      }));
    }

    if (type === 'storage') {
      setLine((prev) => ({
        ...prev,
        storage: [...prev.storage, { name: item.text, id: item.key }]
      }));
    }

    if (type === 'user') {
      const addedUser = line.users.find((u) => u.id === item.id);
      if (!addedUser) {
        const newUser = { ...item, name: item.text.slice(0, item.text.indexOf('(') - 1) };
        delete newUser.text;
        delete newUser.key;
        setLine((prev) => ({ ...prev, users: [...prev.users, newUser] }));
      }
    }
    setIsAddItemModalOpened(false);
  };

  const deleteLine = async () => {
    const onSuccess = () => {
      showAlert('Удаление линии', 'Линия успешно удалена');
    };

    const res = await LinesAPI.deleteLine(lineID);
    afterSending(res, onSuccess);
  };

  const deleteItem = (type, id) => {
    if (type === 'product') {
      setLine((prev) => ({ ...prev, products: prev.products.filter((item) => item.id !== id) }));
    }

    if (type === 'user') {
      setLine((prev) => ({ ...prev, users: prev.users.filter((item) => item.id !== id) }));
    }

    if (type === 'storage') {
      setLine((prev) => ({ ...prev, storage: prev.storage.filter((item) => item.id !== id) }));
    }
  };

  const validateLine = () => {
    return validateForm(
      {
        lineName: line.lineName,
        lineNumber: line.lineNumber
      },
      lineErrors,
      setLineErrors
    );
  };

  const updateLine = () => {
    const onSuccess = () => {
      showAlert('Обновление линии', 'Линия успешно обновлена');
    };

    if (validateLine()) {
      LinesAPI.updateLine(line).then((res) => {
        afterSending(res, onSuccess);
      });
    }
  };

  const addLine = () => {
    const onSuccess = () => {
      showAlert('Добавление линии', 'Линия успешно добавлена');
    };

    if (validateLine()) {
      LinesAPI.addLine(line).then((res) => {
        afterSending(res, onSuccess);
      });
    }
  };

  const getSelectedItems = (type) => {
    console.log('from getSelectedItems', type, line);
    if (type === 'storage') {
      return line[`${type}`];
    }
    return line[`${type}s`];
  };

  return (
    <>
      {loading && <Loader />}
      {isAlertShowed && (
        <Alert title={alertTitle} text={alertText} onClose={() => history.push('/lines')} />
      )}
      {isConfirmShowed && (
        <Confirm
          title="Удаление линии"
          subText="Вы действительно хотите удалить линию?"
          declineText="Отмена"
          okText="Удалить"
          onSend={deleteLine}
          setIsShow={setIsConfirmShowed}
        />
      )}
      <div className={s.tables}>
        <div className={g.titleWrapper}>
          <h1 className={g.title}>{`Линия ${line?.lineName || ''} (№${line.lineNumber || ''})`}</h1>
          {editMode && (
            <TableAction
              iconName={deleteIcon}
              text="Удалить линию"
              isButton={true}
              type="danger"
              onClick={() => setIsConfirmShowed(true)}
            />
          )}
        </div>
        <div className={s.inputRow}>
          <TextField
            errorMessage={lineErrors.has('lineName') && 'Обязательное поле'}
            required={true}
            label="Название линии"
            value={line.lineName}
            onChange={(e) => setLine((prev) => ({ ...prev, lineName: e.target.value }))}
          />
        </div>
        <div className={s.inputRow}>
          <TextField
            errorMessage={lineErrors.has('lineNumber') && 'Обязательное поле'}
            required={true}
            label="Номер линии"
            value={line.lineNumber}
            onChange={(e) => setLine((prev) => ({ ...prev, lineNumber: e.target.value }))}
          />
        </div>
        <div className={s.lineTable}>
          <div className={g.header}>
            <span className={`${g.headerTitle} ${s.headerTitle}`}>Продукты</span>
          </div>

          <Table headerItems={productsTableHeader} maxHeight="50vh" isFixedHeight={false}>
            {line.products.map((item) => {
              return (
                <div key={item.id} className={g.tableItem}>
                  <div className={g.tableCell} style={{ width: cellWidth(productsTableHeader, 0) }}>
                    {item.name}
                  </div>
                  <div className={g.tableCell} style={{ width: cellWidth(productsTableHeader, 1) }}>
                    {item.gtin}
                  </div>
                  <div
                    style={{ width: cellWidth(productsTableHeader, 2) }}
                    className={`${s.productsActionsItems}`}
                  >
                    <TableAction
                      isButton={true}
                      iconName={deleteIcon}
                      type="danger"
                      onClick={() => deleteItem('product', item.id)}
                    />
                  </div>
                </div>
              );
            })}
          </Table>

          <div className={s.lineTableFooter}>
            {!line.products.length && (
              <span className={s.error}>Добавьте хотя бы один продукт</span>
            )}
            <div className={s.addBtn}>
              <TableAction
                iconName={addIcon}
                onClick={() => displayAddItemModal('product')}
                text="Добавить продукт"
                isButton
                addStyles={{ marginLeft: 'auto' }}
              />
            </div>
          </div>
        </div>

        <div className={s.lineTable}>
          <div className={g.header}>
            <span className={`${g.headerTitle} ${s.headerTitle}`}>Пользователи</span>
          </div>
          <Table headerItems={usersTableHeader} maxHeight="50vh" isFixedHeight={false}>
            {line.users.map((item) => {
              return (
                <div key={item.id} className={g.tableItem}>
                  <div className={g.tableCell} style={{ width: cellWidth(usersTableHeader, 0) }}>
                    {item.userName}
                  </div>
                  <div
                    className={g.tableCell}
                    style={{ width: cellWidth(usersTableHeader, 1) }}
                  >{`${item.firstName} ${item.surname} ${item.patronymic}`}</div>
                  <div
                    style={{ width: cellWidth(usersTableHeader, 2) }}
                    className={`${s.productsActionsItems}`}
                  >
                    <TableAction
                      isButton={true}
                      iconName={deleteIcon}
                      type="danger"
                      onClick={() => deleteItem('user', item.id)}
                    />
                  </div>
                </div>
              );
            })}
          </Table>
          <div className={s.addBtn}>
            <TableAction
              iconName={addIcon}
              onClick={() => displayAddItemModal('user')}
              text="Добавить пользователя"
              isButton
            />
          </div>
        </div>
        {!isAddItemModalOpened && (
          <div className={s.buttons}>
            <BottomButtons
              okText={lineID === 'add' ? 'Добавить' : 'Сохранить'}
              closeText="Назад"
              okHandler={() => {
                if (lineID === 'add') {
                  addLine();
                } else {
                  updateLine();
                }
              }}
              disabledButtons={[!line.products.length, false]}
              closeHandler={() => history.push('/lines')}
            />
          </div>
        )}
      </div>
      {isAddItemModalOpened && (
        <AddItemLineModal
          type={addedType}
          selectedItems={selectedItems}
          onSubmit={addItem}
          isOpened={isAddItemModalOpened}
          onClose={() => setIsAddItemModalOpened(false)}
          setIsOpened={setIsAddItemModalOpened}
        />
      )}
    </>
  );
};
