import { useState } from 'react';
import AddForm from 'pages/Products/AddSideProduct/AddForm';
import { Modal } from 'office-ui-fabric-react';
import FormContainer from 'components/Forms/FormContainer';
import { Loader } from 'components/Loader/Loader';
import s from './SideProduct.module.scss';
import { NEW_SIDEPRODUCT_VALIDATION_SCHEMA } from 'pages/Products/AddSideProduct/addSideProducts.constants';
import useGetData from 'pages/Products/AddSideProduct/UseGetData';

const EditSiteProductModal = ({ onDismiss, isOpen, setSideProduct, sideProduct }) => {
  const [loading, setLoading] = useState(false);
  const [companies, owners] = useGetData(setLoading);
  const onSubmit = async (data) => {
    setSideProduct(data);
    onDismiss();
  };

  return (
    <Modal containerClassName={s.modal} isOpen={isOpen} onDismiss={onDismiss} isBlocking={false}>
      {loading ? (
        <Loader />
      ) : (
        <>
          <div>
            <h4 className={s.label}>Редактировать сторонний продукт</h4>
          </div>

          <FormContainer
            enableReinitialize
            initialValues={sideProduct}
            onSubmit={onSubmit}
            className={s.formWrapper}
            validationSchema={NEW_SIDEPRODUCT_VALIDATION_SCHEMA}
          >
            {(formik) => {
              const { isValid } = formik;
              return <AddForm isValid={isValid} companies={companies} owners={owners} />;
            }}
          </FormContainer>
        </>
      )}
    </Modal>
  );
};

export default EditSiteProductModal;
