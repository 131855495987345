export const GET_PRINTERS_START = 'GET_PRINTERS_START';
export const GET_PRINTERS_SUCCESS = 'GET_PRINTERS_SUCCESS';
export const GET_PRINTERS_ERROR = 'GET_PRINTERS_ERROR';
export const PRINTERS_RESET_STATE = 'PRINTERS_RESET_STATE';

const initialState = {
    loading: false,
    errors: '',
    printers: [],
}

export const printersReducer = (state = initialState, action) => {
    switch (action.type) {
        case GET_PRINTERS_START: 
            return {
                ...state,
                loading: true,
                errors: '',
            }
        case GET_PRINTERS_SUCCESS: 
            return {
                ...state,
                loading: false,
                errors: '',
                printers: action.payload
            }
        case GET_PRINTERS_ERROR:
            return {
                ...state,
                loading: false,
                errors: action.payload,
            }
        case PRINTERS_RESET_STATE:
            return initialState
        default:
            return state
    }
}

export const getPrintersAction = (payload) => ({
    type: GET_PRINTERS_START,
    payload
})

export const resetPrintersAction = () => ({
    type: PRINTERS_RESET_STATE
})