import React from 'react'
import s from './BottomButtons.module.scss'
import {DefaultButton, PrimaryButton} from 'office-ui-fabric-react'

export const BottomButtons = ({okText, okHandler, closeText = 'Отменить', closeHandler, disabledButtons = []}) => {
    return (
        <div className={s.buttons}>
            <PrimaryButton className={s.btn} disabled={disabledButtons[0]} text={okText} onClick={okHandler}/>
            {closeHandler &&
            <DefaultButton className={s.btn} disabled={disabledButtons[1]} text={closeText} onClick={closeHandler}/>}
        </div>
    )
}
