import AddToShoppingList from './assets/img/orderCodesIcon.svg';
import PageList from './assets/img/PageList.svg';
import WaitlistConfirm from './assets/img/WaitlistConfirm.svg';
import Product from './assets/img/Product.svg';
import Info from './assets/img/Info.svg';
import Sync from './assets/img/Sync.svg';
import Add from './assets/img/Add.svg';

export const LOCAL_STORAGE_DATA_NAME = 'uplineAdminData';

export const bgColor = '#F8F8F8';
export const lightBlue = '#C7E0F4';
export const accent = '#0078D4';

export const orderCodesIcon = AddToShoppingList;
export const listCodesIcon = PageList;
export const ordersCodesIcon = WaitlistConfirm;
export const productCardIcon = Product;
export const moreInfoIcon = Info;
export const syncIcon = Sync;
export const sendIcon = 'Send';
export const editIcon = 'Edit';
export const deleteIcon = 'Delete';
export const addIcon = Add;

export const wideScreen = 1900;

export const DayPickerStrings = {
  months: [
    'Январь',
    'Февраль',
    'Март',
    'Апрель',
    'Май',
    'Июнь',
    'Июль',
    'Август',
    'Сентябрь',
    'Октябрь',
    'Ноябрь',
    'Декабрь'
  ],
  shortMonths: ['Янв', 'Фев', 'Мар', 'Апр', 'Май', 'Июн', 'Июл', 'Авг', 'Сен', 'Окт', 'Ноб', 'Дек'],
  days: ['Воскресенье', 'Понедельник', 'Вторник', 'Среда', 'Четверг', 'Пятница', 'Суббота'],
  shortDays: ['ВС', 'ПН', 'ВТ', 'СР', 'ЧТ', 'ПТ', 'СБ'],
  goToToday: 'К сегодня',
  invalidInputErrorMessage: 'Неправильный формат даты'
};

export const searchBoxStyles = {
  root: { width: '18.75rem', background: bgColor, borderColor: lightBlue }
};

// export const reportStatuses = [
//   {
//     id: 0,
//     text: 'Создан',
//     key: 'CREATED'
//   },
//   {
//     id: 1,
//     text: 'Отправлен',
//     key: 'SENDED'
//   },
//   {
//     id: 1,
//     text: 'Отправлен',
//     key: 'SENDED'
//   }
// ];

export const reportStatuses = [
  {
    id: 0,
    text: 'Создан',
    key: 'CREATED'
  },
  {
    id: 1,
    text: 'Отправлен',
    key: 'SENDED'
  },
  {
    id: 2,
    text: 'Разагрегирован',
    key: 'DISAGGREGATED'
  },
  {
    id: 3,
    text: 'Успешно обработан',
    key: 'ACCEPTED'
  },
  {
    id: 4,
    text: 'Обработан с ошибкой',
    key: 'REJECTED'
  },
  {
    id: 1000,
    text: 'Успешно обработан и КМ архивированы',
    key: 'ACCEPTED_ARCHIVED'
  },
  {
    id: 1001,
    text: 'Создан и КМ архивированы',
    key: 'CREATED_ARCHIVED'
  },
  {
    id: 1002,
    text: 'Обработан с ошибкой и КМ архивированы',
    key: 'REJECTED_ARCHIVED'
  }
];

export const reportStatuseForPrinting = [
  {
    id: 0,
    text: 'Создан',
    key: 'CREATED'
  },
  {
    id: 1,
    text: 'Отправлен',
    key: 'SENDED'
  },
  {
    id: 3,
    text: ' Успешно обработан',
    key: 'ACCEPTED'
  },
  {
    id: 4,
    text: 'Обработан с ошибкой',
    key: 'REJECTED'
  }
];

export const orderStatuses = [
  {
    id: 0,
    text: 'Создан',
    key: 'CREATED'
  },
  {
    id: 1,
    text: 'Отправлен',
    key: 'SENDED'
  },
  {
    id: 2,
    text: 'Ожидает подтверждения',
    key: 'PENDING'
  },
  {
    id: 3,
    text: 'Не подтверждён',
    key: 'DECLINED'
  },
  {
    id: 4,
    text: 'Подтверждён',
    key: 'APPROVED'
  },
  {
    id: 5,
    text: 'Подписан',
    key: 'READY'
  },
  {
    id: 6,
    text: 'Закрыт',
    key: 'CLOSED'
  },
  {
    id: 7,
    text: 'Аннулирован',
    key: 'EXPIRED'
  },
  {
    id: 8,
    text: 'Создан и КМ архивированы',
    key: 'CREATED_ARCHIVED'
  },
  {
    id: 9,
    text: 'Отправлен и КМ архивированы',
    key: 'SENDED_ARCHIVED'
  },
  {
    id: 10,
    text: 'Ожидает подтверждения и КМ архивированы',
    key: 'PENDING_ARCHIVED'
  },
  {
    id: 11,
    text: 'Не подтвержден и КМ архивированы',
    key: 'DECLINED_ARCHIVED'
  },
  {
    id: 12,
    text: 'Подтвержден и КМ архивированы',
    key: 'APPROVED_ARCHIVED'
  },
  {
    id: 13,
    text: 'Готов (подписан) и КМ архивированы',
    key: 'READY_ARCHIVED'
  },
  {
    id: 14,
    text: 'Закрыт и КМ архивированы',
    key: 'CLOSED_ARCHIVED'
  },
  {
    id: 15,
    text: 'Аннулирован и КМ архивированы',
    key: 'EXPIRED_ARCHIVED'
  },
  {
    id: 17,
    text: 'Отклонен и КМ архивированы',
    key: 'REJECTED_ARCHIVED '
  },
  {
    id: 19,
    text: 'Опустошен и КМ архивированы',
    key: 'EXHAUSTED_ARCHIVED'
  }
];
export const orderStatuses20 = [
  {
    id: 20,
    text: 'Создан и КМ списаны',
    key: 'CREATED_WRITTEN_OFF'
  },
  {
    id: 21,
    text: 'Отправлен и КМ списаны',
    key: 'SENDED_WRITTEN_OFF'
  },
  {
    id: 22,
    text: 'Ожидает подтверждения и КМ списаны',
    key: 'PENDING_WRITTEN_OFF'
  },
  {
    id: 23,
    text: 'Не подтвержден и КМ списаны',
    key: 'DECLINED_WRITTEN_OFF'
  },
  {
    id: 24,
    text: 'Подтвержден и КМ списаны',
    key: 'APPROVED_WRITTEN_OFF'
  },
  {
    id: 25,
    text: 'Готов (подписан) и КМ списаны',
    key: 'READY_WRITTEN_OFF'
  },
  {
    id: 26,
    text: 'Закрыт и КМ списаны',
    key: 'CLOSED_WRITTEN_OFF'
  },
  {
    id: 27,
    text: 'Аннулирован и КМ списаны',
    key: 'EXPIRED_WRITTEN_OFF'
  },
  {
    id: 28,
    text: 'Отклонен и КМ списаны',
    key: 'REJECTED_WRITTEN_OFF'
  },
  {
    id: 29,
    text: 'Опустошен и КМ списаны',
    key: 'EXHAUSTED_WRITTEN_OFF'
  }
];

export const pairedOrderStatuses = [
  {
    id: 0,
    text: 'Создан',
    key: 'CREATED',
    pairedStatusId: 8,
    secondPairdStatusId: 20
  },
  {
    id: 1,
    text: 'Отправлен',
    key: 'SENDED',
    pairedStatusId: 9,
    secondPairdStatusId: 21
  },
  {
    id: 2,
    text: 'Ожидает подтверждения',
    key: 'PENDING',
    pairedStatusId: 10,
    secondPairdStatusId: 22
  },
  {
    id: 3,
    text: 'Не подтверждён',
    key: 'DECLINED',
    pairedStatusId: 11,
    secondPairdStatusId: 23
  },
  {
    id: 4,
    text: 'Подтверждён',
    key: 'APPROVED',
    pairedStatusId: 12,
    secondPairdStatusId: 24
  },
  {
    id: 5,
    text: 'Подписан',
    key: 'READY',
    pairedStatusId: 13,
    secondPairdStatusId: 25
  },
  {
    id: 6,
    text: 'Закрыт',
    key: 'CLOSED',
    pairedStatusId: 14,
    secondPairdStatusId: 26
  },
  {
    id: 7,
    text: 'Аннулирован',
    key: 'EXPIRED',
    pairedStatusId: 15,
    secondPairdStatusId: 27
  },
  {
    id: 16,
    text: 'Обработан с ошибкой',
    key: 'REJECTED',
    pairedStatusId: 17,
    secondPairdStatusId: 28
  },
  {
    id: 18,
    text: 'Опустошен',
    key: 'EXHAUSTED',
    pairedStatusId: 19,
    secondPairdStatusId: 29
  }
];

export const codeFilters = [
  {
    id: 1,
    title: 'Новый',
    key: 'NEW'
  },
  {
    id: 2,
    title: 'Нанесен',
    key: 'UTILISATED'
  },
  {
    id: 3,
    title: 'В агрегате',
    key: 'AGGREGATED'
  },
  {
    id: 4,
    title: 'В обороте',
    key: 'INROLLOUT'
  },
  {
    id: 5,
    title: 'Отсканирован',
    key: 'SCANED'
  },
  {
    id: 6,
    title: 'Недействителен',
    key: 'INVALID'
  }
];

export const lineStatuses = [
  {
    text: 'Запущена',
    key: 'RUN'
  },
  {
    text: 'Остановлена',
    key: 'STOP'
  },
  {
    text: 'Пауза/Пусконаладка',
    key: 'PAUSE'
  }
];

export const consignmentStatuses = [
  { id: 0, key: 'NEW', text: 'Новое' },
  { id: 1, key: 'IN PROGRESS', text: 'В работе' },
  { id: 2, key: 'CLOSE', text: 'Закрыто' }
];

export const consignmentsForDropdown = [
  { key: 0, text: 'Новое' },
  { key: 1, text: 'В работе' },
  { key: 2, text: 'Закрыто' }
];

export const certificateTypeDropdown = [
  { key: 'CONFORMITY_CERTIFICATE', text: 'Сертификат соответствия' },
  { key: 'CONFORMITY_DECLARATION', text: 'Декларация о соответствии' },
  { key: 'STATE_REGISTRATION_CERTIFICATE', text: 'Свидетельство о государственной регистрации' }
];

export const aggregateStatuses = [
  { id: 0, key: 'NEW', text: 'Новый' },
  { id: 1, key: 'FORMED', text: 'Сформирован' },
  { id: 2, key: 'DISAGGREGATED', text: 'Разагрегирован' }
];

export const pagesWithFilters = [
  '/products',
  '/products/codesList',
  '/aggregates',
  '/reports/printing',
  '/reports/aggregation',
  '/reports/entering',
  '/reports/withdrwInternConsump',
  '/consignments',
  '/lines',
  '/users'
];

export const pageSize = 10;

export const AGGREGATION_TYPE = 1;
export const MARKING_TYPE = 16;

export const FULL_ACCESS = 3;
export const CODES_CONTROL_ACCESS = 1;
export const NO_ACCESS = 0;

export const ACCESS_LEVELS = {
  0: FULL_ACCESS,
  1: NO_ACCESS,
  2: NO_ACCESS,
  3: CODES_CONTROL_ACCESS,
  4: NO_ACCESS
};
export const getAccessLevel = (roles) => {
  const rolesIdArr = roles.map((role) => role.id);
  return rolesIdArr.reduce((res, cur) => {
    return res | ACCESS_LEVELS[cur];
  }, 0);
};
