import { DatePicker, DayOfWeek, Label, Stack } from 'office-ui-fabric-react';
import React, { useEffect, useState } from 'react';
import { checkDateError, convertToUTCDate, getDateFromTimestamp } from '../../../functions';
import { setFilteredData } from 'redux/App/appReducer';
import { FilterButtons } from '../FilterButtons/FilterButtons';
import s from './CodesListFilter.module.scss';
import g from '../../../assets/scss/Main.module.scss';
import { DayPickerStrings } from '../../../constants';
import { useDispatch } from 'react-redux';

export const CodesListFilter = ({ query, setQuery, setIsOpenFilters }) => {
  const [minAggregationDate, setMinAggregationDate] = useState(
    query?.minAggregationDate ? new Date(query.minAggregationDate) : null
  );
  const [maxAggregationDate, setMaxAggregationDate] = useState(
    query?.maxAggregationDate ? new Date(query.maxAggregationDate) : null
  );
  const [minUtilisationDate, setMinUtilisationDate] = useState(
    query?.minUtilisationDate ? new Date(query.minUtilisationDate) : null
  );
  const [maxUtilisationDate, setMaxUtilisationDate] = useState(
    query?.maxUtilisationDate ? new Date(query.maxUtilisationDate) : null
  );
  const [minCreationDate, setMinCreationDate] = useState(
    query?.minCreationDate ? new Date(query.minCreationDate) : null
  );
  const [maxCreationDate, setMaxCreationDate] = useState(
    query?.maxCreationDate ? new Date(query.maxCreationDate) : null
  );
  const [minRolloutDate, setMinRolloutDate] = useState(
    query?.minRolloutDate ? new Date(query.minRolloutDate) : null
  );
  const [maxRolloutDate, setMaxRolloutDate] = useState(
    query?.maxRolloutDate ? new Date(query.maxRolloutDate) : null
  );
  const [dateErrors, setDateErrors] = useState(new Set([]));
  const dispatch = useDispatch();

  const onConfirm = () => {
    const filterObj = {
      minAggregationDate,
      maxAggregationDate,
      minUtilisationDate,
      maxUtilisationDate,
      minCreationDate,
      maxCreationDate,
      minRolloutDate,
      maxRolloutDate
    };

    if (!dateErrors.size) {
      setQuery(
        (prev) => ({
          ...prev,
          minCreationDate: minCreationDate ? convertToUTCDate(minCreationDate) : undefined,
          maxCreationDate: maxCreationDate ? convertToUTCDate(maxCreationDate) : undefined,
          minAggregationDate: minAggregationDate ? convertToUTCDate(minAggregationDate) : undefined,
          maxAggregationDate: maxAggregationDate ? convertToUTCDate(maxAggregationDate) : undefined,
          minUtilisationDate: minUtilisationDate ? convertToUTCDate(minUtilisationDate) : undefined,
          maxUtilisationDate: maxUtilisationDate ? convertToUTCDate(maxUtilisationDate) : undefined,
          minRolloutDate: minRolloutDate ? convertToUTCDate(minRolloutDate) : undefined,
          maxRolloutDate: maxRolloutDate ? convertToUTCDate(maxRolloutDate) : undefined,
          pageNumber: 1
        }),
        'replace'
      );
      setIsOpenFilters(false);
    }

    dispatch(setFilteredData(filterObj));
  };

  const onDismiss = () => {
    setMinCreationDate(null);
    setMaxCreationDate(null);
    setMinAggregationDate(null);
    setMaxAggregationDate(null);
    setMinUtilisationDate(null);
    setMaxUtilisationDate(null);
    setMinRolloutDate(null);
    setMaxRolloutDate(null);
    setDateErrors(new Set([]));

    const filterObj = {
      minAggregationDate: null,
      maxAggregationDate: null,
      minUtilisationDate: null,
      maxUtilisationDate: null,
      minCreationDate: null,
      maxCreationDate: null,
      minRolloutDate: null,
      maxRolloutDate: null
    };

    setQuery(
      (prev) => ({
        ...prev,
        minCreationDate: undefined,
        maxCreationDate: undefined,
        minAggregationDate: undefined,
        maxAggregationDate: undefined,
        minUtilisationDate: undefined,
        maxUtilisationDate: undefined,
        minRolloutDate: undefined,
        maxRolloutDate: undefined
      }),
      'replace'
    );
    dispatch(setFilteredData(filterObj));
    setDateErrors(new Set([]));
    setIsOpenFilters(false);
  };

  const changeFilterValue = (value, fieldName) => {
    switch (fieldName) {
      case 'minAggregationDate':
        setMinAggregationDate(value);
        break;
      case 'maxAggregationDate':
        setMaxAggregationDate(value);
        break;
      case 'minCreationDate':
        setMinCreationDate(value);
        break;
      case 'maxCreationDate':
        setMaxCreationDate(value);
        break;
      case 'minRolloutDate':
        setMinRolloutDate(value);
        break;
      case 'maxRolloutDate':
        setMaxRolloutDate(value);
        break;
      case 'minUtilisationDate':
        setMinUtilisationDate(value);
        break;
      default:
        setMaxUtilisationDate(value);
    }
  };

  useEffect(() => {
    checkDateError(
      {
        creationDate: [minCreationDate, maxCreationDate],
        aggregationDate: [minAggregationDate, maxAggregationDate],
        utilisationDate: [minUtilisationDate, maxUtilisationDate],
        rolloutDate: [minRolloutDate, maxRolloutDate]
      },
      dateErrors,
      setDateErrors
    );
  }, [
    minCreationDate,
    maxCreationDate,
    minUtilisationDate,
    maxUtilisationDate,
    minRolloutDate,
    maxRolloutDate,
    minAggregationDate,
    maxAggregationDate
  ]);

  return (
    <Stack tokens={{ childrenGap: 25 }}>
      <Label style={{ marginTop: 25 }}>Дата заказа</Label>
      <div className={g.dateInputs}>
        <DatePicker
          className={s.date}
          firstDayOfWeek={DayOfWeek.Monday}
          label="От"
          isRequired={false}
          allowTextInput={true}
          formatDate={(date) => {
            return !date
              ? null
              : getDateFromTimestamp(date, false, { delimiter: '.', withoutTime: true });
          }}
          strings={DayPickerStrings}
          value={!minCreationDate ? null : new Date(minCreationDate)}
          onSelectDate={(value) => changeFilterValue(value, 'minCreationDate')}
          maxDate={maxCreationDate}
        />
        <DatePicker
          className={s.date}
          firstDayOfWeek={DayOfWeek.Monday}
          label="До"
          minDate={minCreationDate}
          isRequired={false}
          allowTextInput={true}
          formatDate={(date) => {
            return !date
              ? null
              : getDateFromTimestamp(date, false, { delimiter: '.', withoutTime: true });
          }}
          strings={DayPickerStrings}
          value={!maxCreationDate ? null : new Date(maxCreationDate)}
          onSelectDate={(value) => changeFilterValue(value, 'maxCreationDate')}
        />
        {!!dateErrors.has('creationDate') && <span className={s.error}>Неправильный диапазон</span>}
      </div>
      <Label style={{ marginTop: 25 }}>Дата нанесения</Label>
      <div className={g.dateInputs}>
        <DatePicker
          className={s.date}
          firstDayOfWeek={DayOfWeek.Monday}
          label="От"
          isRequired={false}
          allowTextInput={true}
          formatDate={(date) => {
            return !date
              ? null
              : getDateFromTimestamp(date, false, { delimiter: '.', withoutTime: true });
          }}
          strings={DayPickerStrings}
          value={!minUtilisationDate ? null : new Date(minUtilisationDate)}
          onSelectDate={(value) => changeFilterValue(value, 'minUtilisationDate')}
          maxDate={maxUtilisationDate}
        />
        <DatePicker
          className={s.date}
          firstDayOfWeek={DayOfWeek.Monday}
          label="До"
          minDate={minUtilisationDate}
          isRequired={false}
          allowTextInput={true}
          formatDate={(date) => {
            return !date
              ? null
              : getDateFromTimestamp(date, false, { delimiter: '.', withoutTime: true });
          }}
          strings={DayPickerStrings}
          value={!maxUtilisationDate ? null : new Date(maxUtilisationDate)}
          onSelectDate={(value) => changeFilterValue(value, 'maxUtilisationDate')}
        />
        {!!dateErrors.has('utilisationDate') && (
          <span className={s.error}>Неправильный диапазон</span>
        )}
      </div>
      <Label style={{ marginTop: 25 }}>Дата добавления в агрегат</Label>
      <div className={g.dateInputs}>
        <DatePicker
          className={s.date}
          firstDayOfWeek={DayOfWeek.Monday}
          label="От"
          isRequired={false}
          allowTextInput={true}
          formatDate={(date) => {
            return !date
              ? null
              : getDateFromTimestamp(date, false, { delimiter: '.', withoutTime: true });
          }}
          strings={DayPickerStrings}
          value={!minAggregationDate ? null : new Date(minAggregationDate)}
          onSelectDate={(value) => changeFilterValue(value, 'minAggregationDate')}
          maxDate={maxAggregationDate}
        />
        <DatePicker
          className={s.date}
          firstDayOfWeek={DayOfWeek.Monday}
          label="До"
          minDate={minAggregationDate}
          isRequired={false}
          allowTextInput={true}
          formatDate={(date) => {
            return !date
              ? null
              : getDateFromTimestamp(date, false, { delimiter: '.', withoutTime: true });
          }}
          strings={DayPickerStrings}
          value={!maxAggregationDate ? null : new Date(maxAggregationDate)}
          onSelectDate={(value) => changeFilterValue(value, 'maxAggregationDate')}
        />
        {!!dateErrors.has('aggregationDate') && (
          <span className={s.error}>Неправильный диапазон</span>
        )}
      </div>
      <Label style={{ marginTop: 25 }}>Дата ввода в оборот</Label>
      <div className={g.dateInputs}>
        <DatePicker
          className={s.date}
          firstDayOfWeek={DayOfWeek.Monday}
          label="От"
          isRequired={false}
          allowTextInput={true}
          formatDate={(date) => {
            return !date
              ? null
              : getDateFromTimestamp(date, false, { delimiter: '.', withoutTime: true });
          }}
          strings={DayPickerStrings}
          value={!minRolloutDate ? null : new Date(minRolloutDate)}
          onSelectDate={(value) => changeFilterValue(value, 'minRolloutDate')}
          maxDate={maxRolloutDate}
        />
        <DatePicker
          className={s.date}
          firstDayOfWeek={DayOfWeek.Monday}
          label="До"
          minDate={minAggregationDate}
          isRequired={false}
          allowTextInput={true}
          formatDate={(date) => {
            return !date
              ? null
              : getDateFromTimestamp(date, false, { delimiter: '.', withoutTime: true });
          }}
          strings={DayPickerStrings}
          value={!maxRolloutDate ? null : new Date(maxRolloutDate)}
          onSelectDate={(value) => changeFilterValue(value, 'maxRolloutDate')}
        />
        {!!dateErrors.has('rolloutDate') && <span className={s.error}>Неправильный диапазон</span>}
      </div>
      <div>
        <FilterButtons onSubmit={onConfirm} onDismiss={onDismiss} />
      </div>
    </Stack>
  );
};
