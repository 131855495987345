import {serializeQueryObj} from '../functions'
import API from './APIService'

class UsersAPI {
    getRoles = () => API.get('/api/Role/GetRoles')

    getAllUsers = () => API.get('/api/User/all')
    getUsers = (query) => API.get(`/api/User/Get?quantityOnPage=${10}&${serializeQueryObj(query)}`)
    getUserByID = (userID) => API.get(`/api/User/${userID}`)

    addUser = (user) => API.post(`/api/User`, user)
    editUser = (user) => API.put(`/api/User`, user)
    deleteUser = (userID) => API.delete(`/api/User/${userID}`)

    changePassword = (data) => API.put(`/api/User/Password`, data)
}

export default new UsersAPI()
