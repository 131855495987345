export const sepThousands = (str) => {
  const arr = str.toString().split('').reverse();
  return arr
    .map((ch, ind) => {
      if ((ind + 1) % 3 === 0) return ' ' + ch;
      return ch;
    })
    .reverse()
    .join('')
    .trim();
};
