import authAPI from '../api/authAPI';
import { LOCAL_STORAGE_DATA_NAME } from '../constants';

export class TokenTimer {
  constructor(expiredTime) {
    this.time = expiredTime * 1000;
    this.timer = null;
  }

  startTimer() {
    const expired = Math.floor((this.time - Date.now()) / 3);
    console.log('from-token-timer', this.time, Date.now(), expired);
    this.timer = setInterval(async () => {
      const newToken = await TokenTimer.getNewToken();
      this.setNewToken(newToken);
    }, expired);
  }

  static async getNewToken() {
    return await authAPI.refreshToken();
  }

  setNewToken(newToken) {
    this.destroyTimer();
    const localStorageData = JSON.parse(localStorage.getItem(LOCAL_STORAGE_DATA_NAME));
    localStorageData.token = newToken.accessToken;
    localStorageData.tokenExpirationDate = newToken.tokenExpirationDate;
    localStorage.setItem(LOCAL_STORAGE_DATA_NAME, JSON.stringify(localStorageData));
    this.time = newToken.tokenExpirationDate * 1000;
    this.startTimer();
  }

  destroyTimer() {
    clearInterval(this.timer);
  }
}
