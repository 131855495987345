import { wideScreen } from '../../constants';

export const printingTableHeader = [
  { name: 'selected', title: '', width: '0.02' },
  { name: 'suzId', title: 'Код отчёта', width: '0.1', maxLength: 10 },
  { name: 'creationDate', title: 'Дата создания', width: '0.07' },
  { name: 'sendingDate', title: 'Дата отправки', width: '0.08' },
  { name: 'productCreationDate', title: 'Дата производства', width: '0.10' },
  { name: 'status', title: 'Статус', width: '0.13' },
  { name: 'productName', title: 'Продукт', width: '0.16', maxLength: 15 },
  { name: 'productGtin', title: 'Gtin', width: '0.11' },
  { name: 'partNumber', title: 'Задание', width: '0.09' },
  { name: 'numberMarkingCodes', title: 'Кол-во КМ', width: '0.09' },
  { name: 'actions', title: 'Действия', width: '0.05' }
];

export const aggregationTableHeader = [
  { name: 'selected', title: '', width: '0.02' },
  { name: 'suzId', title: 'Код отчёта', width: '0.1', maxLength: 10 },
  { name: 'unitSerialNumber', title: 'Код агрегата', width: '0.11', maxLength: 10 },
  { name: 'creationDate', title: 'Дата создания', width: '0.07' },
  { name: 'sendingDate', title: 'Дата отправки', width: '0.07' },
  { name: 'status', title: 'Статус', width: '0.12' },
  { name: 'productName', title: 'Продукт', width: '0.17', maxLength: 15 },
  { name: 'productGtin', title: 'Gtin', width: '0.11' },
  { name: 'partNumber', title: 'Задание', width: '0.09' },
  { name: 'numberMarkingCodes', title: 'Кол-во КМ', width: '0.09' },
  { name: 'actions', title: 'Действия', width: '0.05' }
];
export const enteringTableHeader = [
  { name: 'selected', title: '', width: '0.03' },
  { name: 'suzId', title: 'Код отчёта', width: '0.1', maxLength: 10 },
  { name: 'creationDate', title: 'Дата создания', width: '0.07' },
  { name: 'sendingDate', title: 'Дата отправки', width: '0.07' },
  { name: 'productCreationDate', title: 'Дата производства', width: '0.08' },
  { name: 'status', title: 'Статус', width: '0.13' },
  { name: 'productName', title: 'Продукт', width: '0.12', maxLength: 15 },
  { name: 'productGtin', title: 'Gtin', width: '0.1' },
  { name: 'vsd', title: 'ВСД', width: '0.1' },
  { name: 'partNumber', title: 'Задание', width: '0.07' },
  { name: 'numberMarkingCodes', title: 'Кол-во КМ', width: '0.08' },
  { name: 'actions', title: 'Действия', width: '0.05' }
];
export const rejectTableHeader = [
  { name: 'selected', title: '', width: '0.02' },
  { name: 'suzId', title: 'Код отчёта', width: '0.1', maxLength: 10 },
  { name: 'creationDate', title: 'Дата создания', width: '0.07' },
  { name: 'sendingDate', title: 'Дата отправки', width: '0.08' },
  { name: 'status', title: 'Статус', width: '0.13' },
  { name: 'dropoutReason', title: 'Причины отсева', width: '0.12' },
  { name: 'productName', title: 'Продукт', width: '0.15' },
  { name: 'productGtin', title: 'Gtin', width: '0.1' },
  { name: 'partNumber', title: 'Задание', width: '0.09' },
  { name: 'numberMarkingCodes', title: 'Кол-во КМ', width: '0.09' },
  { name: 'actions', title: 'Действия', width: '0.05' }
];

export const setsTableHeader = [
  { name: 'selected', title: '', width: '0.03' },
  { name: 'suzId', title: 'Код отчёта', width: '0.11', maxLength: 10 },
  { name: 'creationDate', title: 'Дата создания', width: '0.08' },
  { name: 'sendingDate', title: 'Дата отправки', width: '0.08' },
  { name: 'productCreationDate', title: 'Дата производства', width: '0.08' },
  { name: 'status', title: 'Статус', width: '0.14' },
  { name: 'productName', title: 'Продукт', width: '0.17', maxLength: 15 },
  { name: 'productGtin', title: 'Gtin', width: '0.1', maxLength: 10 },
  { name: 'partNumber', title: 'Задание', width: '0.09' },
  {
    name: 'numberMarkingCodes',
    title: 'Кол-во КМ',
    width: '0.07',
    headerStyles: { padding: '0 0.5rem' }
  },
  { name: 'actions', title: 'Действия', width: '0.05' }
];

export const withdrInternConsupmTableHeader = [
  { name: 'selected', title: '', width: '0.02' },
  {
    name: 'suzId',
    title: 'Код отчёта',
    width: '0.1',
    maxLength: 10
  },
  {
    name: 'creationDate',
    title: 'Дата создания',
    width: '0.08'
  },
  {
    name: 'sendingDate',
    title: 'Дата отправки',
    width: '0.08'
  },
  {
    name: 'actionDate',
    title: 'Дата вывода',
    width: '0.08',
    headerStyles: { padding: '0 3px' }
  },
  { name: 'status', title: 'Статус', width: '0.13' },
  { name: 'productName', title: 'Продукт', width: '0.12', maxLength: 15 },
  { name: 'productGtin', title: 'Gtin', width: '0.09', maxLength: 10 },
  {
    name: 'action',
    title: 'Причина выбытия',
    width: '0.12',
    headerStyles: { padding: '0 0.5rem' }
  },
  {
    name: 'numberMarkingCodes',
    title: 'Кол-во КМ',
    width: '0.06',
    headerStyles: { paddingRight: '1rem', textAlign: 'center' }
  },
  { name: 'actions', title: 'Действия', width: '0.06' }
];

export const writeOffTableHeader = [
  { name: 'selected', title: '', width: '0.02' },
  {
    name: 'suzId',
    title: 'Код отчёта',
    width: '0.1',
    maxLength: 10
  },
  {
    name: 'creationDate',
    title: 'Дата создания',
    width: '0.08'
  },
  {
    name: 'sendingDate',
    title: 'Дата отправки',
    width: '0.08'
  },
  {
    name: 'sourceDocDate',
    title: 'Дата вывода',
    width: '0.08',
    headerStyles: { padding: '0 3px' }
  },
  { name: 'status', title: 'Статус', width: '0.13' },
  { name: 'productName', title: 'Продукт', width: '0.12', maxLength: 15 },
  { name: 'productGtin', title: 'Gtin', width: '0.09', maxLength: 10 },
  {
    name: 'dropoutReason',
    title: 'Причина выбытия',
    width: '0.12',
    headerStyles: { padding: '0 0.5rem' }
  },
  {
    name: 'numberMarkingCodes',
    title: 'Кол-во КМ',
    width: '0.06',
    headerStyles: { paddingRight: '1rem', textAlign: 'center' }
  },
  { name: 'actions', title: 'Действия', width: '0.06' }
  
];
const shorterReportTables = [printingTableHeader, aggregationTableHeader, rejectTableHeader];

export const adjustTable = (
  width,
  wideScreen,
  tableHeader,
  shorterTables = shorterReportTables
) => {
  if (width < wideScreen) return tableHeader;
  const smallTableHeaderSets = new Set(shorterTables);
  if (smallTableHeaderSets.has(tableHeader)) {
    return tableHeader.map((column) => {
      if (column.title.includes('Дата')) column.width = '0.055';
      if (column.width === '0.1' || column.width === '0.09') column.width = '0.08';
      if (column.title === 'Продукт') column.width = '0.34';
      return column;
    });
  } else {
    return tableHeader.map((column) => {
      if (column.title.includes('Дата')) column.width = '0.055';
      if (column.width === '0.1' && column.title !== 'productGtin') column.width = '0.075';
      if (column.width === '0.09' || column.width === '0.07') column.width = '0.06';
      if (column.title === 'Продукт') {
        column.width = '0.305';
        column.longerProduct = true;
      }
      return column;
    });
  }
};

export const isChkboxDisbabled = (status) => status !== 'CREATED' && status !== 'REJECTED';
export const isChkboxDisbabledEnt = (status) => status !== 'CREATED' && status !== 'REJECTED';

export const mockSetsReport = {
  quantity: 3,
  list: [
    {
      creationDate: 1708010882000,
      errorMessage: null,
      id: 1068,
      numberMarkingCodes: 1,
      partNumber: 'xMark2590',
      productCreationDate: 1708009200000,
      productGtin: '00000090876127',
      productName: 'Вода',
      sendingDate: null,
      status: 'CREATED'
    },
    {
      creationDate: 1708010882000,
      errorMessage: null,
      id: 1068,
      numberMarkingCodes: 1,
      partNumber: 'xMark2590',
      productCreationDate: 1708009200000,
      productGtin: '00000090876127',
      productName: 'Вода',
      sendingDate: null,
      status: 'CREATED'
    },
    {
      creationDate: 1708010882000,
      errorMessage: null,
      id: 1068,
      numberMarkingCodes: 1,
      partNumber: 'xMark2590',
      productCreationDate: 1708009200000,
      productGtin: '00000090876127',
      productName: 'Вода',
      sendingDate: null,
      status: 'CREATED'
    }
  ]
};

export const getTypesInfo = (currentWidth) => ({
  printing: {
    title: 'нанесения',
    tableHeader: adjustTable(currentWidth, wideScreen, printingTableHeader)
  },
  aggregation: {
    title: 'агрегации',
    tableHeader: adjustTable(currentWidth, wideScreen, aggregationTableHeader)
  },
  entering: {
    title: 'ввода в оборот',
    tableHeader: adjustTable(currentWidth, wideScreen, enteringTableHeader)
  },
  rejection: {
    title: 'отбраковка',
    tableHeader: adjustTable(currentWidth, wideScreen, rejectTableHeader)
  },
  sets: {
    title: 'наборы',
    tableHeader: adjustTable(currentWidth, wideScreen, setsTableHeader)
  },
  withrdIntrnlConsmpt: {
    title: 'вывод из оборота',
    tableHeader: adjustTable(currentWidth, wideScreen, withdrInternConsupmTableHeader)
  },
  writeOff: {
    title: 'списания',
    tableHeader: adjustTable(currentWidth, wideScreen, writeOffTableHeader)
  }
});
