export const GET_LOGS_START = 'GET_LOGS_START';
export const GET_LOGS_SUCCESS = 'GET_LOGS_SUCCESS';
export const GET_LOGS_ERROR = 'GET_LOGS_ERROR';
export const LOGS_RESET_STATE = 'LOGS_RESET_STATE';
export const LOGS_POLLING_START = 'LOGS_POLLING_START';

const initialState = {
    loading: false,
    errors: '',
    logs: '',
    isPolling: false,
}

export const logsReducer = (state = initialState, action) => {
    switch (action.type) {
        case LOGS_POLLING_START: 
            return {
                ...state,
                isPolling: true
            }
        case GET_LOGS_START: 
            return {
                ...state,
                loading: true,
                errors: '',
            }
        case GET_LOGS_SUCCESS: 
            return {
                ...state,
                loading: false,
                errors: '',
                logs: action.payload
            }
        case GET_LOGS_ERROR:
            return {
                ...state,
                loading: false,
                errors: action.payload,
            }
        case LOGS_RESET_STATE:
            return initialState
        default:
            return state
    }
}

export const getLogsAction = (payload) => ({
    type: GET_LOGS_START,
    payload
})

export const pollingStartAction = () => ({
    type: LOGS_POLLING_START
})

export const resetLogsAction = () => ({
    type: LOGS_RESET_STATE
})