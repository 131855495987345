import { useEffect } from 'react';
import { Field, useField } from 'formik';
import ComboSelect from 'components/Forms/customSelect/comboSelect';
import s from './Expiration.module.scss';

const OwnerField = ({ owners }) => {
  const [ownersField, __, ownerHelpers] = useField('productOwnerId');
  const onChange = (arg) => ownerHelpers.setValue(arg.value);
  const newOwners = owners.map((comp) => ({ value: comp.key, label: comp.text }));

  useEffect(() => {
    if (!ownersField.value) {
      ownerHelpers.setValue(newOwners[0]?.value);
    }
  }, [newOwners?.length]);

  return (
    <section className={s.expiration}>
      <Field name={'productOwnerId'}>
        {(props) => {
          return (
            <ComboSelect
              options={newOwners}
              onChange={onChange}
              label="Владелец продукта"
              placeholder={newOwners.find((own) => own.value === ownersField.value)?.label || ''}
            />
          );
        }}
      </Field>
    </section>
  );
};

export default OwnerField;
