import clsx from 'clsx';
import { SearchBox } from 'office-ui-fabric-react';
import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import ProductsAPI from '../../api/productsAPI';
import g from '../../assets/scss/Main.module.scss';
import { ProductsFilter } from '../../components/Filters/ProductsFilter/ProductsFilter';
import { FiltersPanel } from '../../components/FiltersPanel/FiltersPanel';
import { ProductPagination } from '../../components/Pagination/Pagination';
import { SyncStatus } from '../../components/SyncStatus/SyncStatus';
import { ProductsTable } from '../../components/Table/Table';
import { TableAction } from '../../components/TableAction/TableAction';
import { WaitingScreen } from '../../components/WaitingScreen/WaitingScreen';
import {
  listCodesIcon,
  orderCodesIcon,
  ordersCodesIcon,
  productCardIcon,
  searchBoxStyles,
  syncIcon
} from '../../constants';
import { usePaginationAndSearch, useDataFilter } from '../../hooks';
import { getPrevRoute } from '../../redux/App/appSelectors';
import s from './Products.module.scss';
import { getGlobalErrorText } from 'redux/App/appSelectors';
import { useDispatch } from 'react-redux';
import { getProductChZBalance } from 'redux/App/appReducer';
import RemainingCosedWCallout from './ProductsCard/Callout';
import AddSiteProductButton from './AddSideProduct/AddSideProduct';

const tableHeader = [
  {
    title: 'Название',
    width: '15%'
  },
  {
    title: 'Gtin',
    width: '10%'
  },
  {
    title: 'Остаток КМ',
    width: '10%'
  },
  {
    title: 'Действия',
    width: '200px'
  }
];

export const Products = ({ setIsOpenFilters, isOpenFilters }) => {
  const [products, setProducts] = useState([]);
  const [loading, setLoading] = useState(false);
  const [syncStatus, setSyncStatus] = useState(null);
  const prevRoute = useSelector(getPrevRoute);
  const globalErrorText = useSelector(getGlobalErrorText);
  const dispatch = useDispatch();
  const [calledProduct, setCalledProduct] = useState(false);

  const hideCallout = (e) => {
    if (e.target.nodeName !== 'SPAN') {
      e.stopPropagation();
      setCalledProduct('');
    }
  };

  const getProductBalance = (productId) => {
    dispatch(getProductChZBalance(productId));
  };

  const mouseDownHandler = (e, params) => {
    localStorage.setItem('params', JSON.stringify(params));
  };

  const [query, setQuery] = useDataFilter('products');

  useEffect(() => {
    if (globalErrorText) {
      setSyncStatus(null);
    }
  }, [globalErrorText]);

  const getProducts = async (query) => {
    setLoading(true);

    let res = await ProductsAPI.getProducts(query);

    if (!res?.statusCode) {
      const { list, quantity } = res;
      setProducts(list);
      setTotalItems(quantity);
    }
    setLoading(false);
  };

  const syncProducts = async (type) => {
    setSyncStatus(`${type}InProgress`);
    let syncRes;
    if (type === 'fullSuz') {
      syncRes = await ProductsAPI.setSignedOrdersFull();
    }

    if (type === 'catalog') {
      syncRes = await ProductsAPI.syncProducts();
    }

    if (type === 'suz') {
      syncRes = await ProductsAPI.setSignedOrders();
    }

    if (syncRes.statusCode === 200) {
      await getProducts(query);
      setSyncStatus('success');
    } else {
      setSyncStatus('error');
    }
  };

  const { onPageChanged, onSearchTermChanged, totalPages, setTotalItems, currentPage } =
    usePaginationAndSearch({
      callback: getProducts,
      query,
      setQuery
    });

  return (
    <>
      <div onClick={hideCallout}>
        <div className={g.titleWrapper}>
          <h1 className={g.title}>Продукты</h1>

          <SearchBox
            iconProps={{ iconName: '' }}
            styles={searchBoxStyles}
            value={query.searchValue}
            placeholder="Поиск по названию или Gtin"
            onClear={() => {
              onSearchTermChanged('');
            }}
            onSearch={(newValue) => {
              onSearchTermChanged(newValue);
            }}
          />
        </div>
        <ProductsTable headerItems={tableHeader} maxHeight="65vh" loading={loading}>
          {Array.isArray(products) &&
            products?.map((item, ind) => {
              return (
                <div
                  key={item.id}
                  className={clsx(
                    g.tableItem,
                    item.markingCodesRemainder <= item.minRemainderOfMarkingCodes &&
                      g.errorTableItem
                  )}
                >
                  <div className={s.firsthThreeRows}>
                    <div className={clsx(g.tableRow, s.firstRow)}>{item.name}</div>
                    <div className={clsx(s.tableCell, s.middleRows)}>{item.gtin}</div>
                    <RemainingCosedWCallout
                      markingCodesRemainder={item.markingCodesRemainder}
                      productId={item.id}
                      isCallout={calledProduct === item.id}
                      setCalledProduct={setCalledProduct}
                      index={ind}
                      hideCallout={hideCallout}
                    />
                  </div>

                  <div className={clsx(`${s.tableActionsItems}`, s.lastRow)}>
                    <div
                      onMouseDown={(e) =>
                        mouseDownHandler(e, {
                          id: item.id,
                          productName: item.name,
                          templateId: item.category?.templateId,
                          productGtin: item.gtin,
                          prevRoute: prevRoute
                        })
                      }
                    >
                      <TableAction
                        link={`/products/orderCode/${item.id}`}
                        getProductBalance={() => getProductBalance(item.id)}
                        params={{
                          id: item.id,
                          productName: item.name,
                          templateId: item.category?.templateId,
                          productGtin: item.gtin,
                          prevRoute: prevRoute
                        }}
                        tooltipText={'Заказать КМ'}
                        icon={orderCodesIcon}
                      />
                    </div>

                    <TableAction
                      link={`/products/codesList`}
                      params={{
                        status: 'NEW,UTILISATED,AGGREGATED,INROLLOUT,SCANED,INVALID',
                        statusParamName: 'status',
                        productId: item.id
                      }}
                      tooltipText={'Посмотреть список КМ'}
                      icon={listCodesIcon}
                    />
                    <TableAction
                      link={`/products/orders`}
                      params={{
                        orderId: item.id,
                        statusParamName: 'status',
                        id: item.id,
                        status: 'CREATED,SENDED,PENDING,DECLINED,APPROVED,READY,CLOSED,EXPIRED'
                      }}
                      icon={ordersCodesIcon}
                      tooltipText={'Посмотреть заказы КМ'}
                    />
                    <TableAction
                      link={`/products/productCard/${item.id}`}
                      params={{
                        id: item.id,
                        productName: item.name,
                        templateId: item.category?.templateId,
                        productGtin: item.gtin
                      }}
                      icon={productCardIcon}
                      tooltipText={'Информация о продукте'}
                    />
                  </div>
                  <div className={s.edgeContainer} />
                </div>
              );
            })}
        </ProductsTable>
        <div className={g.footer}>
          {totalPages > 1 && (
            <ProductPagination
              pageCount={totalPages}
              onPageChange={onPageChanged}
              selectedPage={currentPage}
            />
          )}
          <div className={s.syncButtons}>
            <AddSiteProductButton query={query} getProducts={getProducts} />
            <div
              className={clsx(s.syncBtn, syncStatus === 'catalogInProgress' && s.progressSyncBtn)}
            >
              <TableAction
                icon={syncIcon}
                addStyles={{ width: '17rem' }}
                disabled={syncStatus === 'catalogInProgress'}
                isButton={true}
                onClick={() => syncProducts('catalog')}
                text="Синхронизировать нац. каталог"
                type={'accent'}
              />
            </div>
            {/* <div className={clsx(s.syncBtn, syncStatus === 'suzInProgress' && s.progressSyncBtn)}>
              <TableAction
                iconName={'Sync'}
                addStyles={{ width: '17.75rem' }}
                disabled={syncStatus === 'suzInProgress'}
                isButton={true}
                onClick={() => syncProducts('suz')}
                text="Синхронизировать СУЗ"
                type={'accent'}
              />
            </div> */}
            <div
              className={clsx(s.syncBtn, syncStatus === 'fullSuzInProgress' && s.progressSyncBtn)}
            >
              <TableAction
                icon={syncIcon}
                addStyles={{ width: '17rem' }}
                disabled={syncStatus === 'fullSuzInProgress'}
                isButton={true}
                onClick={() => syncProducts('fullSuz')}
                text="Полная синхронизация с СУЗ"
                type={'accent'}
              />
            </div>
          </div>
        </div>
        <FiltersPanel
          isOpenFilters={isOpenFilters}
          setIsOpenFilters={setIsOpenFilters}
          query={query}
          setQuery={setQuery}
        >
          <ProductsFilter />
        </FiltersPanel>
      </div>
      {syncStatus?.includes('InProgress') && (
        <WaitingScreen
          title={'Выполняется синхронизация'}
          text={
            'Пожалуйста, подождите. Это может занять несколько минут. Не закрывайте эту страницу.'
          }
        />
      )}
      {['success', 'error'].includes(syncStatus) && (
        <SyncStatus syncStatus={syncStatus} hide={() => setSyncStatus(null)} />
      )}
    </>
  );
};
