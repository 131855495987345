import { call, put, takeLatest } from '@redux-saga/core/effects';
import AuthAPI from '../../api/authAPI';
import { getAccessLevel, LOCAL_STORAGE_DATA_NAME } from '../../constants';
import { TokenTimer } from '../../utils/TokenTimer';
import { setInitialized } from '../App/appReducer';
import {
  CHECK_LOGGED,
  LOGIN,
  LOGOUT,
  setAccessLevel,
  setErrorAC,
  setFirstName,
  setLoadingAC,
  setLoggedAC,
  setSurName
} from './authReducer';

let tokenTimer = null;

function* doLogin(action) {
  yield put(setLoadingAC(true));
  try {
    const res = yield call(AuthAPI.auth, action.payload);

    if (res?.statusCode && res.statusCode !== 200) {
      if (res?.statusCode === 403) {
        yield put(setLoggedAC(false));
      }
      yield put(setErrorAC(res?.message));
      yield put(setInitialized(true));
    } else {
      const { firstName, surname, accessToken, tokenExpirationDate, roles } = res;
      const uplineAdminData = { firstName, surname, accessToken, tokenExpirationDate, roles };

      localStorage.setItem(LOCAL_STORAGE_DATA_NAME, JSON.stringify(uplineAdminData));

      yield put(setFirstName(uplineAdminData.firstName));
      yield put(setSurName(uplineAdminData.surname));
      yield put(setAccessLevel(getAccessLevel(roles)));
      yield put(setLoggedAC(true));
      yield put(setInitialized(true));
      tokenTimer = new TokenTimer(res.tokenExpirationDate);
      tokenTimer.startTimer();
    }
  } catch (err) {
    if (err.message.includes('Network')) {
      yield put(setErrorAC('Отсутствует соединение с сервером'));
    } else {
      yield put(setErrorAC(err.message));
    }
    yield put(setInitialized(true));
  }
  yield put(setLoadingAC(false));
  yield put(setInitialized(true));
}

export function* doCheckLogged() {
  yield put(setLoadingAC(true));
  const LSData = JSON.parse(localStorage.getItem(LOCAL_STORAGE_DATA_NAME));
  const token = LSData?.accessToken;

  if (token) {
    try {
      const res = yield call(AuthAPI.checkToken, token);
      console.log('res', res);
      if (res.statusCode && res.statusCode !== 200) {
        // if (res.statusCode === 401) {
        //   yield put(setErrorAC('Время сессии истекло. Войдите заново.'));
        // } else {
        //   yield put(setErrorAC(res?.message));
        // }
        yield put(doLogout());
        // yield put(setLoggedAC(false));
        // yield put(setLoadingAC(false));
        // yield put(setInitialized(true));
      } else {
        console.log('else is triggered');
        yield put(setLoggedAC(true));
        yield put(setFirstName(LSData.firstName));
        yield put(setSurName(LSData.surname));
        yield put(setAccessLevel(getAccessLevel(LSData.roles)));
        yield put(setInitialized(true));
        const tokenTimer = new TokenTimer(LSData?.tokenExpirationDate);
        tokenTimer.destroyTimer();
        tokenTimer.startTimer();
      }
    } catch (err) {
      // yield put(setGlobalErrorTextAC(err?.message))
      yield put(setInitialized(true));
    }
  } else {
    yield put(setLoadingAC(false));
    yield put(setInitialized(true));
  }
}

function* doLogout() {
  console.log('logout triggered');
  yield put(setLoadingAC(true));
  localStorage.removeItem(LOCAL_STORAGE_DATA_NAME);
  yield put(setLoggedAC(false));
  yield put(setAccessLevel(null));
  yield put(setLoadingAC(false));
  tokenTimer?.destroyTimer();
}

export function* AuthSaga() {
  yield takeLatest(LOGIN, doLogin);
  yield takeLatest(LOGOUT, doLogout);
  yield takeLatest(CHECK_LOGGED, doCheckLogged);
}
