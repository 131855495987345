import { Field, useField } from 'formik';
import { memo, useEffect, useState } from 'react';
import CertificateSelect from './SingleCertificate';
import { Alert } from 'components/Alert/Alert';
import s from '../placesOfBusiness/places.module.scss';
import st from '../options.module.scss';
import { optionFields, getValue } from './SingleCertificate';

const CertificatesField = () => {
  const [showOptions, setShowOptions] = useState(false);
  const [showAlert, setShowAlert] = useState(false);
  const [alertText, setAlertText] = useState(
    'Данный сертификат недействительный и его нельзя выбрать.'
  );
  const [selectedCert, setSelectedCert] = useState(null);
  const [field] = useField('certificates');
  const [thumbPrintField, _, thumbPrintHelpers] = useField('thumbPrint');
  const [options, setOptons] = useState(null);
  const onCloseAlert = () => {
    setShowAlert(false);
  };

  useEffect(() => {
    if (field.value?.length) {
      setOptons(field.value);
      const selCert = field.value.find((c) => c.thumbprint === thumbPrintField.value);

      setSelectedCert(selCert);
    }
  }, [thumbPrintField.value]);

  const handleShowOptions = () => {
    if (field.value?.length) {
      setShowOptions((p) => !p);
    } else {
      setAlertText('Список сертификатов пуст.');
      setShowAlert(true);
    }
  };

  const handleSelect = (thumbprint, isValid) => {
    if (isValid) {
      thumbPrintHelpers.setValue(thumbprint);
      setShowOptions(false);
    } else {
      setAlertText('Данный сертификат недействительный и его нельзя выбрать.');
      setShowAlert(true);
    }
  };

  return (
    <>
      <section className={s.singleRowWrapper}>
        <div className={s.fieldTitle}>Установленный сертификат</div>
        <button type="button" onClick={handleShowOptions} className={st.fieldLabel}>
          {showOptions ? 'Свернуть сертификаты' : 'Посмотреть сертификаты'}
        </button>
        {showOptions && !!options?.length && (
          <CertificateSelect certificates={options} handleSelect={handleSelect} />
        )}
      </section>
      {selectedCert && (
        <section className={s.singleOption_gray} style={{ background: 'white' }}>
          {optionFields.map((field) => (
            <div className={s.optionRow} key={field.field}>
              <span className={s.optionFieldName}>{field.inRussion}</span>
              <span className={s.optionFieldValue}>{getValue(selectedCert, field.field)}</span>
            </div>
          ))}
        </section>
      )}
      {showAlert && (
        <Alert type="alert" title="Выбор сертификата" text={alertText} onClose={onCloseAlert} />
      )}
    </>
  );
};

export default memo(CertificatesField);
