import { SearchBox } from 'office-ui-fabric-react'
import React, { useEffect, useState } from 'react'
import g from '../../assets/scss/Main.module.scss'
import { UsersFilter } from '../../components/Filters/UsersFilter/UsersFilter'
import { FiltersPanel } from '../../components/FiltersPanel/FiltersPanel'
import { Pagination } from '../../components/Pagination/Pagination'
import { Table } from '../../components/Table/Table'
import { TableAction } from '../../components/TableAction/TableAction'
import { addIcon, moreInfoIcon, searchBoxStyles } from '../../constants'
import UsersAPI from '../../api/usersAPI'
import { usePaginationAndSearch, useDataFilter } from '../../hooks'
import s from './Users.module.scss'

const tableHeader = [
    {
        title: 'Логин',
        width: '0.17'
    },
    {
        title: 'ФИО',
        width: '0.3'
    },
    {
        title: 'Роль',
        width: '0.4'
    },
    {
        title: 'Действия',
        width: '0.13'
    }
]

export const Users = ({ setIsOpenFilters, isOpenFilters }) => {    
    const [users, setUsers] = useState([])
    const [loading, setLoading] = useState(false)
    const [query, setQuery] = useDataFilter('users')

    const getUsers = async (query) => {
        setLoading(true)
        let res = await UsersAPI.getUsers(query)
        const { list, quantity } = res
         setUsers(list)
         setTotalItems(quantity)
         setLoading(false)
    }

    const { onPageChanged, onSearchTermChanged, totalPages, setTotalItems, currentPage } = usePaginationAndSearch({ callback: getUsers, query, setQuery })

    const getRolesString = (user) => {
        const roles = []
        user.roles.forEach(role => roles.push(role.name))
        return roles.join(', ')
    }

    return (
        <div>
            <div className={g.titleWrapper}>
                <h1 className={g.title}>Пользователи</h1>
                <SearchBox
                    styles={searchBoxStyles}
                    placeholder="Поиск по ФИО или логину"
                    value={query.searchValue}
                    onClear={() => {
                        onSearchTermChanged('')
                    }}
                    onSearch={newValue => onSearchTermChanged(newValue)}
                />
            </div>
            <Table headerItems={tableHeader} maxHeight='67vh' loading={loading}>
                {users.map(item => {
                    return <div key={item.id} className={g.tableItem}>
                        <div className={g.tableCell} style={{ width: `calc((100vw - 180px - 3.75rem) * ${tableHeader[0].width})`}}>{item.userName}</div>
                        <div className={g.tableCell}
                            style={{ width: `calc((100vw - 180px - 3.75rem) * ${tableHeader[1].width})` }}>{`${item.firstName} ${item.surname} ${item.patronymic}`}</div>
                        <div className={g.tableCell} style={{ width: `calc((100vw - 180px - 3.75rem) * ${tableHeader[2].width})` }}>{getRolesString(item)}</div>
                        <div className={s.tableActionCell} style={{ width: `calc((100vw - 180px - 3.75rem) * ${tableHeader[3].width})` }}>
                            <TableAction iconName={moreInfoIcon} text='Подробнее' link={`/users/${item.id}`} />
                        </div>
                    </div>
                })}
            </Table>
            <div className={g.footer}>
                {totalPages > 1 &&
                 <Pagination pageCount={totalPages} onPageChange={onPageChanged} selectedPage={currentPage} />}
                <TableAction iconName={addIcon} link={`users/add`} text='Добавить пользователя' />
            </div>
            <FiltersPanel isOpenFilters={isOpenFilters} setIsOpenFilters={setIsOpenFilters}
                query={query}
                setQuery={setQuery}>
                <UsersFilter />
            </FiltersPanel>
        </div>
    )
}
