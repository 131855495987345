import React from 'react';
import { useAlert } from '../../../hooks';
import g from '../../../assets/scss/Main.module.scss';
import { Alert } from '../../../components/Alert/Alert';
import { rejectTableHeader, isChkboxDisbabled } from '../constants';
import { Checkbox } from 'office-ui-fabric-react';
import {
  cropText,
  getDateFromTimestamp,
  getDropoutReasonByEnum,
  getStatusByEnum,
  isShipped,
  formatTwoPartDate
} from '../../../functions';
import { TableItemWithTooltip } from './TableItemWithTooltip';
import clsx from 'clsx';
import { TableAction } from '../../../components/TableAction/TableAction';
import { moreInfoIcon } from '../../../constants';
import s from '../../Products/Products.module.scss';
import info_button from '../../../assets/img/info_button.svg';

const RejectionReportsTableRow = ({
  report,
  onSelectReport,
  selectedReports,
  rowStyle = g.tableCell
}) => {
  const { alertTitle, alertText, isAlertShowed, showAlert, hideAlert } = useAlert();
  const errorInfoHandler = () => {
    showAlert('Текст ошибки', report?.errorMessage);
  };

  return (
    <div className={g.tableItem}>
      {isAlertShowed && <Alert title={alertTitle} text={alertText} onClose={() => hideAlert()} />}

      <div
        className={g.tableRow}
        style={{ width: `calc((100vw - 180px - 3.75rem) * ${rejectTableHeader[0].width})` }}
      >
        <Checkbox
          onChange={() => onSelectReport(report)}
          disabled={isShipped(report.status) || isChkboxDisbabled(report.status)}
          checked={selectedReports.has(report.id)}
        />
      </div>

      <TableItemWithTooltip
        tooltipValue={report.suzId}
        styles={{ width: `calc((100vw - 180px - 3.75rem) * ${rejectTableHeader[1].width})` }}
      >
        <div className={clsx(g.tableRow, rowStyle)}>{cropText(report.suzId)}</div>
      </TableItemWithTooltip>

      <TableItemWithTooltip tooltipValue={formatTwoPartDate(report.creationDate).toolTipPart}>
        <div
          className={clsx(g.tableRow, rowStyle)}
          style={{ width: `calc((100vw - 180px - 3.75rem) * ${rejectTableHeader[2].width})` }}
        >
          {formatTwoPartDate(report.creationDate).mainPart}
        </div>
      </TableItemWithTooltip>

      <TableItemWithTooltip tooltipValue={formatTwoPartDate(report.sendingDate).toolTipPart}>
        <div
          className={clsx(g.tableRow, rowStyle)}
          style={{ width: `calc((100vw - 180px - 3.75rem) * ${rejectTableHeader[3].width})` }}
        >
          {formatTwoPartDate(report.sendingDate).mainPart}
        </div>
      </TableItemWithTooltip>

      <div
        className={clsx(g.tableRow, g.tableRowFlex, rowStyle)}
        style={{ width: `calc((100vw - 180px - 3.75rem) * ${rejectTableHeader[4].width})` }}
      >
        <div className={rowStyle}> {getStatusByEnum(report.status)}</div>
        {report?.status === 'REJECTED' && report.errorMessage && (
          <div>
            <TableAction
              isButton
              onClick={errorInfoHandler}
              tooltipText={'Текст ошибки'}
              icon={info_button}
            />
          </div>
        )}
      </div>
      <div
        className={clsx(g.tableRow && rowStyle)}
        style={{ width: `calc((100vw - 180px - 3.75rem) * ${rejectTableHeader[5].width})` }}
      >
        {getDropoutReasonByEnum(report.dropoutReason)}
      </div>
      <TableItemWithTooltip
        tooltipValue={report.productName}
        styles={{ width: `calc((100vw - 180px - 3.75rem) * ${rejectTableHeader[6].width})` }}
      >
        <div className={clsx(g.tableRow && rowStyle)}>
          {rejectTableHeader[6]?.longerProduct
            ? report.productName
            : cropText(report.productName, 15)}
        </div>
      </TableItemWithTooltip>
      <div
        className={clsx(g.tableRow)}
        style={{ width: `calc((100vw - 180px - 3.75rem) * ${rejectTableHeader[7].width})` }}
      >
        {report.productGtin}
      </div>
      <div
        className={clsx(g.tableRow && rowStyle)}
        style={{ width: `calc((100vw - 180px - 3.75rem) * ${rejectTableHeader[8].width})` }}
      >
        {report.partNumber}
      </div>
      <div
        className={clsx(g.tableRow, rowStyle)}
        style={{ width: `calc((100vw - 180px - 3.75rem) * ${rejectTableHeader[9].width})` }}
      >
        {report.numberMarkingCodes}
      </div>
      <div
        style={{ width: `calc((100vw - 180px - 3.75rem) * ${rejectTableHeader[10].width})` }}
        className={`${clsx(g.tableRow, rowStyle)} ${s.tableActionsItems}`}
      >
        <TableAction
          link={`/reports/rejection/${report.id}`}
          params={{
            reportId: report.id,
            type: 'rejection'
          }}
          icon={info_button}
        />
      </div>
    </div>
  );
};

export default RejectionReportsTableRow;
