import { useState } from 'react';
import { Modal } from 'office-ui-fabric-react';
import FormContainer from 'components/Forms/FormContainer';
import s from './corrections-modal.module.scss';
import { NEW_SIDEPRODUCT_VALIDATION_SCHEMA } from 'pages/Products/AddSideProduct/addSideProducts.constants';
import { PrimaryButton, Checkbox } from 'office-ui-fabric-react';
import { Field } from 'formik';
import { correctionFields, compareTwoObjects, realInitValues } from './constants';
import reportsAPI from 'api/reportsAPI';
import { SimpLoader } from 'components/Loader/Loader';

const CorrectionsModal = ({ onDismiss, isOpen, initVals, reportId, getReport }) => {
  const [loading, setLoading] = useState(false);

  const onSubmit = async (data) => {
    setLoading(true);
    const {
      correctVsd,
      correctProductionDate,
      correctExpirationDate,
      correctVariableExpiration,
      correctWeight,
      correctTnVed,
      correctPermitDocs,
      correctLicenses,
      correctEmissionType
    } = data;
    await reportsAPI.sendCorrections(
      reportId,
      correctVsd,
      correctProductionDate,
      correctExpirationDate,
      correctVariableExpiration,
      correctWeight,
      correctTnVed,
      correctPermitDocs,
      correctLicenses,
      correctEmissionType
    );
    await getReport();
    setLoading(false);
    onDismiss();
  };

  return (
    <Modal containerClassName={s.modal} isOpen={isOpen} onDismiss={onDismiss} isBlocking={false}>
      {loading ? (
        <SimpLoader size="5rem" />
      ) : (
        <>
          <div>
            <h4 className={s.label}>Kорректировка сведений</h4>
          </div>

          <FormContainer
            enableReinitialize
            initialValues={realInitValues}
            onSubmit={onSubmit}
            className={s.formWrapper}
            // validationSchema={NEW_SIDEPRODUCT_VALIDATION_SCHEMA}
          >
            {(formik) => {
              const { values, initialValues } = formik;
              console.log('VALUES', values);
              return (
                <>
                  <section className={s.checkboxContainer}>
                    {correctionFields.map((corrField) => (
                      <Field name={corrField.fieldName} key={corrField.fieldName}>
                        {(props) => {
                          const { field, form } = props;
                          // console.log('field-prop', field);
                          // console.log('form-prop', form);

                          return (
                            <Checkbox
                              className={s.checkbox}
                              // disabled
                              label={corrField.russFieldName}
                              checked={field.checked}
                              disabled={!initVals[field.name]}
                              {...field}
                            />
                          );
                        }}
                      </Field>
                    ))}
                  </section>

                  <PrimaryButton
                    type="submit"
                    className={s.btn}
                    disabled={compareTwoObjects(initialValues, values)}
                    text="Отправить"
                  />
                </>
              );
            }}
          </FormContainer>
        </>
      )}
    </Modal>
  );
};

export default CorrectionsModal;
