import clsx from 'clsx';
import { Label, Stack, TextField } from 'office-ui-fabric-react';
import React, { useCallback, useEffect, useState } from 'react';
import SettingsAPI from '../../api/settingsAPI';
import g from '../../assets/scss/Main.module.scss';
import { Alert } from '../../components/Alert/Alert';
import { BottomButtons } from '../../components/BottomButtons/BottomButtons';
import { Confirm } from '../../components/Confirm/Confirm';
import { GrayPanel } from '../../components/GrayPanel/GrayPanel';
import { Loader } from '../../components/Loader/Loader';
import { ToggleCheckbox } from '../../components/ToggleCheckbox/ToggleCheckbox';
import { useAlert, useSendRequest } from '../../hooks';
import s from './Settings.module.scss';

const settingsTitles = {
  suz: 'Конфигурация СУЗ',
  gismt: 'Конфигурация ГИС МТ',
  catalog: 'Конфигурация API национального каталога',
  ftp: 'Конфигурация FTP',
  database: 'Конфигурация базы данных',
  company: 'Конфигурация компании'
};

const settingsKeys = {
  suz: {
    omsId: { name: 'Уникальный идентификатор СУЗ', type: 'text' },
    clientToken: { name: 'Токен авторизации', type: 'text' },
    omsConnection: { name: 'Идентификатор соединения', type: 'text' },
    participantInn: { name: 'ИНН Участника', type: 'text' },
    ownerInn: { name: 'ИНН Компании', type: 'text' },
    producerInn: { name: 'ИНН Производителя', type: 'text' }
  },
  gismt: {
    thumbprint: { name: 'Отпечаток ЭЦП', type: 'text' }
  },
  catalog: {
    apikey: { name: 'Идентификатор участника оборота', type: 'text' }
  },
  ftp: {
    server: { name: 'Сервер', type: 'text' },
    login: { name: 'Логин', type: 'text' },
    password: { name: 'Пароль', type: 'password' },
    delayBeforeDownloading: {
      name: 'Задержка перед загрузкой',
      type: 'number'
    }
  },
  database: {
    databaseName: { name: 'Имя базы данных', type: 'text' },
    serverName: { name: 'Сервер', type: 'text' },
    'defaultUser-userName': { name: 'Пользователь по умолчанию - имя', type: 'text' },
    'defaultUser-password': { name: 'Пользователь по умолчанию - пароль', type: 'password' },
    'migrationUser-userName': { name: 'Пользователь для выполнения миграций - имя', type: 'text' },
    'migrationUser-password': {
      name: 'Пользователь для выполнения миграций - пароль',
      type: 'password'
    }
  },
  company: {
    name: { name: 'Наименование организации', type: 'text' },
    prefix: { name: 'Префикс организации', type: 'text' },
    legalAddress: {
      name: 'Информация об юридическом адресе компании',
      type: 'text'
    },
    manufacturer: { name: 'Информация об изготовителе упаковки', type: 'text' },
    manufacturersAddress: {
      name: 'Информация об адресе производства для упаковки',
      type: 'text'
    }
  }
};

const settingsAPIRoutesMap = {
  get: {
    suz: SettingsAPI.getSuzSettings,
    gismt: SettingsAPI.getGisMTSettings,
    catalog: SettingsAPI.getCatalogSettings,
    ftp: SettingsAPI.getFTPSettings,
    database: SettingsAPI.getDatabaseSettings,
    company: SettingsAPI.getCompanySettings
  },
  put: {
    suz: SettingsAPI.updateSuzSettings,
    gismt: SettingsAPI.updateGisMTSettings,
    catalog: SettingsAPI.updateCatalogSettings,
    ftp: SettingsAPI.updateFTPSettings,
    database: SettingsAPI.updateDatabaseSettings,
    company: SettingsAPI.updateCompanySettings
  }
};

export const Settings = ({ settingsType }) => {
  const [loading, setLoading] = useState(false);
  const [settings, setSettings] = useState(null);
  const [isConfirmShowed, setIsConfirmShowed] = useState(false);

  const { alertTitle, alertText, isAlertShowed, showAlert, hideAlert } = useAlert();
  const { afterSending } = useSendRequest(setLoading);

  const getSettings = async () => {
    setLoading(true);
    const res = await settingsAPIRoutesMap.get[settingsType]();
    // ----------------- res ------------------
    if (res && settingsType === 'database') {
      res['defaultUser-userName'] = res.defaultUser.userName;
      res['defaultUser-password'] = res.defaultUser.password;
      res['migrationUser-userName'] = res.migrationUser.userName;
      res['migrationUser-password'] = res.migrationUser.password;
    }
    console.log('res from getSettings', res);
    setSettings(res);

    setLoading(false);
  };

  useEffect(() => {
    if (settingsType) {
      getSettings();
    }
  }, [settingsType]);

  const updateSettings = (name, value) => {
    setSettings((prev) => ({ ...prev, [name]: value }));
  };

  //============= save settings ========================
  const saveSettings = async () => {
    const onSuccess = () => {
      showAlert('Сохранение настроек', 'Настройки успешно сохранены');
      setIsConfirmShowed(false);
    };
    let dataToSend;

    if (settingsType === 'database') {
      dataToSend = {
        ...settings,
        defaultUser: {
          userName: settings['defaultUser-userName'],
          password: settings['defaultUser-password']
        },
        migrationUser: {
          userName: settings['migrationUser-userName'],
          password: settings['migrationUser-password']
        }
      };
      try {
        await settingsAPIRoutesMap.put[settingsType](dataToSend);
        onSuccess();
      } catch (e) {
        console.log('error from updateDatabase', e);
      }
    } else {
      dataToSend = { ...settings };
      const res = await settingsAPIRoutesMap.put[settingsType](dataToSend);
      afterSending(res, onSuccess);
    }
  };

  //============= save settings ========================

  const renderControl = useCallback(
    (entry, key) => {
      if (settingsKeys[settingsType][entry[0]]) {
        const label = settingsKeys[settingsType][entry[0]]?.name;
        const type = settingsKeys[settingsType][entry[0]]?.type;
        const required = settingsKeys[settingsType][entry[0]]?.required;

        if (type === 'checkbox') {
          return (
            <div key={key} className={s.infoRow}>
              <ToggleCheckbox
                label={label}
                value={!!entry[1]}
                onText={settingsKeys[settingsType][entry[0]]?.onText}
                offText={settingsKeys[settingsType][entry[0]]?.offText}
                onChange={() => setSettings((prev) => ({ ...prev, active: !prev.active }))}
              />
            </div>
          );
        }

        return (
          <div key={key} className={s.infoRow}>
            <TextField
              label={label}
              required={required}
              value={entry[1]}
              name={entry[0]}
              type={type}
              canRevealPassword={type === 'password'}
              onChange={(e) => updateSettings(e.target.name, e.target.value)}
            />
          </div>
        );
      }
    },
    [settingsType]
  );

  const updateTemplateId = (itemId, type) => (e) => {
    const value = e.target.value;
    const newTemplates = [...settings.templates];
    const findedTemplate = newTemplates.find((item) => item.id === itemId);

    if (type === 'templateId') {
      findedTemplate.templateId = +value;
    }

    if (type === 'codeLength') {
      findedTemplate.codeLength = +value;
    }

    setSettings((prev) => ({ ...prev, templates: newTemplates }));
  };

  return (
    <div>
      {isAlertShowed && <Alert title={alertTitle} text={alertText} onClose={hideAlert} />}
      {loading ? (
        <Loader />
      ) : (
        <>
          <div className={g.titleWrapper}>
            <h1 className={g.title}>{settingsTitles[settingsType]}</h1>
          </div>
          <GrayPanel className={s.panel}>
            <div className={s.mainInputs}>
              {settings && (
                <Stack tokens={{ childrenGap: 25 }}>
                  {Object.entries(settings).map((entry, i) => {
                    return renderControl(entry, i);
                  })}
                  {!!settings.templates && (
                    <>
                      <Label style={{ marginTop: 25, marginBottom: -20 }}>
                        Идентификаторы продуктов (template ID)
                      </Label>
                      {settings.templates.map(({ codeLength, id, name, templateId }) => {
                        return (
                          <div key={id} className={clsx(s.infoRow, s.templates)}>
                            <TextField
                              label={name}
                              className={s.templateInput}
                              value={templateId}
                              name={name}
                              onWheel={(e) => { e.currentTarget.blur() }}
                              type={'number'}
                              onChange={updateTemplateId(id, 'templateId')}
                            />
                            <TextField
                              label={'Длина кода'}
                              value={codeLength}
                              className={s.templateInput}
                              name={'codeLength'}
                              onWheel={(e) => { e.currentTarget.blur() }}
                              type={'number'}
                              onChange={updateTemplateId(id, 'codeLength')}
                            />
                          </div>
                        );
                      })}
                    </>
                  )}
                </Stack>
              )}
            </div>
          </GrayPanel>
          <div className={s.buttons}>
            <BottomButtons okText={'Сохранить'} okHandler={() => setIsConfirmShowed(true)} />
          </div>
        </>
      )}
      {isConfirmShowed && (
        <Confirm
          title="Вы уверены?"
          subText="Изменение настроек в случае ошибки может привести к сбоям работоспособности системы."
          okText="Сохранить"
          declineText="Отменить"
          onSend={saveSettings}
          setIsShow={setIsConfirmShowed}
        />
      )}
    </div>
  );
};
