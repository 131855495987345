import { call, put, takeLatest } from 'redux-saga/effects';
import {
  SET_LOADING,
  setCompanyAC,
  setErrorAC,
  setSuccessAC,
  START_UPDATE_COMPANY,
  successUpdateAC,
  START_ADD_COMPANY,
  successCreate,
  START_FETCHING_COMPANY
} from './legalFacesReducer';
import LegalApi from '../../api/legalAPI';

function* getInfoCompany(action) {
  try {
    // const dataCompany = yield call(LegalApi.getCompanyById, action.payload);
    const dataCompany = yield call(LegalApi.getCompanyById2, action.id);
    // const idSuz = dataCompany?.apiSuz?.id;
    // const dataSuz = yield call(LegalApi.getSuzById, idSuz);
    // yield put(setCompanyAC({ ...dataCompany, templates: dataSuz.templates }));
    yield put(setCompanyAC({ ...dataCompany }));
    yield put(setSuccessAC());
  } catch (e) {
    yield put(setErrorAC(e?.message));
  }
}

function* updateInfoCompany({ payload }) {
  try {
    const { id } = payload;
    yield call(LegalApi.putCompany, id, payload);
    const dataCompany = yield call(LegalApi.getCompanyById2, id);
    yield put(setCompanyAC({ ...dataCompany }));
    yield put(successUpdateAC());
  } catch (e) {
    yield put(setErrorAC(e?.message));
  }
}

function* addCompany({ payload }) {
  try {
    const newCompany = yield call(LegalApi.addCompany, payload);

    if (newCompany.id) {
      const dataCompany = yield call(LegalApi.getCompanyById2, newCompany.id);
      yield put(setCompanyAC({ ...dataCompany }));
      yield put(successCreate());
    } else {
      throw new Error(
        'Не удалось сохратиь юридическое лишо. Пожалуйста, обратитесь в службу поддержки.'
      );
    }
  } catch (e) {
    yield put(setErrorAC(e?.message));
  }
}

export function* LegalSaga() {
  //yield takeLatest(SET_LOADING, getInfoCompany, addCompany);
  yield takeLatest(START_FETCHING_COMPANY, getInfoCompany);
  yield takeLatest(START_UPDATE_COMPANY, updateInfoCompany);
  yield takeLatest(START_ADD_COMPANY, addCompany);
}
