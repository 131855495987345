import { call, put, takeLatest, delay } from '@redux-saga/core/effects'
import { select } from 'redux-saga/effects'
import LogsApi from '../../api/LogsApi'
import {GET_LOGS_ERROR, GET_LOGS_START, GET_LOGS_SUCCESS} from './LogsReducer'


function* getLogs(action) {
    try {
        const res = yield call(LogsApi.getLogs, action.payload)
        yield put({
            type: GET_LOGS_SUCCESS,
            payload: res
        })
        const isPolling = yield select(state => state.logs.isPolling)
        if(isPolling){
            yield delay(2500)
            yield put({
                type: GET_LOGS_START,
                payload: action.payload
            })
        }       
        
    } catch (err) {
        yield put({
            type: GET_LOGS_ERROR,
            payload: err?.message
        })
    }
    
}


export function* LogsSaga() {
    yield takeLatest(GET_LOGS_START, getLogs)
}
