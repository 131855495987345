import React, { Fragment, useEffect, useState } from 'react';
import { Dropdown, Modal, PrimaryButton, TextField, TooltipHost } from 'office-ui-fabric-react';
import questionIcon from '../../../../assets/img/questionIcon.svg';
import s from './index.module.scss';
import ProductsAPI from '../../../../api/productsAPI';
import { validateField } from '../../../../functions';

const aggregationOptions = [
  {
    key: 0,
    text: 'Код маркировки'
  },
  {
    key: 1,
    text: 'Код короба'
  },
  {
    key: 2,
    text: 'Код палета'
  }
];

const ModalPrint = ({ isOpen, onDismiss, data, gtin, productId }) => {
  const [aggregationLevel, setAggregationLevel] = useState(0);
  const [info, setInfo] = useState({ quantity: 0, copyQuantity: 0, numerationStartIndex: 0 });
  const [dataInputs, setDataInputs] = useState([]);
  const [formErrors, setFormErrors] = useState(new Set([]));
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    if (data) {
      setDataInputs(data);
    }
  }, [data]);

  const handleChange = (index) => (_, key) => {
    setDataInputs((prev) => prev.map((el, i) => (i === index ? { ...el, value: key } : el)));
  };

  const handleChangeAggregation = (_, key) => setAggregationLevel(key?.key);

  const handleChangeInfo = (name) => (_, key) => {
    let newErrors = new Set(formErrors);

    if (!validateField(name, key)) {
      newErrors.add(name);
    } else {
      newErrors.delete(name);
    }
    setInfo((prev) => ({ ...prev, [name]: +key }));
    setFormErrors(newErrors);
  };

  const handleSubmit = async () => {
    try {
      await setIsLoading(true);
      const baseUrlFromLS = localStorage.getItem('BASE_URL');
      const res = await ProductsAPI.setPrintingParameters({
        ...info,
        parameters: dataInputs,
        aggregationLevel,
        productGTIN: gtin,
        productId
      });

      if (res?.relativePath) {
        await window.open(
          `${baseUrlFromLS || process.env.REACT_APP_BASE_URL || ''}${res.relativePath}`
        );
        onDismiss();
      }
      await setIsLoading(false);
    } catch (e) {
      console.log(e);
    }
  };

  return (
    <Modal containerClassName={s.modal} isOpen={isOpen} onDismiss={onDismiss} isBlocking={false}>
      <TextField
        label="Количество"
        errorMessage={formErrors.has('quantity') && 'Обязательное поле'}
        required
        value={info.quantity}
        name={'quantity'}
        type={'number'}
        onWheel={(e) => { e.currentTarget.blur() }}
        onChange={handleChangeInfo('quantity')}
        className={s.input}
      />

      <TextField
        label="Количество копий"
        errorMessage={formErrors.has('copyQuantity') && 'Обязательное поле'}
        required
        value={info.copyQuantity}
        name={'copyQuantity'}
        onWheel={(e) => { e.currentTarget.blur() }}
        type={'number'}
        onChange={handleChangeInfo('copyQuantity')}
        className={s.input}
      />

      <TextField
        label="Стартовый индекс"
        errorMessage={formErrors.has('numerationStartIndex') && 'Обязательное поле'}
        required
        value={info.numerationStartIndex}
        name={'numerationStartIndex'}
        onWheel={(e) => { e.currentTarget.blur() }}
        type={'number'}
        onChange={handleChangeInfo('numerationStartIndex')}
        className={s.input}
      />

      <Dropdown
        style={{ marginBottom: 25 }}
        label="Тип кода"
        selectedKey={aggregationLevel}
        options={aggregationOptions}
        onChange={handleChangeAggregation}
      />

      {dataInputs?.map((el, index) => (
        <Fragment key={`modal-print__${index}`}>
          <TooltipHost content={el?.description} id={`modal-print__${index}`}>
            <label className={s.label} id={`modal-print__${index}`}>
              <span>{el?.title}</span>
              {el?.description && <img src={questionIcon} alt="" />}
            </label>
          </TooltipHost>

          <TextField
            value={el?.value}
            name={el?.name}
            onChange={handleChange(index)}
            className={s.input}
          />
        </Fragment>
      ))}

      <div className={s.buttons}>
        <PrimaryButton
          disabled={isLoading}
          text="Печать"
          style={{ flexGrow: 1 }}
          onClick={handleSubmit}
        />
        <PrimaryButton
          text="Отмена"
          style={{ marginLeft: '10px', flexGrow: 1 }}
          onClick={onDismiss}
        />
      </div>
    </Modal>
  );
};

export default ModalPrint;
