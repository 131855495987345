import { useState, useCallback, memo } from 'react';
import sMain from './ProductsCard.module.scss';
import s from './RemainingCodes.module.scss';
import RemainingQuantMarcCodes from './RemainingQuantiMarkCodes';
import { sepThousands } from '../core';
import clsx from 'clsx';

const RemainingCodesWTooltip = ({ remainderMarkingCodes, codesData }) => {
  const [isToolTip, setToolTip] = useState(false);
  const val = sepThousands(remainderMarkingCodes);

  const handleShowTooltip = useCallback(() => {
    setToolTip(true);
  }, []);

  const hideToolTip = useCallback((e) => {
    setToolTip(false);
  }, []);

  return (
    <span
      className={sMain.remains}
      style={{ position: 'relative' }}
      onMouseEnter={handleShowTooltip}
      onMouseLeave={hideToolTip}
    >
      {
        <>
          Текущий остаток КМ: <b>{val}</b>
        </>
      }
      {isToolTip && (
        <section
          className={
            val.length < 3
              ? s.informationBoxProdPage
              : val.length < 5
              ? clsx(s.informationBoxProdPage, s.longerString)
              : clsx(s.informationBoxProdPage, s.longString)
          }
        >
          <RemainingQuantMarcCodes data={codesData} />
        </section>
      )}
    </span>
  );
};

export default memo(RemainingCodesWTooltip);
