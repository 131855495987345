import { Checkbox, SearchBox } from 'office-ui-fabric-react';
import React, { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { NumberParam, StringParam, useQueryParams } from 'use-query-params';
import ProductsAPI from '../../../api/productsAPI';
import { Pagination } from 'components/Pagination/Pagination';
import { Table } from 'components/Table/Table';
import { TableAction } from 'components/TableAction/TableAction';
import {
  bgColor,
  lightBlue,
  moreInfoIcon,
  orderStatuses,
  pairedOrderStatuses,
  orderStatuses20
} from '../../../constants';
import { useCrumbsContext } from '../../../context/CrumbsContext';
import { cropText, getDateFromTimestamp, getStatusById, tableCellWidth } from '../../../functions';
import { useCheckboxFilter, usePaginationAndSearch } from '../../../hooks';
import { TableItemWithTooltip } from '../../Reports/components/TableItemWithTooltip';
import { useAlert } from '../../../hooks';
import { Alert } from 'components/Alert/Alert';
import s from './Orders.module.scss';
import g from 'assets/scss/Main.module.scss';
import clsx from 'clsx';
import infoIcon from '../../../assets/img/Info.svg';

const tableHeader = [
  {
    title: 'Продукт',
    width: '0.20'
  },
  {
    title: 'Код заказа',
    width: '0.2'
  },
  {
    title: 'Дата заказа',
    width: '0.14'
  },
  {
    title: 'Статус заказа',
    width: '0.2'
  },
  {
    title: 'Кол-во КМ',
    width: '0.12'
  },
  {
    title: 'Действия',
    width: '0.14'
  }
];
const ordersTableHeader = [

  {
    title: 'Код заказа',
    width: '0.25'
  },
  {
    title: 'Дата заказа',
    width: '0.14'
  },
  {
    title: 'Статус заказа',
    width: '0.12'
  },
  {
    title: 'Продукт',
    width: '0.24'
  },
  {
    title: 'Количество КМ',
    width: '0.12'
  },
  {
    title: 'Действия',
    width: '0.13'
  }
];

const searchBoxStyles = {
  root: { width: '28.75rem', background: bgColor, borderColor: lightBlue }
};

export const Orders = ({ status, statusParamName }) => {
  console.log('status-from orders', status);
  const { setCrumbs } = useCrumbsContext();
  const { params } = useLocation();
  const [orders, setOrders] = useState([]);
  const [orderInfo, setOrderInfo] = useState(null);
  const [loading, setLoading] = useState(false);

  const orderWidth = status ? tableCellWidth(ordersTableHeader) : tableCellWidth(tableHeader);

  useEffect(() => {
    if (params) {
      setQuery((prev) => {
        console.log('params', params);
        return { ...prev, productId: params.id };
      }, 'replace');
    } else {
      if (!status) {
        // setQuery({})
      }
    }
    return async () => {
      // await setQuery({})
    };
  }, []);

  const [query, setQuery] = useQueryParams({
    pageNumber: NumberParam,
    quantityOnPage: NumberParam,
    productId: NumberParam,
    orderStatus: StringParam,
    searchValue: StringParam
  });

  const getOrders = async (query) => {
    setLoading(true);
    let res = await ProductsAPI.getOrders(query);
    const { list, quantity, info } = res;
    await setOrders(list);
    await setOrderInfo(info);
    await setTotalItems(quantity);
    await setLoading(false);
  };

  const { alertTitle, alertText, isAlertShowed, showAlert, hideAlert } = useAlert();
  const errorInfoHandler = (msg) => {
    showAlert('Текст ошибки', msg);
  };

  // useEffect(() => {
  //   if (!params) {
  //     if (!status) {
  //       // getBack()
  //     }
  //   } else {
  //     if (params.status && !query.orderStatus) {
  //       console.log('from if', params);
  //       setQuery((prev) => ({ ...prev, orderStatus: params.status }), 'replace');
  //     }
  //   }
  // }, [params]);

  useEffect(() => {
    if (query.productId) {
      setCrumbs(['/products', 'Заказы КМ']);
    }
  }, [query]);

  useEffect(() => {
    if (status && !query.orderStatus) {
      console.log('from if', status);
      setQuery((prev) => ({ ...prev, orderStatus: status }), 'replace');
    }
  }, [status]);

  const [selectedFilters, onChangeFilter] = useCheckboxFilter(
    pairedOrderStatuses,
    'orderStatus',
    setQuery,
    query
  );
  const { onPageChanged, onSearchTermChanged, totalPages, setTotalItems, currentPage } =
    usePaginationAndSearch({
      callback: getOrders,
      query,
      setQuery,
      status: status || params?.status,
      statusParamName: statusParamName || params?.statusParamName
    });

  return (
    <div>
      <div className={g.titleWrapper}>
        <h1 className={g.title}>{status ? 'Заказы кодов маркировки' : orderInfo?.name}</h1>
      </div>
      {!status && orderInfo && <span className={g.code}>{`GTIN: ${orderInfo?.gtin}`}</span>}
      <div className={s.header} style={{ marginTop: 20 }}>
        <div className={s.headerSearch}>
          <span className={g.headerTitle}>{!status ? 'Заказы кодов маркировки' : ''}</span>
          <SearchBox
            styles={searchBoxStyles}
            className={s.search}
            placeholder={
              status ? 'Поиск по коду заказа, продукту или gtin' : 'Поиск по коду заказа'
            }
            onClear={() => {
              onSearchTermChanged('');
            }}
            onSearch={(newValue) => onSearchTermChanged(newValue)}
          />
        </div>
        <div className={s.chBoxes} style={{ marginTop: 20 }}>
          {pairedOrderStatuses.map((item) => {
            return (
              <Checkbox
                key={item.id}
                label={item.text}
                checked={selectedFilters.has(item.key)}
                onChange={() => {
                  onChangeFilter(item.key);
                  const objectWithSecondStatusKey = orderStatuses.find(
                    (status) => status.id === item.pairedStatusId
                  );
                  const objectWithThirdStatusKey = orderStatuses20.find(
                    (status) => status.id === item.secondPairdStatusId
                  );
                  // console.log('item 2', objectWithSecondStatusKey, objectWithThirdStatusKey);
                  if (objectWithSecondStatusKey) onChangeFilter(objectWithSecondStatusKey.key);
                  if (objectWithThirdStatusKey) onChangeFilter(objectWithThirdStatusKey.key);
                }}
              />
            );
          })}
        </div>
      </div>
      <Table
        headerItems={status ? ordersTableHeader : tableHeader}
        maxHeight="60vh"
        loading={loading}
      >
        {isAlertShowed && (
          <Alert
            title={alertTitle}
            text={alertText}
            onClose={() => {
              hideAlert();
            }}
          />
        )}
        {console.log('orders', orders)}
        {status
          ? orders.map((item) => {
            return (
              <div key={item.id} className={g.tableItem}>
                <div className={g.tableCell} style={{ width: orderWidth(0) }}>
                  {item.product?.name}
                </div>
                <div className={g.tableCell} style={{ width: orderWidth(0) }}>
                  {item.suzId}
                </div>
                <div className={g.tableCell} style={{ width: orderWidth(1) }}>
                  {getDateFromTimestamp(item.creationDate)}
                </div>
                <div
                  className={g.tableCell}
                  style={{ width: orderWidth(2), alignItems: 'center' }}
                >
                  {item.errorMessage ? (
                    <span style={{ color: 'red' }}>Обработан с ошибкой</span>
                  ) : (
                    getStatusById(item.status, [
                      ...orderStatuses,
                      ...orderStatuses20,
                      ...pairedOrderStatuses
                    ])
                  )}
                  {item.errorMessage && (
                    <div style={{ display: 'flex', marginLeft: '5px', alignSelf: 'start' }}>
                      <TableAction
                        isButton
                        onClick={() => errorInfoHandler(item.errorMessage)}
                        tooltipText={'Текст ошибки'}
                        icon={infoIcon}
                      />
                    </div>
                  )}
                </div>
                <TableItemWithTooltip tooltipValue={item.product?.name}>
                  <div
                    style={{ width: orderWidth(3) }}
                    className={clsx(g.tableRow && g.tableCell)}
                  >
                    {cropText(item.product?.name)}
                  </div>
                </TableItemWithTooltip>
                <div className={g.tableCell} style={{ width: orderWidth(4) }}>
                  {item.markingCodesQuantity}
                </div>
                <div
                  className={s.tableActionCell}
                  style={{ width: orderWidth(5), display: 'flex', alignItems: 'center' }}
                >
                  <TableAction
                    link={`/products/codesList`}
                    params={{
                      orderId: item.id,
                      status: 'NEW,UTILISATED,AGGREGATED,INROLLOUT,SCANED,INVALID',
                      statusParamName: 'status',
                      prevRoute: '/orders'
                    }}
                    text="Подробнее"
                    icon={infoIcon}
                  />
                </div>
              </div>
            );
          })
          : orders.map((item) => {
            return (
              <div key={item.id} className={g.tableItem}>
                <div className={g.tableCell} style={{ width: orderWidth(0) }}>
                  {item.product?.name}
                </div>
                <div className={g.tableCell} style={{ width: orderWidth(0) }}>
                  {item.suzId}
                </div>
                <div className={g.tableCell} style={{ width: orderWidth(1) }}>
                  {getDateFromTimestamp(item.creationDate)}
                </div>
                <div
                  className={g.tableCell}
                  style={{
                    width: orderWidth(2),
                    display: 'flex',
                    alignItems: 'center',
                    gap: '7px'
                  }}
                >
                  {item.errorMessage
                    ? 'Обработан с ошибкой'
                    : getStatusById(item.status, [
                      ...orderStatuses,
                      ...orderStatuses20,
                      ...pairedOrderStatuses
                    ])}
                  {item.errorMessage && (
                    <TableAction
                      isButton
                      onClick={() => errorInfoHandler(item.errorMessage)}
                      tooltipText={'Текст ошибки'}
                      icon={infoIcon}
                    />
                  )}
                </div>
                <div className={g.tableCell} style={{ width: orderWidth(3) }}>
                  {item.markingCodesQuantity}
                </div>
                <div className={g.tableCell} style={{ width: orderWidth(4) }}>
                  <TableAction
                    link={`/products/codesList`}
                    params={{
                      orderId: item.id,
                      status: 'NEW,UTILISATED,AGGREGATED,INROLLOUT,SCANED,INVALID',
                      id: params?.id,
                      productName: params?.productName,
                      templateId: params?.templateId,
                      productGtin: params?.productGtin,
                      orderCode: item.suzId,
                      orderStatus: item.status
                    }}
                    text="Подробнее"
                  // icon={infoIcon}
                  />
                </div>
              </div>
            );
          })}
      </Table>
      {totalPages > 1 && (
        <Pagination
          pageCount={totalPages}
          onPageChange={onPageChanged}
          selectedPage={currentPage}
        />
      )}
    </div>
  );
};
