import { useState } from 'react';
import { TableAction } from '../../../components/TableAction/TableAction';
import AddSiteProductModal from './AddModal';
import s from './AddSideProduct.module.scss';
import { addIcon } from '../../../constants';

const AddSiteProductButton = ({ query, getProducts }) => {
  const [showModal, setShowModal] = useState(false);
  const openModal = () => setShowModal(true);
  const closeModal = () => setShowModal(false);

  const getAllProducts = async () => getProducts(query);

  return (
    <>
      <div className={s.mainButtonWrapper}>
        <TableAction
          type={'accent'}
          addStyles={{ width: '16rem' }}
          isButton
          text={'Добавить сторонний продукт'}
          onClick={openModal}
          icon={addIcon}
        />
      </div>

      {showModal && (
        <AddSiteProductModal
          isOpen={showModal}
          onDismiss={closeModal}
          getProducts={getAllProducts}
        />
      )}
    </>
  );
};

export default AddSiteProductButton;
