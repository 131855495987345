import { serializeQueryObj } from '../functions'
import API from './APIService'

class AggregatesAPI {
    getAggregates = (query) => API.get(`/api/Aggregation/GetUnit?${serializeQueryObj(query)}`)
    getAggregateByCode = (aggregationCode) => API.get(`/api/Scan/Get/?scanedValue=${aggregationCode}`)
    getAggregationIncluded = () => API.get('/api/auth/CheckModuleAvaible/?moduleName=Aggregation')
}

export default new AggregatesAPI()
