import { Dropdown, Stack } from 'office-ui-fabric-react'
import React, { useEffect, useState } from 'react'
import ProductsAPI from '../../../api/productsAPI'
import { lineStatuses } from '../../../constants'
import { mapItemsForDropdown } from '../../../functions'
import { FilterButtons } from '../FilterButtons/FilterButtons'
import {setFilteredData} from 'redux/App/appReducer'
import { useDispatch } from 'react-redux';

export const LinesFilter = ({ query, setQuery, setIsOpenFilters }) => {
    const [_product, _setProduct] = useState(query.productId)
    const [_status, _setStatus] = useState(query.status)
    const [productsForDropdown, setProductsForDropdown] = useState([])
    const dispatch = useDispatch()
    useEffect(() => {
        ProductsAPI.getAllProductsNoMarking().then(res => setProductsForDropdown(mapItemsForDropdown(res)))
    }, [])

    const onConfirm = () => {
        const filterObj = {
            status: _status, 
            productId: _product, 
            pageNumber: 1 
        }

        setIsOpenFilters(false)
        setQuery(prev => ({ ...prev, ...filterObj}), 'replace')
        dispatch(setFilteredData(filterObj));
    }

    const onDismiss = () => {
        _setStatus(null)
        _setProduct(null)
        const filterObj = {
            status: undefined, 
            productId: undefined
        }

        setQuery(prev => ({ ...prev, ...filterObj }), 'replace')
        setIsOpenFilters(false)
        dispatch(setFilteredData(filterObj));
    }

    const changeFilterValue = (value, fieldName) => {
        if (fieldName === 'status') {
            _setStatus(value)
        } else {
            _setProduct(value)
        }       
    }

    return (
        <Stack tokens={{ childrenGap: 25 }}>
            <Dropdown
                label="Статус"
                selectedKey={_status}
                options={lineStatuses}
                onChange={(_, item) => changeFilterValue(item.key, 'status')}
            />
            <Dropdown
                label="Продукт"
                selectedKey={_product}
                options={productsForDropdown}
                onChange={(_, item) => changeFilterValue(item.key, 'product')}
            />
            <div>
                <FilterButtons onSubmit={onConfirm} onDismiss={onDismiss} />
            </div>
        </Stack>
    )
}
