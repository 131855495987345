import {DefaultButton, PrimaryButton} from 'office-ui-fabric-react'
import React from 'react'
import s from './FilterButtons.module.scss'

export const FilterButtons = ({onSubmit, onDismiss}) => {
    return (
        <div className={s.buttons}>
            <PrimaryButton onClick={onSubmit} className={s.btn} text="Применить"/>
            <DefaultButton onClick={onDismiss} className={s.btn} text="Сбросить"/>
        </div>
    )
}
