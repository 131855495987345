import React, { useEffect, useState } from 'react';
import { Loader } from '../../components/Loader/Loader';
import g from '../../assets/scss/Main.module.scss';
import s from './index.module.scss';
import { useHistory, useParams, useLocation } from 'react-router-dom';
import FormContainer from '../../components/Forms/FormContainer';
import { useDispatch, useSelector } from 'react-redux';
import {
  resetStateAC,
  startLoadingCompanyInfoAC,
  startUpdateCompanyAC
} from '../../redux/LegalFaces/legalFacesReducer';
import {
  getCompanyInfo,
  getIsLoadingCompany,
  getSuccessUpdate
} from '../../redux/LegalFaces/legalFacesSelectors';
import { PrimaryButton } from 'office-ui-fabric-react';
import { COMPANY_VALIDATION_SCHEMA } from '../../constants/VALIDATION_SCHEME';
import { Confirm } from '../../components/Confirm/Confirm';
import { Alert } from '../../components/Alert/Alert';
import CompanyConfig from './components/CompanyConfig';
import SuzConfig from './components/SuzConfig';
import GismtConfig from './components/GismtConfig';
import { useCrumbsContext } from '../../context/CrumbsContext';
import { setCompanyAC } from '../../redux/LegalFaces/legalFacesReducer';
import legalAPI from 'api/legalAPI';

const Company = () => {
  const dispatch = useDispatch();
  const isLoading = useSelector(getIsLoadingCompany);
  const companyInfo = useSelector(getCompanyInfo);
  companyInfo.initPlacesOfBusiness = companyInfo.placeOfBusinesses;
  const isSuccessUpdate = useSelector(getSuccessUpdate);
  const [isConfirmShowed, setIsConfirmShowed] = useState(false);
  const [submitData, setSubmitData] = useState({});
  const { faceID } = useParams();
  const location = useLocation();
  const { setCrumbs } = useCrumbsContext();
  const [showAlert, setShowAlert] = useState(false);

  useEffect(() => {
    if (isSuccessUpdate) setShowAlert(true);
  }, [isSuccessUpdate]);

  useEffect(() => {
    return () => dispatch(resetStateAC());
  }, [faceID]);

  useEffect(() => {
    const getCompanyInfo = async (compId) => {
      const legalFace = await legalAPI.getCompanyById2(compId);
      dispatch(setCompanyAC(legalFace));
    };

    if (!companyInfo?.id && faceID) {
      getCompanyInfo(faceID);
    }
  }, [faceID, companyInfo, companyInfo?.id]);

  useEffect(() => {
    setCrumbs([location.pathname.slice(0, location.pathname.lastIndexOf('/')), companyInfo?.name]);
  }, [companyInfo]);

  const initialValues = {
    name: '',
    glnPrefix: '',
    legalAddress: '',
    manufacturer: '',
    manufacturerAddress: '',
    participantInn: '',
    ownerInn: '',
    producerInn: '',
    omsId: '',
    omsConnection: '',
    thumbPrint: '',
    isProductionApi: true,
    isArchived: false,
    productGroups: [],
    placeOfBusinesses: []
  };

  const onSubmit = (values) => {
    const data = {
      ...values,
      suzId: companyInfo?.suzId,
      gisMtId: companyInfo?.gisMtId,
      natCatId: companyInfo?.natCatId
    };
    setIsConfirmShowed(true);
    setSubmitData(data);
    delete data.allProductGroups;
    delete data.initPlacesOfBusiness;
    data.placeOfBusinesses = data.placeOfBusinesses.map((pl) => {
      if (pl.name) {
        return { productGroup: pl.productGroup, placeOfBusinessId: pl.placeOfBusinessId };
      }
      return pl;
    });
  };

  const handleSaveSetting = () => {
    dispatch(startUpdateCompanyAC(submitData));
    setIsConfirmShowed(false);
  };

  const handleCloseSuccess = () => setShowAlert(false);

  const isEmptyCompanyInfo = Object.keys(companyInfo).length && companyInfo;

  return (
    <div>
      {showAlert && (
        <Alert
          title="Сохранение настроек"
          text="Настройки успешно сохранены"
          onClose={handleCloseSuccess}
        />
      )}

      {isLoading ? (
        <Loader />
      ) : (
        <>
          <div className={g.titleWrapper}>
            <h1 className={g.title}>Юридические лица</h1>
          </div>

          <FormContainer
            enableReinitialize
            initialValues={isEmptyCompanyInfo || initialValues}
            onSubmit={onSubmit}
            className={s.form}
            validationSchema={COMPANY_VALIDATION_SCHEMA}
          >
            {(formik) => {
              const { isValid } = formik;

              return (
                <>
                  <CompanyConfig />

                  <SuzConfig companyId={companyInfo.id} values={formik.values} />
                  <GismtConfig />
                  {/* <CatalogConfig /> */}
                  {/* <TemplatesConfig templatesData={companyInfo?.templates} /> */}
                  <PrimaryButton
                    type="submit"
                    className={s.btn}
                    disabled={!isValid}
                    text="Сохранить"
                  />
                </>
              );
            }}
          </FormContainer>
        </>
      )}
      {isConfirmShowed && (
        <Confirm
          title="Вы уверены?"
          subText="Изменение настроек в случае ошибки может привести к сбоям работоспособности системы."
          okText="Сохранить"
          declineText="Отменить"
          onSend={handleSaveSetting}
          setIsShow={setIsConfirmShowed}
        />
      )}
    </div>
  );
};

export default Company;
