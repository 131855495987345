import { Checkbox } from 'office-ui-fabric-react';
import React from 'react';
import clsx from 'clsx';
import g from '../../../assets/scss/Main.module.scss';
import s from '../../Products/Products.module.scss';
import { TableAction } from '../../../components/TableAction/TableAction';
import { moreInfoIcon } from '../../../constants';
import { cropText, getStatusByEnum, isShipped, formatTwoPartDate } from '../../../functions';
import { aggregationTableHeader, isChkboxDisbabled } from '../constants';
import { TableItemWithTooltip } from './TableItemWithTooltip';
import { useAlert } from '../../../hooks';
import { Alert } from '../../../components/Alert/Alert';
import info_button from '../../../assets/img/info_button.svg';

export const AggregationReportsTableRow = ({
  report,
  onSelectReport,
  selectedReports,
  rowStyle = s.tableCell
}) => {
  const { alertTitle, alertText, isAlertShowed, showAlert, hideAlert } = useAlert();
  const errorInfoHandler = () => {
    showAlert('Текст ошибки', report?.errorMessage);
  };
  return (
    <div className={g.tableItem}>
      {isAlertShowed && (
        <Alert
          title={alertTitle}
          text={alertText}
          onClose={() => {
            hideAlert();
          }}
        />
      )}
      <div
        className={g.tableRow}
        style={{ width: `calc((100vw - 180px - 3.75rem) * ${aggregationTableHeader[0].width})` }}
      >
        <Checkbox
          onChange={() => onSelectReport(report)}
          disabled={isShipped(report.status) || isChkboxDisbabled(report.status)}
          checked={selectedReports.has(report.id)}
        />
      </div>
      <TableItemWithTooltip
        tooltipValue={report.suzId}
        styles={{ width: `calc((100vw - 180px - 3.75rem) * ${aggregationTableHeader[1].width})` }}
      >
        <div className={clsx(g.tableRow, rowStyle)}>{cropText(report.suzId, 15)}</div>
      </TableItemWithTooltip>

      <TableItemWithTooltip
        tooltipValue={report.unitSerialNumber}
        styles={{ width: `calc((100vw - 180px - 3.75rem) * ${aggregationTableHeader[2].width})` }}
      >
        <div className={clsx(g.tableRow, rowStyle)}>
          {aggregationTableHeader[2].maxLength >= report?.unitSerialNumber?.toString().length
            ? report.unitSerialNumber
            : cropText(report.unitSerialNumber, 10)}
        </div>
      </TableItemWithTooltip>

      {/* <div
        className={clsx(g.tableRow, rowStyle)}
        style={{ width: `calc((100vw - 180px - 3.75rem) * ${aggregationTableHeader[2].width})` }}
      >
        {report.unitSerialNumber}
      </div> */}
      {/* ===== дата создания ===== */}
      <TableItemWithTooltip tooltipValue={formatTwoPartDate(report.creationDate).toolTipPart}>
        <div
          className={clsx(g.tableRow, rowStyle)}
          style={{ width: `calc((100vw - 180px - 3.75rem) * ${aggregationTableHeader[3].width})` }}
        >
          {formatTwoPartDate(report.creationDate).mainPart}
        </div>
      </TableItemWithTooltip>
      {/* ===== =============================================================================== ===== */}

      {/* ===== дата отправки ======*/}
      <TableItemWithTooltip tooltipValue={formatTwoPartDate(report.sendingDate).toolTipPart}>
        <div
          className={clsx(g.tableRow, rowStyle)}
          style={{ width: `calc((100vw - 180px - 3.75rem) * ${aggregationTableHeader[4].width})` }}
        >
          {formatTwoPartDate(report.sendingDate).mainPart}
        </div>
      </TableItemWithTooltip>
      {/* ===== ================================================================================= ===== */}
      <div
        className={clsx(g.tableRow, g.tableRowFlex, rowStyle)}
        style={{ width: `calc((100vw - 180px - 3.75rem) * ${aggregationTableHeader[5].width})` }}
      >
        <div>{getStatusByEnum(report.status)}</div>
        {report?.status === 'REJECTED' && report.errorMessage && (
          <div>
            <TableAction
              isButton
              onClick={errorInfoHandler}
              tooltipText={'Текст ошибки'}
              icon={info_button}
            />
          </div>
        )}
      </div>
      <TableItemWithTooltip
        tooltipValue={report.productName}
        styles={{ width: `calc((100vw - 180px - 3.75rem) * ${aggregationTableHeader[6].width})` }}
      >
        <div className={clsx(g.tableRow, rowStyle)}>
          {aggregationTableHeader[6]?.longerProduct
            ? report.productName
            : cropText(report.productName, 15)}
        </div>
      </TableItemWithTooltip>
      <div
        className={clsx(g.tableRow, rowStyle)}
        style={{ width: `calc((100vw - 180px - 3.75rem) * ${aggregationTableHeader[7].width})` }}
      >
        {report.productGtin}
      </div>
      <div
        className={clsx(g.tableRow, rowStyle)}
        style={{ width: `calc((100vw - 180px - 3.75rem) * ${aggregationTableHeader[8].width})` }}
      >
        {report.partNumber}
      </div>
      <div
        className={clsx(g.tableRow, rowStyle)}
        style={{ width: `calc((100vw - 180px - 3.75rem) * ${aggregationTableHeader[9].width})` }}
      >
        {report.numberMarkingCodes}
      </div>
      <div
        style={{ width: `calc((100vw - 180px - 3.75rem) * ${aggregationTableHeader[10].width})` }}
        className={clsx(`${g.tableRow} ${g.tableActionsItems}`, rowStyle)}
      >
        <TableAction
          link={`/reports/aggregation/${report.id}`}
          params={{
            reportId: report.id,
            type: 'aggregation'
          }}
          icon={info_button}
        />
      </div>
    </div>
  );
};
