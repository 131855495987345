import { DatePicker, DayOfWeek, Dropdown, Label, Stack } from 'office-ui-fabric-react';
import React, { useEffect, useState } from 'react';
import g from '../../../assets/scss/Main.module.scss';
import LegalApi from '../../../api/legalAPI';
import { DayPickerStrings, reportStatuses, reportStatuseForPrinting } from '../../../constants';
import {
  checkDateError,
  convertToUTCDate,
  getDateFromTimestamp,
  mapItemsForDropdown
} from '../../../functions';
import { FilterButtons } from '../FilterButtons/FilterButtons';
import { setFilteredData } from 'redux/App/appReducer';
import { useDispatch } from 'react-redux';
import s from './ReportsFilter.module.scss';

export const ReportsFilter = ({ query, setQuery, setIsOpenFilters, type }) => {
  const [minCreationDate, setMinCreationDate] = useState(
    query?.minCreationDate ? new Date(query.minCreationDate) : null
  );
  const [maxCreationDate, setMaxCreationDate] = useState(
    query?.maxCreationDate ? new Date(query.maxCreationDate) : null
  );
  const [minProductCreationDate, setMinProductCreationDate] = useState(
    query?.minProductCreationDate ? new Date(query.minProductCreationDate) : null
  );
  const [maxProductCreationDate, setMaxProductCreationDate] = useState(
    query?.maxProductCreationDate ? new Date(query.maxProductCreationDate) : null
  );
  const [minSendingDate, setMinSendingDate] = useState(
    query?.minSendingDate ? new Date(query.minSendingDate) : null
  );
  const [maxSendingDate, setMaxSendingDate] = useState(
    query?.maxSendingDate ? new Date(query.maxSendingDate) : null
  );
  const [status, setStatus] = useState(query?.status);
  const [dateErrors, setDateErrors] = useState(new Set([]));
  const [companyId, setCompanyId] = useState(query?.companyId);
  const [companiesForDropdown, setCompaniesForDropdown] = useState([]);
  const dispatch = useDispatch();

  useEffect(() => {
    LegalApi.getCompanies().then((res) => setCompaniesForDropdown(mapItemsForDropdown(res)));
  }, []);

  const onConfirm = () => {
    const filterObj = {
      status: status || undefined,
      minCreationDate: minCreationDate ? minCreationDate.getTime() : undefined,
      maxCreationDate: maxCreationDate ? maxCreationDate.getTime() : undefined,
      minSendingDate: minSendingDate ? minSendingDate.getTime() : undefined,
      maxSendingDate: maxSendingDate ? maxSendingDate.getTime() : undefined,
      minProductCreationDate: minProductCreationDate ? minProductCreationDate.getTime() : undefined,
      maxProductCreationDate: maxProductCreationDate ? maxProductCreationDate.getTime() : undefined,
      pageNumber: 1,
      companyId: companyId || undefined,
      type
    };

    if (!dateErrors.size) {
      setQuery(
        (prev) => ({
          ...prev,
          ...filterObj
        }),
        'replace'
      );
      setIsOpenFilters(false);
      dispatch(setFilteredData(filterObj));
    }
  };

  const onDismiss = () => {
    setStatus(null);
    setMinCreationDate(null);
    setMaxCreationDate(null);
    setMinSendingDate(null);
    setMaxSendingDate(null);
    setMinProductCreationDate(null);
    setMaxProductCreationDate(null);
    setDateErrors(new Set([]));
    setCompanyId(null);

    const filterObj = {
      status: undefined,
      minCreationDate: undefined,
      maxCreationDate: undefined,
      minSendingDate: undefined,
      maxSendingDate: undefined,
      minProductCreationDate: undefined,
      maxProductCreationDate: undefined,
      companyId: undefined
    };

    setQuery(
      (prev) => ({
        ...prev,
        ...filterObj
      }),
      'replace'
    );
    dispatch(setFilteredData(filterObj));
    setDateErrors(new Set([]));
    setIsOpenFilters(false);
  };

  useEffect(() => {
    checkDateError(
      {
        sendingDate: [minSendingDate, maxSendingDate],
        creationDate: [minCreationDate, maxCreationDate],
        productCreationDate: [minProductCreationDate, maxProductCreationDate]
      },
      dateErrors,
      setDateErrors
    );
  }, [
    minSendingDate,
    maxSendingDate,
    minCreationDate,
    maxCreationDate,
    minProductCreationDate,
    maxProductCreationDate
  ]);

  const changeFilterValue = (value, fieldName) => {
    switch (fieldName) {
      case 'minCreationDate':
        setMinCreationDate(value);
        break;
      case 'maxCreationDate':
        setMaxCreationDate(value);
        break;
      case 'minSendingDate':
        setMinSendingDate(value);
        break;
      case 'maxSendingDate':
        setMaxSendingDate(value);
        break;
      // extra cases
      case 'minProductCreationDate':
        setMinProductCreationDate(value);
        break;
      case 'maxProductCreationDate':
        setMaxProductCreationDate(value);
        break;
      case 'companyId':
        setCompanyId(value);
        break;
      default:
        setStatus(value);
    }
  };

  return (
    <Stack tokens={{ childrenGap: 25 }}>
      <Dropdown
        label="Юридические лица"
        selectedKey={companyId}
        options={companiesForDropdown}
        onChange={(_, item) => changeFilterValue(item.key, 'companyId')}
      />
      <Dropdown
        label="Статус"
        selectedKey={status}
        options={reportStatuseForPrinting}
        onChange={(_, item) => changeFilterValue(item.key, 'status')}
      />
      <div>
        <Label>Дата создания</Label>
        <div className={g.dateInputs}>
          <DatePicker
            className={s.date}
            firstDayOfWeek={DayOfWeek.Monday}
            label="От"
            isRequired={false}
            allowTextInput={true}
            formatDate={(date) => {
              return !date
                ? null
                : getDateFromTimestamp(date, false, { delimiter: '.', withoutTime: true });
            }}
            strings={DayPickerStrings}
            value={!minCreationDate ? null : new Date(minCreationDate)}
            onSelectDate={(value) => changeFilterValue(value, 'minCreationDate')}
            maxDate={maxCreationDate}
          />
          <DatePicker
            className={s.date}
            firstDayOfWeek={DayOfWeek.Monday}
            label="До"
            minDate={minCreationDate}
            isRequired={false}
            allowTextInput={true}
            formatDate={(date) => {
              return !date
                ? null
                : getDateFromTimestamp(date, false, { delimiter: '.', withoutTime: true });
            }}
            strings={DayPickerStrings}
            value={!maxCreationDate ? null : new Date(maxCreationDate)}
            onSelectDate={(value) => changeFilterValue(value, 'maxCreationDate')}
          />
          {!!dateErrors.has('creationDate') && (
            <span className={s.error}>Неправильный диапазон</span>
          )}
        </div>
        <Label style={{ marginTop: 25 }}>Дата отправки</Label>
        <div className={g.dateInputs}>
          <DatePicker
            className={s.date}
            firstDayOfWeek={DayOfWeek.Monday}
            label="От"
            isRequired={false}
            allowTextInput={true}
            formatDate={(date) => {
              return !date
                ? null
                : getDateFromTimestamp(date, false, { delimiter: '.', withoutTime: true });
            }}
            strings={DayPickerStrings}
            value={!minSendingDate ? null : new Date(minSendingDate)}
            onSelectDate={(value) => changeFilterValue(value, 'minSendingDate')}
            maxDate={maxSendingDate}
          />
          <DatePicker
            className={s.date}
            firstDayOfWeek={DayOfWeek.Monday}
            label="До"
            minDate={minSendingDate}
            isRequired={false}
            allowTextInput={true}
            formatDate={(date) => {
              return !date
                ? null
                : getDateFromTimestamp(date, false, { delimiter: '.', withoutTime: true });
            }}
            strings={DayPickerStrings}
            value={!maxSendingDate ? null : new Date(maxSendingDate)}
            onSelectDate={(value) => changeFilterValue(value, 'maxSendingDate')}
          />
          {!!dateErrors.has('sendingDate') && (
            <span className={s.error}>Неправильный диапазон</span>
          )}
        </div>

        <Label style={{ marginTop: 25 }}>Дата производства</Label>
        <div className={g.dateInputs}>
          <DatePicker
            className={s.date}
            firstDayOfWeek={DayOfWeek.Monday}
            label="От"
            isRequired={false}
            allowTextInput={true}
            formatDate={(date) => {
              return !date
                ? null
                : getDateFromTimestamp(date, false, { delimiter: '.', withoutTime: true });
            }}
            strings={DayPickerStrings}
            value={!minProductCreationDate ? null : new Date(minProductCreationDate)}
            onSelectDate={(value) => changeFilterValue(value, 'minProductCreationDate')}
            maxDate={maxProductCreationDate}
          />
          <DatePicker
            className={s.date}
            firstDayOfWeek={DayOfWeek.Monday}
            label="До"
            minDate={minProductCreationDate}
            isRequired={false}
            allowTextInput={true}
            formatDate={(date) => {
              return !date
                ? null
                : getDateFromTimestamp(date, false, { delimiter: '.', withoutTime: true });
            }}
            strings={DayPickerStrings}
            value={!maxProductCreationDate ? null : new Date(maxProductCreationDate)}
            onSelectDate={(value) => changeFilterValue(value, 'maxProductCreationDate')}
          />
          {!!dateErrors.has('productCreationDate') && (
            <span className={s.error}>Неправильный диапазон</span>
          )}
        </div>

        <FilterButtons onSubmit={onConfirm} onDismiss={onDismiss} />
      </div>
    </Stack>
  );
};
