import React, { useState } from 'react';
import { Icon } from 'office-ui-fabric-react';
import plusIcon from '../../assets/img/AddAccent.svg';
import minusIcon from '../../assets/img/Minus.svg';
import s from './index.module.scss';

export const Accordion = (props) => {
  const { children, title = '' } = props;
  const [isShowedMainInfo, setIsShowedMainInfo] = useState(false);

  return (
    <div className={s.info}>
      <button
        className={s.infoHeader}
        style={{ background: '#F4F4F4' }}
        onClick={() => setIsShowedMainInfo((prev) => !prev)}
      >
        <span className={s.infoTitle}>{title}</span>
        {isShowedMainInfo ? (
          <img
            src={minusIcon}
            alt="minus icon"
            className={s.signIcons}
            style={{ width: '1.125rem' }}
          />
        ) : (
          <img
            src={plusIcon}
            alt="plus icon"
            className={s.signIcons}
            style={{ width: '1.125rem' }}
          />
        )}
      </button>
      <div
        style={{ background: '#F4F4F4' }}
        className={`${s.infoContent} ${isShowedMainInfo ? s.infoContentShowed : ''}`}
      >
        {children}
      </div>
    </div>
  );
};
