export const getGlobalErrorText = (state) => state.app.globalErrorText;
export const getIsFilters = (state) => state.app.isFilters;
export const getIsInitialized = (state) => state.app.initialized;
export const getPrevRoute = (state) => state.app.prevRoute;
export const getFilteredData = (state) => state.app.filteredData;
export const getUpdatedMinBalances = (state) => state.app.minBalanceUpdates;
export const getIsLowBalance = (state) => state.app.isLowBalance;
export const getChestZBalances = (state) => state.app.chZnakBalances;
export const getIsAppLoading = (state) => state.app.isLoading;
export const getProductChZnakBalance = (state) => state.app.productChZnakBalance;
export const getIsNewRelease = (state) => state.app.isNewRelease;
export const getShouldRetryWS = (state) => state.app.shouldRetry;
export const getReleaseVersion = (state) => state.app.releaseVersion;
export const getReleaseIntallStatus = (state) => state.app.releaseInstallStatus;
export const getInstalledRelVersion = (state) => state.app.installedReleaseVersion;
export const getInstRelVerInfo = (state) => state.app.installedRelVerInfo;
export const getPrintReports = (state) => state.app.printReports;
export const getIsStillSendingBalance = (state) => state.app.isStillSendingBalance;
export const getShouldCheckExpDateForPrintRep = (state) =>
  state.app.genSettings?.checkProductionAndExpirationDatesWhenSubmittingUtilisationReports;
export const getGeneralSettings = (state) => state.app.genSettings;

export const getGlobalSuccAlertBody = (state) => state.app.globalSuccessAlertBody;
export const getIsDbfromStore = (state) => state.app.isDb;
