import {Icon} from 'office-ui-fabric-react'
import React from 'react'
import s from './WaitingScreen.module.scss'
import g from '../../assets/scss/Main.module.scss'

export const WaitingScreen = ({title, text}) => {
    return (
        <div className={g.overlay}>
            <div className={s.waitingInfo}>
                <Icon iconName={'Sync'} className={s.icon}/>
                <span className={s.title}>{title}</span>
                <span className={s.text}>{text}</span>
            </div>
        </div>
    )
}
