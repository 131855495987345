import { Checkbox } from 'office-ui-fabric-react';
import React from 'react';
import clsx from 'clsx';
import g from '../../../assets/scss/Main.module.scss';
import { TableAction } from '../../../components/TableAction/TableAction';
import { moreInfoIcon } from '../../../constants';
import {
  cropText,
  getDateFromTimestamp,
  getStatusByEnum,
  isShipped,
  tableCellWidth,
  formatTwoPartDate
} from '../../../functions';
import s from '../../Products/Products.module.scss';
import { withdrInternConsupmTableHeader, isChkboxDisbabledEnt } from '../constants';
import { TableItemWithTooltip } from './TableItemWithTooltip';
import { useAlert } from '../../../hooks';
import { Alert } from '../../../components/Alert/Alert';
import info_button from '../../../assets/img/info_button.svg';
import st from '../Reports.module.scss';

// ввод в оборот

export const WithdrwIntrnlConsupmt = ({
  report,
  onSelectReport,
  selectedReports,
  rowStyle = st.tableCell
}) => {
  const tableWidth = tableCellWidth(withdrInternConsupmTableHeader);

  const { alertTitle, alertText, isAlertShowed, showAlert, hideAlert } = useAlert();
  const errorInfoHandler = () => {
    showAlert('Текст ошибки', report?.errorMessage);
  };
  return (
    <div className={g.tableItem}>
      {isAlertShowed && <Alert title={alertTitle} text={alertText} onClose={() => hideAlert()} />}
      <div className={g.tableRow} style={{ width: tableWidth(0) }}>
        <Checkbox
          onChange={() => onSelectReport(report)}
          disabled={
            report.status === 'SENDED' ||
            report.status === 'DISAGGREGATED' ||
            isChkboxDisbabledEnt(report.status)
          }
          checked={selectedReports.has(report.id)}
        />
      </div>
      <TableItemWithTooltip tooltipValue={report.suzId}>
        <div className={clsx(g.tableRow, rowStyle)} style={{ width: tableWidth(1) }}>
          {cropText(report.suzId, withdrInternConsupmTableHeader[1].maxLength)}
        </div>
      </TableItemWithTooltip>

      <TableItemWithTooltip tooltipValue={formatTwoPartDate(report.creationDate).toolTipPart}>
        <div className={clsx(g.tableRow, st.tableCell)} style={{ width: tableWidth(2) }}>
          {formatTwoPartDate(report.creationDate).mainPart}
        </div>
      </TableItemWithTooltip>

      <TableItemWithTooltip tooltipValue={formatTwoPartDate(report.sendingDate).toolTipPart}>
        <div className={clsx(g.tableRow, rowStyle)} style={{ width: tableWidth(3) }}>
          {formatTwoPartDate(report.sendingDate).mainPart}
        </div>
      </TableItemWithTooltip>

      <TableItemWithTooltip tooltipValue={formatTwoPartDate(report.actionDate).toolTipPart}>
        <div className={clsx(g.tableRow, st.tableCell)} style={{ width: tableWidth(4) }}>
          {formatTwoPartDate(report.actionDate).mainPart}
        </div>
      </TableItemWithTooltip>

      <div className={clsx(g.tableRow, g.tableRowFlex, rowStyle)} style={{ width: tableWidth(5) }}>
        <div>
          {report?.statusCorrectingDocument && report.statusCorrectingDocument !== 0
            ? getStatusByEnum(report.statusCorrectingDocument)
            : getStatusByEnum(report.status)}
        </div>
        {(report?.status === 'REJECTED' && report.errorMessage) ||
          (report?.statusCorrectingDocument === 'REJECTED' && report.errorMessage) ? (
          <div>
            <TableAction
              isButton
              onClick={errorInfoHandler}
              tooltipText={'Текст ошибки'}
              iconName={moreInfoIcon}
            />
          </div>
        ) : null}
      </div>
      {/* продукт */}
      <TableItemWithTooltip tooltipValue={report.productName}>
        <div className={clsx(g.tableRow, rowStyle)} style={{ width: tableWidth(6) }}>
          {withdrInternConsupmTableHeader[6]?.longerProduct
            ? report.productName
            : cropText(report.productName, 15)}
        </div>
      </TableItemWithTooltip>
      {/* продукт */}
      {/* <div className={clsx(g.tableRow, rowStyle)} style={{ width: tableWidth(7) }}>
        {report.productGtin}
      </div> */}

      <TableItemWithTooltip tooltipValue={report.productGtin}>
        <div className={clsx(g.tableRow, rowStyle)} style={{ width: tableWidth(7) }}>
          {cropText(report.productGtin, withdrInternConsupmTableHeader[7].maxLength)}
        </div>
      </TableItemWithTooltip>

      <div className={clsx(g.tableRow, rowStyle)} style={{ width: tableWidth(8) }}>
        {report.action}
      </div>
      <div className={clsx(g.tableRow, rowStyle)} style={{ width: tableWidth(9) }}>
        {report.numberMarkingCodes}
      </div>
      <div
        style={{ width: tableWidth(10) }}
        className={`${clsx(g.tableRow, rowStyle)} ${s.tableActionsItems}`}
      >
        <TableAction
          link={`/reports/withrdIntrnlConsmpt/${report.id}`}
          params={{
            reportId: report.id,
            type: 'withrdIntrnlConsmpt'
          }}
          icon={info_button}
        />
      </div>
    </div>
  );
};
