import React, {useContext, useState} from 'react'

const AppContext = React.createContext()

export const useAppContext = () => {
    return useContext(AppContext)
}

export const AppProvider = ({children}) => {
    const [isMenuDisabled, setIsMenuDisabled] = useState(false)

    return (
        <AppContext.Provider value={{isMenuDisabled, setIsMenuDisabled}}>
            {children}
        </AppContext.Provider>
    )
}
