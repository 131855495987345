import { call, put, takeLatest } from '@redux-saga/core/effects'
import consignmentsAPI from '../../api/consignmentsAPI'
import {GET_PRINTERS_ERROR, GET_PRINTERS_START, GET_PRINTERS_SUCCESS} from './PrintersReducer'


function* getPrinters() {
    try {
        const res = yield call(consignmentsAPI.getPrinters)
        if(res.statusCode) throw new Error(res);
        yield put({
            type: GET_PRINTERS_SUCCESS,
            payload: res
        })
        
        
    } catch (err) {
        yield put({
            type: GET_PRINTERS_ERROR,
            payload: err?.message
        })
    }
}


export function* printersSaga() {
    yield takeLatest(GET_PRINTERS_START, getPrinters)
}
