import { CODES_CONTROL_ACCESS, FULL_ACCESS } from '../../constants';

export const navLinkGroups = [
  {
    links: [
      {
        name: 'Продукты (КМ)',
        key: '/products',
        access: [FULL_ACCESS, CODES_CONTROL_ACCESS]
      },
      {
        name: 'Заказы КМ',
        key: '/orders',
        access: [FULL_ACCESS, CODES_CONTROL_ACCESS]
      },
      {
        name: 'Агрегаты (КИТУ)',
        key: '/aggregates',
        access: [FULL_ACCESS, CODES_CONTROL_ACCESS]
      },
      {
        name: 'Отчеты',
        key: '/reports',
        access: [FULL_ACCESS, CODES_CONTROL_ACCESS],
        links: [
          {
            name: 'Нанесение',
            key: '/reports/printing'
          },
          {
            name: 'Агрегация',
            key: '/reports/aggregation'
          },
          {
            name: 'Ввод в оборот',
            key: '/reports/entering'
          },
          {
            name: 'Отбраковка',
            key: '/reports/rejection'
          },
          {
            name: 'Наборы',
            key: '/reports/sets'
          },
          {
            name: 'Вывод из оборота',
            key: '/reports/withrdIntrnlConsmpt'
          },
          {
            name: 'Списание',
            key: '/reports/writeOff'
          }
        ],
        isExpanded: true
      },
      {
        name: 'Задания',
        key: '/consignments',
        access: [FULL_ACCESS]
      },
      {
        name: 'Линии',
        key: '/lines',
        access: [FULL_ACCESS]
      },
      // {
      //     name: 'Склады',
      //     key: '/storage',
      //     access: [FULL_ACCESS]
      // },
      {
        name: 'Пользователи',
        key: '/users',
        access: [FULL_ACCESS]
      },
      {
        name: 'Настройки',
        key: '/settings',
        access: [FULL_ACCESS],
        links: [
          {
            name: 'Юридические лица',
            key: '/legal'
          },
          {
            name: 'Владельцы продуктов',
            key: '/productowners'
          },
          {
            name: 'Настройки списания КМ',
            key: '/writeoffSettings'
          },
          {
            name: 'Общие настройки',
            key: '/mainSettings'
          },
          // {
          //     name: 'FTP',
          //     key: '/settings/ftp'
          // },
          {
            name: 'База данных',
            key: '/settings/database'
          },

          {
            name: 'Журнал запросов "Честный знак"',
            key: '/settings/logs'
          }
        ],
        isExpanded: false
      }
    ]
  }
];

export const navLinkGroupsNoAggregation = [
  {
    links: [
      {
        name: 'Продукты (КМ)',
        key: '/products',
        access: [FULL_ACCESS, CODES_CONTROL_ACCESS]
      },
      {
        name: 'Заказы КМ',
        key: '/orders',
        access: [FULL_ACCESS, CODES_CONTROL_ACCESS]
      },
      // {
      //   name: 'Агрегаты (КИТУ)',
      //   key: '/aggregates',
      //   access: [FULL_ACCESS, CODES_CONTROL_ACCESS]
      // },
      {
        name: 'Отчеты',
        key: '/reports',
        access: [FULL_ACCESS, CODES_CONTROL_ACCESS],
        links: [
          {
            name: 'Нанесение',
            key: '/reports/printing'
          },
          // {
          //   name: 'Агрегация',
          //   key: '/reports/aggregation'
          // },
          {
            name: 'Ввод в оборот',
            key: '/reports/entering'
          },
          {
            name: 'Отбраковка',
            key: '/reports/rejection'
          },
          {
            name: 'Наборы',
            key: '/reports/sets'
          }
        ],
        isExpanded: true
      },
      {
        name: 'Задания',
        key: '/consignments',
        access: [FULL_ACCESS]
      },
      {
        name: 'Линии',
        key: '/lines',
        access: [FULL_ACCESS]
      },
      // {
      //     name: 'Склады',
      //     key: '/storage',
      //     access: [FULL_ACCESS]
      // },
      {
        name: 'Пользователи',
        key: '/users',
        access: [FULL_ACCESS]
      },
      {
        name: 'Настройки',
        key: '/settings',
        access: [FULL_ACCESS],
        links: [
          {
            name: 'Юридические лица',
            key: '/legal'
          },
          {
            name: 'Владельцы продуктов',
            key: '/productowners'
          },
          {
            name: 'Настройки списания КМ',
            key: '/writeoffSettings'
          },
          {
            name: 'Общие настройки',
            key: '/mainSettings'
          },
          // {
          //     name: 'FTP',
          //     key: '/settings/ftp'
          // },
          {
            name: 'База данных',
            key: '/settings/database'
          },

          {
            name: 'Журнал запросов "Честный знак"',
            key: '/settings/logs'
          }
        ],
        isExpanded: false
      }
    ]
  }
];

export const pathWithState = [
  '/products',
  '/consignments',
  '/aggregates',
  '/reports/printing',
  '/reports/entering',
  '/reports/rejection',
  '/reports/aggregation',
  '/reports/sets',
  '/lines'
];
