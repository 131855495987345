import { useEffect } from 'react';
import { Field, useField } from 'formik';
import { productGroupRefObj } from './addSideProducts.constants';
import ComboSelect from 'components/Forms/customSelect/comboSelect';
import s from './Expiration.module.scss';

const ProductGroupField = () => {
  const [pgField, __, prGrHelpers] = useField('productGroup');
  const newProductGroups = productGroupRefObj.map((comp) => ({
    value: comp.key,
    label: comp.text
  }));
  const onChange = (arg) => prGrHelpers.setValue(arg.value);

  useEffect(() => {
    if (!pgField.value) {
      prGrHelpers.setValue(newProductGroups[0]?.key || '');
    }
  }, []);

  return (
    <section className={s.expiration}>
      <Field name={'productGroup'}>
        {(props) => {
          return (
            <ComboSelect
              options={newProductGroups}
              onChange={onChange}
              label="Товарная группа продукта"
              placeholder={
                newProductGroups.find((pg) => pg.value === pgField.value)?.label ||
                newProductGroups[0]?.label ||
                ''
              }
              isSearchable={false}
            />
          );
        }}
      </Field>
    </section>
  );
};

export default ProductGroupField;
