export const updateMinBalanceState = (state, payload)=> {    
    const findBalance = (balance)=> balance.title === payload.title && balance.productGroup === payload.productGroup  
    const isRepeatingMinBalanceChange = state.minBalanceUpdates.find(balance => findBalance(balance));    
    if(!!isRepeatingMinBalanceChange) {
        const newArray = state.minBalanceUpdates.map(balance => {
            if (findBalance(balance)) {
                balance.minBalance = payload.minBalance;             
            }
            return balance
        })
        return {...state, minBalanceUpdates: newArray}
    } else {        
        return {...state, minBalanceUpdates: [...state.minBalanceUpdates, payload]}
    }
}
