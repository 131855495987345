export const mapToArray = (map, field) => {
    const result = [];
    map?.forEach(item => {
        result.push(item[field])
    })
    return result
}

export const arrayToString = (array, symbol)  => {
    const result = []
    array?.forEach(item => result.push(item))
    return result?.join(symbol)
}
