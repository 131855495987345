import API from './APIService';

class ReleaseAPI {
  delayUpdate = () => API.post(`/api/v2/administrator/master-update/delay-install-update`);

  startUpdate = () => API.post(`/api/v2/administrator/master-update/start-installing-update`);

  confirmUILock = () => API.post('/api/v2/administrator/master-update/confirm-ui-lock');

  confirmFinishUpdate = () =>
    API.post('/api/v2/administrator/master-update/finish-installing-update');
}

export default new ReleaseAPI();
