import React, { memo, useState } from 'react';
import s from './version-info.module.scss';
import { getInstalledRelVersion } from 'redux/App/appSelectors';
import { useSelector } from 'react-redux';
import ConfirmWithComponent from 'components/Confirm/ConfirmWcomponent';
import VersionModal from './InfoModal';

const VersionInfo = () => {
  const [showModal, setShowModal] = useState(false);
  const version = useSelector(getInstalledRelVersion);
  const openModal = () => setShowModal(true);
  const closeModal = () => setShowModal(false);
  return (
    <div className={s.swVersionContaier}>
      <span className={s.version}>{`v. ${version || '1.2.0'}`}</span>
      <div className={s.infoLink} onClick={openModal}>
        Что нового?
      </div>
      {showModal && (
        <ConfirmWithComponent
          title={`Обновления в актуальной версии ПО`}
          onClose={closeModal}
          isLoading={false}
          disagreeText={'Понятно'}
          onDisagree={closeModal}
        >
          <VersionModal />
        </ConfirmWithComponent>
      )}
    </div>
  );
};

export default VersionInfo;
