import { Spinner } from 'office-ui-fabric-react';
import React from 'react';
import s from './Table.module.scss';
import p from '../../pages/Products/Products.module.scss';
import clsx from 'clsx';

const spinnerStyles = {
  circle: {
    height: 60,
    width: 60,
    borderWidth: 4
  }
};

export const Table = React.memo(
  ({
    headerItems,
    maxHeight,
    isFixedHeight = true,
    loading,
    children,
    isReport = false,
    customStyles = null,
    customHeaderClass = null
  }) => {
    const getLenghtOfTheRightChild = (arr) =>
      arr.filter((child) => child !== false)[0]?.length || 0;

    return (
      <div className={clsx(s.table, customStyles && customStyles)} style={{ maxHeight: maxHeight }}>
        <div className={clsx(s.tableHeader, customHeaderClass && customHeaderClass)}>
          {headerItems.map((headerItem, i) => {
            return (
              <div
                key={i}
                className={`${s.tableHeaderItem}`}
                style={{
                  width: `calc((100vw - 180px - 3.75rem) * ${headerItem.width})`,
                  ...headerItem.headerStyles
                }}
              >
                {headerItem.title}
              </div>
            );
          })}
        </div>
        <div
          className={s.tableContent}
          style={
            isFixedHeight
              ? { height: `calc(${maxHeight} - 3.75rem` }
              : { maxHeight: `calc(${maxHeight} - 3.75rem` }
          }
        >
          {!loading ? (
            <div>
              {(!children?.length && !isReport) ||
              (isReport && !getLenghtOfTheRightChild(children)) ? (
                <span className={s.searchNothing}>Список результатов пуст</span>
              ) : (
                children
              )}
            </div>
          ) : (
            <div className={s.loaderContainer} style={{ height: `calc(${maxHeight} - 3.75rem` }}>
              <Spinner styles={spinnerStyles} />
            </div>
          )}
        </div>
      </div>
    );
  }
);
// style={{ padding: "00.9375rem  8rem" }}

export const ProductsTable = React.memo(
  ({ headerItems, maxHeight, isFixedHeight = true, loading, children }) => {
    return (
      <div className={`${s.table}`} style={{ maxHeight: maxHeight }}>
        <div className={s.tableHeader}>
          <div className={p.firsthThreeRows}>
            {headerItems.map((headerItem, i) => {
              if (i < 3) {
                return (
                  <div
                    key={i}
                    className={clsx(`${p.tableHeaderItem}`, {
                      [p.firstRow]: i === 0,
                      [p.middleRows]: i === 1 || i === 2,
                      [p.middleTableHeaderItem]: i === 1 || i === 2
                    })}
                  >
                    {headerItem.title}
                  </div>
                );
              }
            })}
          </div>

          {headerItems.map((headerItem, i) => {
            if (i === 3) {
              return (
                <div key={i} className={clsx(`${p.lastTableHeaderItem} `, p.lastRow)}>
                  {headerItem.title}
                </div>
              );
            }
          })}
        </div>
        <div
          className={s.tableContent}
          style={
            isFixedHeight
              ? { height: `calc(${maxHeight} - 3.75rem` }
              : { maxHeight: `calc(${maxHeight} - 3.75rem` }
          }
        >
          {!loading ? (
            <div>
              {!children?.length ? (
                <span className={s.searchNothing}>Список результатов пуст</span>
              ) : (
                children
              )}
            </div>
          ) : (
            <div className={s.loaderContainer} style={{ height: `calc(${maxHeight} - 3.75rem` }}>
              <Spinner styles={spinnerStyles} />
            </div>
          )}
        </div>
      </div>
    );
  }
);
