import { useEffect, useRef, useState } from 'react';
import s from './index.module.scss';
import { TextField, PrimaryButton } from 'office-ui-fabric-react';
import { Loader } from 'components/Loader/Loader';
import productsAPI from 'api/productsAPI';
import { useLocation } from 'react-router-dom/cjs/react-router-dom.min';
import { useCrumbsContext } from 'context/CrumbsContext';
import { useAlert } from 'hooks';
import { Alert } from 'components/Alert/Alert';
import { getGlobalErrorText } from 'redux/App/appSelectors';
import { useSelector } from 'react-redux';

const daysInRuss = (days) => {
  switch (days) {
    case '1':
    case '21':
      return 'день';
    case '2':
    case '22':
    case '3':
    case '23':
    case '4':
    case '24':
      return 'дня';
    default:
      return 'дней';
  }
};

const WriteoffSettings = () => {
  const [days, setDays] = useState('5');
  const [error, setError] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const location = useLocation();
  const { setCrumbs } = useCrumbsContext();
  const globalErrorText = useSelector(getGlobalErrorText);
  const requesIsSent = useRef(null);

  const { alertTitle, alertText, isAlertShowed, showAlert, hideAlert } = useAlert();

  useEffect(() => {
    setCrumbs([
      location.pathname.slice(0, location.pathname.lastIndexOf('/')),
      'Настройки списания КМ'
    ]);
  }, []);

  const getCurrentDays = async () => {
    setIsLoading(true);
    const currentDays = await productsAPI.getDays();
    if (currentDays?.beforeShelfLife) {
      setDays(currentDays.beforeShelfLife);
    }
    setIsLoading(false);
  };

  useEffect(() => {
    getCurrentDays();

    return () => {
      hideAlert();
      requesIsSent.current = false;
      setDays('');
      setError('');
    };
  }, []);

  useEffect(() => {
    if (globalErrorText) {
      requesIsSent.current = false;
      hideAlert();
    }
  }, [globalErrorText]);

  const changeDays = (e) => {
    const value = e.target.value;
    if (!/[^0-9]/.test(value) && Number(value) >= 0 && Number(value) < 30) {
      setDays(value);
      setError('');
    }
    if (value.length === 2 && value[0] === '0') {
      setDays(value[1]);
      setError('');
    }
    if (value === '') {
      setDays(0);
      setError('');
    }
  };

  const validate = (val) => {
    if (val === '' || /[^0-9]/.test(val) || Number(val) < 0 || Number(val) > 29) {
      setError('Данное поле может принимать числовые значение от 0 до 29');
      return false;
    }
    return true;
  };

  const onSubmit = async () => {
    if (validate(days)) {
      setIsLoading(true);
      requesIsSent.current = true;
      const res = await productsAPI.sendDays(days);
      setIsLoading(false);
      showAlert(
        'Настройки списания КМ',
        `Натройки успешно сохранились. Коды маркировки будут списываться за ${days} ${daysInRuss(
          days.toString().trim()
        )} до истечения срока годности.`
      );
    }
  };

  return (
    <main className={s.mainWrapper}>
      {isLoading ? (
        <Loader />
      ) : (
        <section>
          {isAlertShowed && requesIsSent.current && (
            <Alert title={alertTitle} text={alertText} onClose={hideAlert} />
          )}
          <div className={s.fieldWrapper}>
            <p className={s.fieldDescrp}>{'Списывать коды маркировки за'}</p>
            <TextField
              value={days}
              type="text"
              onChange={changeDays}
              className={s.input}
              inputClassName={s.inputClassName}
            />
            <p className={s.fieldDescrp}>{'дней до истечения срока годности.'}</p>
          </div>
          <p className={s.error}>{error}</p>

          <section className={s.info}>
            <div className={s.infoParag}>
              Данная настройка позволяет настроить списание кодов маркировки в Hi mark за несколько
              дней до того, как коды будут списаны Честным знаком.
            </div>
            <div className={s.infoParag}>
              Честный знак ограничивает срок годности кодов маркировки. В зависимости от торговой
              группы это 30 суток и более. Если с момента заказа в течение этого срока по коду не
              будет отправлен отчёт о нанесении - он будет списан. При попытке отправить код
              маркировки в отчёт о нанесении после его списания - такой отчёт не будет принят
              Честным знаком.
            </div>

            <div className={s.infoParag}>
              Списание кодов в Hi mark за несколько дней, до списания Честным знаком позволяет
              избежать ошибок, которые могут возникнуть в случаях когда коды маркировки фактически
              нанесены на продукцию одной датой, а отправка отчёта о ненесении проводится на
              несколько дней позже.
            </div>

            <div className={s.infoParag}>
              Настоятельно не рекомендуем изменять данный параметр в меньшую сторону, если на вашем
              производстве фактическое нанесение кодов и передача отчетов о нанесении происходят не
              в один день.
            </div>
          </section>
          <PrimaryButton
            disabled={error}
            text="Сохранить"
            type="button"
            onClick={onSubmit}
            className={s.btn}
          />
        </section>
      )}
    </main>
  );
};

export default WriteoffSettings;
