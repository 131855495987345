import { useState, useCallback, memo } from 'react';
import productsAPI from '../../../api/productsAPI';
import clsx from 'clsx';
import sMain from '../Products.module.scss';
import s from './RemainingCodes.module.scss';
import RemainingQuantMarcCodes from './RemainingQuantiMarkCodes';
import { useEffect } from 'react';
import { sepThousands } from '../core';

const RemainingCodesWCallout = ({
  markingCodesRemainder,
  productId,
  isCallout,
  setCalledProduct,
  index,
  hideCallout
}) => {
  const [codesData, setCodesData] = useState(null);
  const [isLoading, setLoading] = useState(false);

  const handleShowCallout = useCallback(async () => {
    setCalledProduct(productId);
  }, [productId]);

  useEffect(() => {
    const getCodes = async () => {
      setLoading(true);
      const remMarkCodes = await productsAPI.getRemainingMarcCodes(productId);
      setCodesData(remMarkCodes);
      setLoading(false);
    };
    isCallout && getCodes();
  }, [productId, isCallout]);

  return (
    <span
      className={clsx(sMain.tableCell, sMain.middleRows, s.calloutWrapper)}
      onClick={handleShowCallout}
      id={productId}
    >
      {sepThousands(markingCodesRemainder)}
      {isCallout && (
        <>
          <section
            className={index > 2 ? clsx(s.informationBoxUP) : clsx(s.informationBoxBottom)}
            onClick={hideCallout}
          >
            {isLoading ? (
              <span className={s.loading}>Собираем данные...</span>
            ) : (
              <>{codesData && <RemainingQuantMarcCodes data={codesData} />}</>
            )}
          </section>
          {/* <div className={s.backDrop} onClick={hideCallout}/> */}
        </>
      )}
    </span>
  );
};

export default memo(RemainingCodesWCallout);
