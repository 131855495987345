import * as Yup from 'yup';

export const productGroupRefObj = [
  { key: 'lp', text: 'Предметы одежды, бельё постельное, столовое, туалетное и кухонное' },
  { key: 'shoes', text: 'Обувные товары' },
  { key: 'tobacco', text: 'Табачная продукция' },
  { key: 'perfumery', text: 'Духи и туалетная вода' },
  { key: 'tires', text: 'Шины и покрышки пневматические резиновые новые' },
  { key: 'electronics', text: 'Фотокамеры (кроме кинокамер), фотовспышки и лампы-вспышки' },
  { key: 'pharma', text: 'Лекарственные препараты для медицинского применения' },
  { key: 'milk', text: 'Молочная продукция' },
  { key: 'bicycle', text: 'Велосипеды и велосипедные рамы' },
  { key: 'wheelchairs', text: 'Медицинские изделия' },
  { key: 'otp', text: 'Альтернативная табачная продукция' },
  { key: 'water', text: 'Упакованная вода' },
  { key: 'furs', text: 'Товары из натурального меха' },
  { key: 'beer', text: 'Пиво, напитки, изготавливаемые на основе пива, слабоалкогольные напитки' },
  { key: 'ncp', text: 'Никотиносодержащая продукция' },
  { key: 'bio', text: 'Биологически активные добавки к пище' },
  { key: 'antiseptic', text: 'Антисептики и дезинфицирующие средства' },
  { key: 'seafood', text: 'Морепродукты' },
  { key: 'nabeer', text: 'Безалкогольное пиво' },
  { key: 'softdrinks', text: 'Соковая продукция и безалкогольные напитки' }
];

export const expirationDateTypeRefObj = [
  { key: 'Hour', text: 'Час' },
  { key: 'Day', text: 'День' },
  { key: 'Week', text: 'Неделя' },
  { key: 'Month', text: 'Месяц' },
  { key: 'Year', text: 'Год' }
];

export const NEW_SIDEPRODUCT_VALIDATION_SCHEMA = Yup.object().shape({
  Name: Yup.string()
    .required('Поле обязательно')
    .test(
      'length>=4',
      'Название продукта не должно быть короче четырех символов',
      (value) => value?.toString().length > 3
    )
    .typeError('Поле обязательно'),
  TnVed: Yup.string()
    .required('Поле обязательно')
    .test(
      'numbersOnly',
      'Формат ТН ВЭД должен быть 4-10 цифр',
      (value) =>
        !/[^0-9]/.test(value) && value?.toString().length > 3 && value?.toString().length < 11
    )
    .typeError('Поле обязательно'),
  Gtin: Yup.string()
    .required('Поле обязательно')
    .test(
      'numbersOnly',
      'Формат GTIN должен быть 14 цифр',
      (value) => !/[^0-9]/.test(value) && value?.toString().length === 14
    )
    .typeError('Поле обязательно'),
  expirationDate: Yup.number()
    .test(
      'min0',
      'Данное поле принимает только числовые значения больше 0',
      (value) => Number(value) > 0
    )
    .test(
      'numbersOnly',
      'Данное поле принимает только числовые значения больше 0',
      (value) => typeof value === 'number' && !/[^0-9]/.test(value)
    )
    .required('Поле обязательно')
    .typeError('Данное поле принимает только числовые значения больше 0')
});

export const initialValues = {
  Name: '',
  TnVed: '',
  Gtin: '',
  productGroup: productGroupRefObj[0].key,
  expirationDateType: expirationDateTypeRefObj[0].key,
  expirationDate: null,
  companyId: '',
  productOwnerId: ''
};
