import {Spinner, SpinnerSize} from 'office-ui-fabric-react'
import React from 'react'
import s from './MiniLoader.module.scss'

export const MiniLoader = () => {
    return (
        <div className={s.loaderContainer}>
            <Spinner size={SpinnerSize.medium}/>
        </div>
    )
}
