import { Nav } from 'office-ui-fabric-react/lib/Nav';
import React, { useEffect, useState, useCallback } from 'react';
import { useSelector } from 'react-redux';
import { useHistory, useLocation } from 'react-router-dom';
import { CODES_CONTROL_ACCESS, FULL_ACCESS } from '../../constants';
import { useAppContext } from '../../context/AppContext';
import { useCrumbsContext } from '../../context/CrumbsContext';
import { getAccessLevel } from '../../redux/Auth/authSelectors';
import VersionInfo from './VersionInfo/VersionInfo';
import settingsAPI from 'api/settingsAPI';
import { navLinkGroups, navLinkGroupsNoAggregation, pathWithState } from './navLinkGroups';
import { getIsDbfromStore } from 'redux/App/appSelectors';
import s from './Menu.module.scss';

export const Menu = () => {
  const [selectedKey, setSelectedKey] = useState('/products');
  const history = useHistory();
  const location = useLocation();
  const accessLevel = useSelector(getAccessLevel);
  const [filteredNav, setFilteredNav] = useState([]);
  const isAggregationIncluded = useSelector((state) => state.app.aggregationIncluded);
  const shouldShowDbPage = useSelector(getIsDbfromStore);

  useEffect(() => {
    if (accessLevel) {
      let _nav = isAggregationIncluded
        ? navLinkGroups[0].links.filter((item) => item.access.includes(accessLevel))
        : navLinkGroupsNoAggregation[0].links.filter((item) => item.access.includes(accessLevel));

      if (typeof shouldShowDbPage !== 'undefined' && !shouldShowDbPage) {
        console.log('inside the if');
        const settingsItem = _nav.find((item) => item.name === 'Настройки');
        if (settingsItem) {
          const newSettingsLinks = settingsItem.links.filter((link) => link.name !== 'База данных');
          settingsItem.links = newSettingsLinks;
        }
      }

      setFilteredNav([{ links: _nav }]);
    }
  }, [accessLevel, isAggregationIncluded, shouldShowDbPage]);

  const { crumbs } = useCrumbsContext();
  const { isMenuDisabled } = useAppContext();

  console.log('shouldShowDbPage', shouldShowDbPage);

  const onLinkClick = (e, item) => {
    if (!['/reports', '/settings'].includes(item.key)) {
      setSelectedKey(item.key);
      if (pathWithState.includes(`${item.key}`)) {
        history.push({ pathname: item.key, state: { prevPath: location.pathname } });
      } else {
        history.push(item.key);
      }
    }
  };

  useEffect(() => {
    let pathname = location.pathname;

    if (pathname) {
      setSelectedKey(pathname);
    } else {
      setSelectedKey('/products');
    }
  }, [location]);

  useEffect(() => {
    if (crumbs.length) {
      setSelectedKey(crumbs[0]);
    }
  }, [crumbs]);

  return (
    <div className={`${s.nav} ${isMenuDisabled && s.disabledNav}`}>
      <Nav onLinkClick={onLinkClick} selectedKey={selectedKey} groups={filteredNav} />
      <VersionInfo />
    </div>
  );
};
