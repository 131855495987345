import React from 'react'

export const Logo = () => {
    return (
        <svg style={{marginRight: 15}} xmlns="http://www.w3.org/2000/svg" width="92" height="20" viewBox="0 0 92 20"
             fill="none">
            <path
                d="M12.678 18.4046C11.3606 19.3781 9.57851 19.8649 7.33184 19.8649C5.08517 19.8649 3.30208 19.3781 1.98257 18.4046C0.665134 17.4332 0.00641396 15.9136 0.00641396 13.8457V6.2068C0.00529065 6.13641 0.0184513 6.06652 0.0450858 6.00145C0.0717203 5.93639 0.111265 5.87751 0.161285 5.82845C0.210782 5.77721 0.270074 5.73665 0.335564 5.70924C0.401054 5.68183 0.471378 5.66813 0.542269 5.66898H4.74239C4.81325 5.66831 4.88352 5.68209 4.94899 5.70949C5.01445 5.7369 5.07376 5.77736 5.12337 5.82845C5.17466 5.87685 5.21545 5.93546 5.24318 6.00059C5.2709 6.06571 5.28495 6.13592 5.28444 6.2068V14.124C5.2715 14.3617 5.31475 14.599 5.41062 14.8165C5.50648 15.034 5.65221 15.2253 5.83578 15.3747C6.27092 15.6876 6.79842 15.8419 7.33184 15.8125C7.86525 15.8419 8.39276 15.6876 8.8279 15.3747C9.01146 15.2253 9.15719 15.034 9.25305 14.8165C9.34892 14.599 9.39217 14.3617 9.37924 14.124V6.2068C9.37873 6.13592 9.39277 6.06571 9.4205 6.00059C9.44822 5.93546 9.48901 5.87685 9.5403 5.82845C9.58991 5.77736 9.64922 5.7369 9.71469 5.70949C9.78015 5.68209 9.85042 5.66831 9.92129 5.66898H14.0997C14.1706 5.66813 14.2409 5.68183 14.3064 5.70924C14.3719 5.73665 14.4312 5.77721 14.4807 5.82845C14.5318 5.87697 14.5725 5.93561 14.6003 6.0007C14.628 6.06579 14.6421 6.13595 14.6418 6.2068V13.8457C14.65 15.9136 13.9954 17.4332 12.678 18.4046Z"
                fill="#057BD6"/>
            <path
                d="M16.9988 5.66895H24.5039C26.4037 5.66895 27.908 6.13485 29.0169 7.06664C29.5495 7.49376 29.9771 8.03946 30.2662 8.6611C30.5553 9.28273 30.6981 9.96344 30.6833 10.65C30.6833 12.1092 30.2156 13.2672 29.2801 14.1239C28.3447 14.9807 26.7526 15.4091 24.5039 15.4091H21.8432V19.2989C21.8416 19.4435 21.784 19.5817 21.6827 19.684C21.5814 19.7863 21.4444 19.8444 21.3012 19.8461H16.9988C16.8556 19.8444 16.7186 19.7863 16.6173 19.684C16.516 19.5817 16.4584 19.4435 16.4568 19.2989V6.21614C16.4584 6.07152 16.516 5.93328 16.6173 5.83101C16.7186 5.72874 16.8556 5.67057 16.9988 5.66895ZM21.8742 9.42116V11.6475H24.4234C24.5644 11.6565 24.7056 11.6347 24.8376 11.5837C24.9695 11.5328 25.089 11.4537 25.188 11.352C25.287 11.2502 25.3632 11.1282 25.4114 10.9941C25.4596 10.8601 25.4786 10.7171 25.4672 10.575C25.4792 10.2719 25.3787 9.9753 25.1853 9.74322C25.0891 9.63756 24.9708 9.55472 24.8391 9.50065C24.7073 9.44659 24.5654 9.42265 24.4234 9.43054L21.8742 9.42116Z"
                fill="#057BD6"/>
            <path
                d="M37.925 10.0278H44.7641C44.9074 10.0295 45.0443 10.0876 45.1456 10.1899C45.2469 10.2922 45.3045 10.4304 45.3062 10.575V13.6331C45.3045 13.7777 45.2469 13.9159 45.1456 14.0182C45.0443 14.1205 44.9074 14.1786 44.7641 14.1803H33.0496C32.9064 14.1786 32.7694 14.1205 32.6681 14.0182C32.5668 13.9159 32.5092 13.7777 32.5076 13.6331V0.553543C32.5092 0.408922 32.5668 0.270683 32.6681 0.168415C32.7694 0.0661457 32.9064 0.00797276 33.0496 0.00634766H37.386C37.5293 0.00797276 37.6662 0.0661457 37.7675 0.168415C37.8688 0.270683 37.9265 0.408922 37.9281 0.553543L37.925 10.0278Z"
                fill="#057BD6"/>
            <path
                d="M60.3226 4.15566H56.6924V10.0279H60.3226C60.4643 10.0277 60.6006 10.083 60.7028 10.1821C60.805 10.2812 60.8652 10.4165 60.8708 10.5594V13.6175C60.8692 13.7621 60.8116 13.9003 60.7103 14.0026C60.609 14.1049 60.4721 14.163 60.3288 14.1647H47.6046C47.4613 14.163 47.3244 14.1049 47.223 14.0026C47.1217 13.9003 47.0641 13.7621 47.0625 13.6175V10.5594C47.0641 10.4148 47.1217 10.2766 47.223 10.1743C47.3244 10.072 47.4613 10.0138 47.6046 10.0122H51.2688V4.15566H47.6046C47.4613 4.15403 47.3244 4.09586 47.223 3.99359C47.1217 3.89132 47.0641 3.75308 47.0625 3.60846V0.553544C47.0641 0.408923 47.1217 0.270684 47.223 0.168415C47.3244 0.0661458 47.4613 0.00797277 47.6046 0.00634766H60.3257C60.4695 0.00715857 60.6072 0.0649713 60.7092 0.167327C60.8112 0.269683 60.8692 0.408386 60.8708 0.553544V3.61159C60.8692 3.75621 60.8116 3.89445 60.7103 3.99672C60.609 4.09899 60.4721 4.15716 60.3288 4.15878L60.3226 4.15566Z"
                fill="#057BD6"/>
            <path
                d="M72.1579 0.00292969H75.8748C76.0181 0.0045548 76.155 0.0627279 76.2563 0.164997C76.3576 0.267266 76.4152 0.405505 76.4169 0.550126V13.6328C76.4152 13.7774 76.3576 13.9157 76.2563 14.0179C76.155 14.1202 76.0181 14.1784 75.8748 14.18H72.6597C72.5018 14.1802 72.3463 14.1416 72.2065 14.0675C72.0667 13.9934 71.9469 13.8861 71.8574 13.7548L67.4839 8.12646V13.6359C67.4823 13.7806 67.4246 13.9188 67.3233 14.0211C67.222 14.1233 67.0851 14.1815 66.9418 14.1831H63.2249C63.0817 14.1815 62.9447 14.1233 62.8434 14.0211C62.7421 13.9188 62.6845 13.7806 62.6829 13.6359V0.553254C62.6845 0.408633 62.7421 0.270394 62.8434 0.168125C62.9447 0.0658554 63.0817 0.00768231 63.2249 0.00605719H66.4462C66.6 0.0030677 66.752 0.0386405 66.8888 0.109585C67.0256 0.180529 67.1428 0.284627 67.2299 0.412547L71.6158 6.49425V0.553254C71.6175 0.408633 71.6751 0.270394 71.7764 0.168125C71.8777 0.0658554 72.0146 0.00768231 72.1579 0.00605719V0.00292969Z"
                fill="#057BD6"/>
            <path
                d="M83.4512 10.2311H91.458C91.6013 10.2327 91.7382 10.2909 91.8395 10.3931C91.9409 10.4954 91.9985 10.6337 92.0001 10.7783V13.6331C91.9985 13.7777 91.9409 13.9159 91.8395 14.0182C91.7382 14.1205 91.6013 14.1786 91.458 14.1803H78.7772C78.6339 14.1786 78.497 14.1205 78.3957 14.0182C78.2943 13.9159 78.2367 13.7777 78.2351 13.6331V0.553543C78.2367 0.408922 78.2943 0.270683 78.3957 0.168415C78.497 0.0661457 78.6339 0.00797276 78.7772 0.00634766H91.2567C91.4 0.00797276 91.5369 0.0661457 91.6382 0.168415C91.7395 0.270683 91.7972 0.408922 91.7988 0.553543V3.40834C91.7972 3.55296 91.7395 3.6912 91.6382 3.79347C91.5369 3.89574 91.4 3.95391 91.2567 3.95554H83.4512V5.23128H90.1509C90.2942 5.23291 90.4311 5.29108 90.5324 5.39334C90.6337 5.49561 90.6914 5.63386 90.693 5.77848V8.41127C90.6914 8.55589 90.6337 8.69413 90.5324 8.7964C90.4311 8.89867 90.2942 8.95684 90.1509 8.95846H83.4512V10.2311Z"
                fill="#057BD6"/>
            <path
                d="M91.9999 16.55V19.3322C91.9975 19.4771 91.9395 19.6153 91.8383 19.7174C91.7371 19.8196 91.6006 19.8776 91.4579 19.8792H33.0621C32.9193 19.8776 32.7829 19.8196 32.6816 19.7174C32.5804 19.6153 32.5224 19.4771 32.52 19.3322V16.5594C32.5216 16.414 32.5793 16.275 32.6806 16.1722C32.7819 16.0694 32.9188 16.0109 33.0621 16.0093H91.4579C91.6011 16.0109 91.7381 16.0694 91.8394 16.1722C91.9407 16.275 91.9983 16.414 91.9999 16.5594V16.55Z"
                fill="#057BD6"/>
            <path
                d="M30.708 0.55646V3.32609C30.7056 3.47094 30.6476 3.60913 30.5464 3.71128C30.4452 3.81343 30.3087 3.87149 30.166 3.8731H0.548354C0.475367 3.87421 0.403003 3.85933 0.336189 3.8295C0.269376 3.79966 0.209683 3.75556 0.161176 3.7002C0.109498 3.65117 0.0684771 3.59173 0.0407414 3.52569C0.0130057 3.45964 -0.000833821 3.38845 0.000109279 3.31666V0.55646C-0.00137013 0.483202 0.0121856 0.410433 0.0399147 0.34278C0.0676437 0.275127 0.108939 0.214071 0.161176 0.163493C0.210296 0.111259 0.269448 0.0698015 0.334976 0.0416842C0.400505 0.0135669 0.471018 -0.000611672 0.542159 2.02289e-05H30.166C30.3092 0.00165412 30.4462 0.0601392 30.5475 0.162961C30.6488 0.265783 30.7064 0.404771 30.708 0.550174V0.55646Z"
                fill="#057BD6"/>
        </svg>
    )
}
