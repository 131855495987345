import { TextField, Dropdown, DatePicker, DayOfWeek, DefaultButton } from 'office-ui-fabric-react';
import moment from 'moment';
import { DayPickerStrings } from '../../../constants';
import { initialErrorState, certificateTypeDropdown } from './ProductsCard';
import g from '../../../assets/scss/Main.module.scss';
import s from './Permits.module.scss';

const Permits = ({
  formErrors,
  setFormErrors,
  certificateField,
  setCertificateFields,
  findKey
}) => {
  const cleanAllFields = () => {
    setCertificateFields({
      certificateType: findKey(certificateTypeDropdown[0]?.text),
      certificateNumber: '',
      certificateDate: '',
      wellNumber: null
    });
    setFormErrors(initialErrorState);
  };
  const onChangeSertDocument = (name) => (e, item) => {
    if (name === 'certificateDate') {
      if (e === null) {
        setCertificateFields((p) => ({
          ...p,
          certificateDate: ''
        }));
      } else {
        setCertificateFields((p) => ({
          ...p,
          certificateDate: e
        }));
      }
      setFormErrors((p) => ({ ...p, certificateDate: '' }));
    }
    if (name === 'certificateType') {
      setCertificateFields((p) => ({
        ...p,
        certificateType: item.key
      }));
      setFormErrors((p) => ({ ...p, certificateType: '' }));
    }
    if (name === 'certificateNumber') {
      setCertificateFields((p) => ({
        ...p,
        certificateNumber: e.target.value
      }));
      setFormErrors((p) => ({ ...p, certificateNumber: '' }));
    }
    if (name === 'wellNumber') {
      setCertificateFields((p) => ({
        ...p,
        wellNumber: e.target.value
      }));
      setFormErrors((p) => ({ ...p, wellNumber: '' }));
    }
  };

  return (
    <main className={s.permitsWrapper}>
      <h1 className={g.title}>Разрешительная документация</h1>
      <TextField
        label="Номер скважины (если больше одного номера, введите номера через запятую)"
        value={certificateField.wellNumber}
        name={'wellNumber'}
        type={'string'}
        onChange={onChangeSertDocument('wellNumber')}
        errorMessage={formErrors.wellNumber}
      />

      <Dropdown
        // style={{ marginTop: 25 }}
        label={'Тип разрешительной документации'}
        selectedKey={certificateField?.certificateType}
        options={certificateTypeDropdown}
        onChange={onChangeSertDocument('certificateType')}
        errorMessage={formErrors.certificateType}
      />
      <div className={s.infoRow}>
        <TextField
          required
          label="Номер документа"
          value={certificateField?.certificateNumber}
          name={'certificateNumber'}
          type={'string'}
          onChange={onChangeSertDocument('certificateNumber')}
          errorMessage={formErrors.certificateNumber}
        />
      </div>
      <div className={s.datePickerWrap}>
        {typeof certificateField?.certificateDate !== undefined && (
          <DatePicker
            placeholder="Выберете дату"
            className={s.date}
            firstDayOfWeek={DayOfWeek.Monday}
            label={'Дата документа'}
            allowTextInput={true}
            formatDate={(date) => {
              return !date ? null : moment(date).format('DD-MM-YYYY');
            }}
            strings={DayPickerStrings}
            defaultValue={'Выберете дату документа'}
            value={certificateField.certificateDate}
            onSelectDate={onChangeSertDocument('certificateDate')}
          />
        )}
        {!!formErrors.certificateDate && (
          <span className={s.errorMsg}>{formErrors.certificateDate}</span>
        )}
      </div>
      <DefaultButton
        className={s.btn}
        text={'Очистить поля разрешительной документации'}
        onClick={cleanAllFields}
      />
    </main>
  );
};

export default Permits;
