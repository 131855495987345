import React, { useEffect, useState } from 'react';
import { tableCellWidth } from 'functions';
import { Table } from 'components/Table/Table';
import MinBalanceInput from './customInput';
import { Pagination } from 'components/Pagination/Pagination';
import { usePaginationAndSearch, useDataFilter } from 'hooks';
import clsx from 'clsx';
import g from 'assets/scss/Main.module.scss';
import s from './balance.module.scss';
import { useDispatch } from 'react-redux';
import { setLowBalance } from 'redux/App/appReducer';

const headerFontStyle = {
  fontFamily: 'Segoe UI',
  fontStyle: 'normal',
  fontWeight: '600',
  fontSize: '1.1rem',
  lineHeight: '1.2rem',
  color: '#333333',
  justifyContent: 'flex-start'
};

const tableHeader = [
  {
    title: 'Товарная группа',
    width: '0.5',
    headerStyles: headerFontStyle
  },
  {
    title: 'Баланс, руб',
    width: '0.2',
    headerStyles: headerFontStyle
  },
  {
    title: 'Уведомлять при остатке',
    width: '0.3',
    headerStyles: { ...headerFontStyle, justifyContent: 'flex-end' }
  }
];

const BalanceDetails = ({ data, getBalance }) => {
  const [loading, setLoading] = useState(false);
  const balanceCellWidth = tableCellWidth(tableHeader);
  const [query, setQuery] = useDataFilter('balance');
  const dispatch = useDispatch();

  const isBalanceTooLow = (currentBalance, borderLineBalance) => {
    const lowBalance = currentBalance <= borderLineBalance;
    return lowBalance;
  };

  const getData = () => {
    setLoading(true);
    getBalance();
    setLoading(false);
    return data;
  };
  const { onPageChanged, onSearchTermChanged, totalPages, setTotalItems, currentPage } =
    usePaginationAndSearch({ callback: getData, query, setQuery });

  return (
    <main className={s.mainContainer}>
      <header className={s.titleWrapper}>
        <h1 className={s.balanceTitle}>{data.companyName}</h1>
      </header>
      <Table
        headerItems={tableHeader}
        maxHeight="auto"
        loading={loading}
        customStyles={s.additionalTableProps}
        customHeaderClass={s.additionalHeaderProps}
      >
        {data.items.map((row) => {
          return (
            <div
              key={row.productGroup}
              className={clsx(
                g.tableItem,
                isBalanceTooLow(row.currentBalance, row.minimumBalance) && s.lowBalance
              )}
            >
              <div
                className={clsx(g.tableCell, s.balanceTableColumns)}
                style={{ width: balanceCellWidth(0) }}
              >
                {row.productGroupName}
              </div>
              <div
                className={clsx(g.tableCell, s.balanceTableColumns)}
                style={{ width: balanceCellWidth(1) }}
              >
                {row.currentBalance}
              </div>
              <div
                className={clsx(g.tableCell, s.minBalanceColumn)}
                style={{ width: balanceCellWidth(2) }}
              >
                <MinBalanceInput
                  companyId={data.companyId}
                  productGroup={row.productGroup}
                  initialVal={row.minimumBalance}
                  additonalStyles={
                    isBalanceTooLow(row.currentBalance, row.minimumBalance) && s.lowBalance
                  }
                />
              </div>
            </div>
          );
        })}
      </Table>
      <div className={g.footer}>
        {totalPages > 1 && (
          <Pagination
            pageCount={totalPages}
            onPageChange={onPageChanged}
            selectedPage={currentPage}
          />
        )}
      </div>
    </main>
  );
};

export default BalanceDetails;
