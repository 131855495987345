import { serializeQueryObj } from '../functions';
import API from './APIService';

class ReportsAPI {
  getUtilisationReports = (query) => API.get(`/api/Utilisation/Get/?${serializeQueryObj(query)}`);
  getAggregationReports = (query) => API.get(`/api/Aggregation/Get/?${serializeQueryObj(query)}`);
  getRolloutReports = (query) => API.get(`/api/Rollout/Get/?${serializeQueryObj(query)}`);
  getRejectReports = (query) => API.get(`/api/Dropout/Get/?${serializeQueryObj(query)}`);

  getUtilisationReportById = (query) =>
    API.get(`/api/Utilisation/MarkingCodes/?${serializeQueryObj(query)}`);
  getAggregationReportById = (query) =>
    API.get(`/api/Aggregation/MarkingCodes/?${serializeQueryObj(query)}`);
  getRolloutReportById = (query) =>
    API.get(`/api/Rollout/MarkingCodes/?${serializeQueryObj(query)}`);
  getRejectReportById = (query) =>
    API.get(`/api/Dropout/MarkingCodes/?${serializeQueryObj(query)}`);
  sendUtilisationReports = (reports) =>
    API.post('/api/Utilisation/SendMany/', { idArray: reports });
  sendAggregationReports = (reports) =>
    API.post('/api/Aggregation/SendMany/', { idArray: reports });
  sendRolloutReports = (reports) => API.post('/api/Rollout/SendMany/', { idArray: reports });
  sendRejectReports = (reports) => API.post('/api/Dropout/SendMany/', { idArray: reports });

  sendInternalWithdrwlReports = (reports) =>
    API.post('/api/lk-receipt-document/send-many', { idArray: reports });

  sendInternalWriteOffReports = (reports) =>
    API.post('/api/write-off-document/send-many', { idArray: reports });

  getSetsReport = (query) => API.get(`/api/set-report/list/?${serializeQueryObj(query)}`);

  getInternalWithdrowalReport = (query) =>
    API.get(`/api/lk-receipt-document/list?${serializeQueryObj(query)}`);

  getInternalWithdrowalReportById = (query) =>
    API.get(`/api/lk-receipt-document/marking-codes/?${serializeQueryObj(query)}`);

  getSetsReportById = (query) =>
    API.get(`/api/set-report/marking-codes/?${serializeQueryObj(query)}`);

  getInternalWriteOffReport = (query) =>
    API.get(`/api/write-off-document/list?${serializeQueryObj(query)}`)

  getInternalWriteOffReportById = (query) =>
    API.get(`/api/write-off-document/marking-codes?${serializeQueryObj(query)}`)

  sendSetsReports = (reports) => API.post('/api/set-report/send-many/', { idArray: reports });

  senPrinRepsParams = (parameters) =>
    API.post(`/api/Utilisation/send-many-with-parameters`, { parameters });

  sendCorrections = (
    reprtId,
    correctVsd,
    correctProductionDate,
    correctExpirationDate,
    correctVariableExpiration,
    correctWeight,
    correctTnVed,
    correctPermitDocs,
    correctLicenses,
    correctEmissionType
  ) =>
    API.post(
      `/api/v2/administrator/correcting-information-about-codes/create-for-rollout-report?reportId=${reprtId}&correctVsd=${correctVsd}&correctProductionDate=${correctProductionDate}&correctExpirationDate=${correctExpirationDate}&correctVariableExpiration=${correctVariableExpiration}&correctWeight=${correctWeight}&correctTnVed=${correctTnVed}&correctPermitDocs=${correctPermitDocs}&correctLicenses=${correctLicenses}&correctEmissionType=${correctEmissionType}`
    );
}

export default new ReportsAPI();
