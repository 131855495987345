import React from 'react';
import s from './version-info.module.scss';
import { useSelector } from 'react-redux';
import { getInstRelVerInfo } from 'redux/App/appSelectors';

const VersionModal = () => {
  const info = useSelector(getInstRelVerInfo);
  // const infoText = `- Пункт описания реализованной фичи номер один, текст текст текст текст текст текст текст текст текст текст текст текст текст текст текст текст текст текст текст текст \r\n- Пункт два\r\n- Пункт три текст текст текст текст текст текст текст текст текст`;
  // const info = infoText
  //   .split('\r\n')
  //   .map((str) => str.trim())
  //   .filter((str) => !!str);

  return (
    <main className={s.modalWrapper}>
      {/* <span className={s.title}>{`Обновления в актуальной версии ПО на ${version}`}</span> */}
      <div className={s.modalBody}>
        <div className={s.infoList}>{info?.length ? <InfoList versionInfo={info} /> : ''}</div>
      </div>
    </main>
  );
};

const InfoList = ({ versionInfo }) => {
  const list = versionInfo.map((row) => {
    const newRow = row.replace('- ', '');
    return newRow.trim();
  });

  return (
    <>
      {list.map((row, ind) => (
        <div className={s.row}>
          <span>{`${ind + 1}.`}</span>
          <span>{` ${row}`}</span>
        </div>
      ))}
    </>
  );
};

export default VersionModal;
