export const SET_LOGGED = 'AUTH/SET_LOGGED'
export const SET_ERROR = 'AUTH/SET_ERROR'
export const SET_LOADING = 'AUTH/SET_LOADING'
export const SET_TOKEN = 'AUTH/SET_TOKEN'
export const LOGIN = 'AUTH/LOGIN'
export const LOGOUT = 'AUTH/LOGOUT'
export const CHECK_LOGGED = 'AUTH/CHECK_LOGGED'
export const SET_FIRSTNAME = 'AUTH/SET_FIRSTNAME'
export const SET_SURNAME = 'AUTH/SET_SURNAME'
export const SET_ACCESS_LEVEL = 'AUTH/SET_ACCESS_LEVEL'

const initialState = {
    logged: false,
    errorText: '',
    loading: false,
    token: null,
    firstName: '',
    surName: '',
    accessLevel: null
}

export const authReducer = (state = initialState, action) => {
    switch (action.type) {
        case SET_LOGGED:
            return {
                ...state,
                logged: action.isLogged,
            }
        case SET_ERROR:
            return {
                ...state,
                errorText: action.errorText,
            }
        case SET_LOADING:
            return {
                ...state,
                loading: action.isLoading,
            }
        case SET_TOKEN:
            return {
                ...state,
                token: action.token,
            }
        case SET_FIRSTNAME:
            return {
                ...state,
                firstName: action.firstName,
            }
        case SET_SURNAME:
            return {
                ...state,
                surName: action.surName,
            }
        case SET_ACCESS_LEVEL:
            return {
                ...state,
                accessLevel: action.accessLevel,
            }
        default:
            return state
    }
}

export const setLoggedAC = (isLogged) => ({type: SET_LOGGED, isLogged})
export const setErrorAC = (errorText) => ({type: SET_ERROR, errorText})
export const setLoadingAC = (isLoading) => ({type: SET_LOADING, isLoading})
export const setAccessLevel = (accessLevel) => ({type: SET_ACCESS_LEVEL, accessLevel})
export const setFirstName = (firstName) => ({type: SET_FIRSTNAME, firstName})
export const setSurName = (surName) => ({type: SET_SURNAME, surName})
export const loginAC = (payload) => ({type: LOGIN, payload})
export const logoutAC = () => ({type: LOGOUT})
export const checkLoggedAC = () => ({type: CHECK_LOGGED})
