import * as Yup from 'yup';

export const COMPANY_VALIDATION_SCHEMA = Yup.object().shape({
  name: Yup.string().required('Поле обязательно').typeError('Поле обязательно'),
  glnPrefix: Yup.string().required('Поле обязательно').typeError('Поле обязательно'),
  legalAddress: Yup.string().required('Поле обязательно').typeError('Поле обязательно'),
  manufacturer: Yup.string().required('Поле обязательно').typeError('Поле обязательно'),
  manufacturerAddress: Yup.string().required('Поле обязательно').typeError('Поле обязательно')

  // omsId: Yup.string().required('Поле обязательно').typeError('Поле обязательно'),
  // clientToken: Yup.string().typeError('Поле обязательно'),
  // participantInn: Yup.string().required('Поле обязательно').typeError('Поле обязательно'),
  // ownerInn: Yup.string().required('Поле обязательно').typeError('Поле обязательно'),
  // producerInn: Yup.string().required('Поле обязательно').typeError('Поле обязательно'),
  // thumbPrint: Yup.string().required('Поле обязательно').typeError('Поле обязательно')
  // apiKey: Yup.string().typeError('Поле обязательно')
});

export const NEW_COMPANY_VALIDATION_SCHEMA = Yup.object().shape({
  name: Yup.string().required('Поле обязательно').typeError('Поле обязательно')
});
