import { useState, memo } from 'react';
import clsx from 'clsx';
import s from './balance.module.scss';
import { useDispatch, useSelector } from 'react-redux';
import { getUpdatedMinBalances } from 'redux/App/appSelectors';
import { setMinBalance, setLoading } from 'redux/App/appReducer';
import { useEffect } from 'react';
import { seperateeThousands } from 'functions';

const replaceDot = (str) => {
  if (typeof str === 'string' && str.includes('.')) {
    return str.replace('.', ',');
  }

  return str;
};

const MinBalanceInput = ({ companyId, productGroup, initialVal, additonalStyles = null }) => {
  const updatedBalnces = useSelector(getUpdatedMinBalances);
  const [minimumBalance, setBalance] = useState(replaceDot(initialVal.toString()));

  useEffect(() => {
    if (typeof minimumBalance === 'string') setMinBalance(replaceDot(minimumBalance));
  }, [minimumBalance]);

  const onChange = ({ target }) => {
    const { value } = target;

    if (value === '' || /^[0-9]+,?[0-9]{0,2}?$/.test(replaceDot(value))) {
      setBalance(replaceDot(value));
    }
  };
  const dispatch = useDispatch();

  // useEffect(() => {
  //   if (updatedBalnces.length === 0) setBalance(initialVal);
  // }, [updatedBalnces.length, initialVal]);

  const updateMinBalance = () => {
    dispatch(setLoading(true));
    let minBalance;
    if (typeof minimumBalance !== 'undefined' && minimumBalance.toString()?.length > 0) {
      if (typeof minimumBalance === 'string' && minimumBalance.toString().slice(-1) === ',') {
        minBalance = minimumBalance(9, -1);
      } else {
        minBalance = minimumBalance;
      }
      if (typeof minimumBalance === 'string' && minimumBalance.includes(',')) {
        minBalance = minimumBalance.toString().replace(',', '.');
      }

      const unpadateddMinBalance = {
        companyId,
        productGroup,
        minBalance
      };
      dispatch(setMinBalance(unpadateddMinBalance));
      dispatch(setLoading(false));
    } else {
      setBalance(replaceDot(initialVal.toString()));
    }
  };

  return (
    <input
      className={clsx(s.minBalanceInput, additonalStyles && additonalStyles)}
      type="text"
      value={typeof minimumBalance === 'undefined' ? '' : minimumBalance.toString()}
      onChange={onChange}
      onBlur={updateMinBalance}
    />
  );
};

export default memo(MinBalanceInput);
