import { Dropdown, Label, Stack, TextField } from 'office-ui-fabric-react';
import React, { useEffect, useState } from 'react';
import ProductsAPI from '../../../api/productsAPI';
import LegalApi from '../../../api/legalAPI';
import { mapItemsForDropdown } from '../../../functions';
import { FilterButtons } from '../FilterButtons/FilterButtons';
import { setFilteredData } from 'redux/App/appReducer'
import s from './ProductsFilter.module.scss';
import { useDispatch } from 'react-redux';

export const ProductsFilter = ({ query, setQuery, setIsOpenFilters }) => {
  const [brandsForDropdown, setBrandsForDropdown] = useState([]);
  const [categoriesForDropdown, setCategoriesForDropdown] = useState([]);
  const [subCategoriesForDropdown, setSubCategoriesForDropdown] = useState([]);

  const [_brand, _setBrand] = useState(query.brand);
  const [_category, _setCategory] = useState(query.category);
  const [_subCategory, _setSubCategory] = useState(query.subCategory);
  const [_minMC, _setMinMC] = useState(query.minRemainderOfMarkingCodes);
  const [_maxMC, _setMaxMC] = useState(query.maxRemainderOfMarkingCodes);
  const [MCError, setMCError] = useState(false);
  const [companyId, setCompanyId] = useState(query.companyId);
  const [companiesForDropdown, setCompaniesForDropdown] = useState([]);
  const dispatch = useDispatch()

  useEffect(() => {
    ProductsAPI.getAllBrands().then((res) => setBrandsForDropdown(mapItemsForDropdown(res.list)));
    ProductsAPI.getCategories().then((res) => {
      setCategoriesForDropdown(mapItemsForDropdown(res.list));
    });
    LegalApi.getCompanies().then((res) => setCompaniesForDropdown(mapItemsForDropdown(res)));
  }, []);

  useEffect(() => {
    if (_category) {
      ProductsAPI.getSubCategories(_category).then((res) =>
        setSubCategoriesForDropdown(mapItemsForDropdown(res.list))
      );
    }
  }, [_category]);

  const onConfirm = () => {

    const filterObj = {
      brand: _brand,
      category: _category,
      subCategory: _subCategory,
      minRemainderOfMarkingCodes: _minMC,
      maxRemainderOfMarkingCodes: _maxMC,
      companyId
    };

    if (_minMC || _maxMC) {
      if (_maxMC >= _minMC) {
        setMCError(false);
        setIsOpenFilters(false);
        setQuery(
          (prev) => ({
            ...prev,
            ...filterObj
          }),
          'replace'
        );
      } else {
        setMCError(true);
      }
    } else {
      setMCError(false);
      setIsOpenFilters(false);
      setQuery(
        (prev) => ({
          ...prev,
          ...filterObj
        }),
        'replace'
      );
    }

    dispatch(setFilteredData(filterObj));
  };

  const changeFilterValue = (value, fieldName) => {
    switch (fieldName) {
      case 'category':
        _setCategory(value);
        break;
      case 'subCategory':
        _setSubCategory(value);
        break;
      case 'minRemainderOfMarkingCodes':
        _setMinMC(value);
        break;
      case 'maxRemainderOfMarkingCodes':
        _setMaxMC(value);
        break;
      case 'companyId':
        setCompanyId(value);
        break;
      default:
        _setBrand(value);
    }
  };

  const onDismiss = () => {
    _setBrand(null);
    _setCategory(null);
    _setSubCategory(null);
    _setMinMC(null);
    _setMaxMC(null);
    setCompanyId(null);

    const filterObj = {
      brand: undefined,
      category: undefined,
      subCategory: undefined,
      minRemainderOfMarkingCodes: undefined,
      maxRemainderOfMarkingCodes: undefined,
      companyId: undefined
    }

    setQuery(
      (prev) => ({
        ...prev,
        ...filterObj
      }),
      'replace'
    );

    dispatch(setFilteredData(filterObj));
    setIsOpenFilters(false);
    setMCError(false);
  };

  return (
    <Stack tokens={{ childrenGap: 25 }}>
      <Dropdown
        label="Юридические лица"
        selectedKey={companyId}
        options={companiesForDropdown}
        onChange={(_, item) => changeFilterValue(item.key, 'companyId')}
      />
      {brandsForDropdown && (
        <Dropdown
          label="Бренд"
          selectedKey={_brand}
          options={brandsForDropdown}
          onChange={(_, item) => changeFilterValue(item.key, 'brand')}
        />
      )}
      <Dropdown
        label="Категория"
        selectedKey={_category}
        options={categoriesForDropdown}
        onChange={(_, item) => changeFilterValue(item.key, 'category')}
      />
      <Dropdown
        label="Подкатегория"
        selectedKey={_subCategory}
        options={subCategoriesForDropdown}
        disabled={!_category}
        onChange={(_, item) => changeFilterValue(item.key, 'subCategory')}
      />
      <div className={s.remains}>
        <Label>Остаток КМ (тыс. шт)</Label>
        <div className={s.remainsInputs}>
          <TextField
            className={s.remainsInput}
            value={_minMC === 0 ? 0 : _minMC || ''}
            onWheel={(e) => e.target.blur()}
            type="number"
            onChange={(e) => changeFilterValue(e.target.value, 'minRemainderOfMarkingCodes')}
          />
          <span className={s.line}>—</span>
          <TextField
            className={s.remainsInput}
            value={_maxMC === 0 ? 0 : _maxMC || ''}
            type="number"
            onWheel={(e) => e.target.blur()}
            onChange={(e) => changeFilterValue(e.target.value, 'maxRemainderOfMarkingCodes')}
          />
        </div>
        {MCError && <span className={s.error}>Неправильный диапазон</span>}
      </div>
      <div>
        <FilterButtons onSubmit={onConfirm} onDismiss={onDismiss} />
      </div>
    </Stack>
  );
};
