import React from 'react';
import s from '../index.module.scss';
import Input from '../../../components/Forms/Input';
import CustomSelect from 'components/Forms/customSelect/customSelect.index';
import { Field } from 'formik';
import { Dropdown } from 'office-ui-fabric-react';
import ProductGroupOptions from './ProductGroupsOptions';
import PlacesOfBusiness from './placesOfBusiness/PlacesOfBusiness';
import Archive from './Archive';

const CompanyConfig = ({ isAdding = false }) => {
  return (
    <div className={s.company}>
      {!isAdding && <h3 className={s.title}>Конфигурация компании</h3>}
      <Input name="name" type="text" label="Наименование организации" />
      {!isAdding && (
        <>
          <Input name="glnPrefix" type="text" label="Префикс организации" />
          <Input
            name="legalAddress"
            type="text"
            label="Информация об юридическом адресе компании"
          />
          <Input name="manufacturer" type="text" label="Информация об изготовителе упаковки" />
          <Input
            name="manufacturerAddress"
            type="text"
            label="Информация об адресе производства для упаковки"
          />
        </>
      )}

      {!isAdding && (
        <Field name={'isArchived'}>
          {(props) => {
            const { form } = props;
            return <Archive />;
          }}
        </Field>
      )}

      {!isAdding && (
        <Field name={'productGroups'}>
          {(props) => {
            const { form } = props;
            return (
              <ProductGroupOptions
                onClick={(group) => form.setFieldValue('productGroups', group)}
              />
            );
          }}
        </Field>
      )}

      {!isAdding && (
        <Field name={'placeOfBusinesses'}>
          {(props) => {
            return <PlacesOfBusiness />;
          }}
        </Field>
      )}
    </div>
  );
};

export default CompanyConfig;
