import React from 'react'
import s from './GrayPanel.module.scss'

export const GrayPanel = ({title = '', className, children}) => {
    return (
        <div className={`${s.panel} ${className}`}>
            <span className={s.panelTitle}>{title}</span>
            {children}
        </div>
    )
}
