import { Icon } from '@fluentui/react/lib/Icon';
import { useId } from '@uifabric/react-hooks';
import { TooltipHost } from 'office-ui-fabric-react';
import React, { useEffect, useState } from 'react';
import { getDataFromLocalStorage } from '../../functions';
import { Confirm } from '../Confirm/Confirm';
import { RenderHeaderLogo } from './Logo';
import s from './Header.module.scss';
import { AlertIframe } from '../Alert/AlertIframe';
import { useHistory } from 'react-router-dom';
import { getIsLowBalance } from 'redux/App/appSelectors';
import { useDispatch, useSelector } from 'react-redux';
import { resetMinBalance } from 'redux/App/appReducer';
import AutoUpdateButton from './autoUpdate/AutoUpdateButton';
//import pdf from '/home/ilkhom-asap/myWork/upline/upline_admin-module/public/manual.pdf'
import signOutIcon from '../../assets/img/SignOut.svg';
import helpIcon from '../../assets/img/Help.svg';

const calloutProps = { gapSpace: 0 };
const hostStyles = { root: { display: 'inline-block' } };

export const Header = ({ logout }) => {
  const [name, setName] = useState('');
  const [surname, setSurname] = useState('');
  const [isModalShow, setIsModalShow] = useState(false);
  const tooltipId = useId('tooltip');
  const [showAlert, setShowAlert] = useState(false);
  const history = useHistory();
  const islowBalance = useSelector(getIsLowBalance);
  const dispatch = useDispatch();

  const onBalanceClick = () => {
    if (history.location.pathname === '/balance') {
      dispatch(resetMinBalance());
      return;
    }
    history.push('/balance');
  };

  const pdf = `${window.location.origin}/manual.pdf`;

  useEffect(() => {
    const localStorageData = getDataFromLocalStorage('uplineAdminData');
    const { firstName, surname } = JSON.parse(localStorageData);

    setName(firstName);
    setSurname(surname);
  }, []);

  const closeModal = () => setShowAlert(false);

  return (
    <>
      {!!showAlert && <AlertIframe source={pdf} onClose={closeModal} closeText={'Закрыть'} />}

      <div className={s.header}>
        <div className={s.logoContainer}>
          {RenderHeaderLogo()}
          <span className={s.logoText}>HI-MARK</span>
        </div>
        <div className={s.mainInfo}>
          <span className={s.title}>{`Модуль администратора и управления кодами`}</span>
        </div>
        <div className={s.controls}>
          <AutoUpdateButton isUpdate={false} />
          <button className={islowBalance ? s.balanceLow : s.balance} onClick={onBalanceClick}>
            <span className={s.balnceText}>{`${islowBalance ? '!' : ''} Баланс Честный знак`}</span>
          </button>

          <button className={s.button && s.noDisplay}>
            <Icon className={s.icon && s.noDisplay} iconName="Ringer" />
          </button>
          <button className={`${s.button} ${s.help}`} onClick={() => setShowAlert(true)}>
            <img src={helpIcon} alt="helpIcon" className={s.icon} />
            {/* <Icon className={`${s.icon}  `} iconName="Help" onClick={() => setShowAlert(true)} /> */}
          </button>
          <span className={`${s.button} ${s.name}`}>
            {`${name.slice(0, 1)}${surname.slice(0, 1)}`}
            <TooltipHost
              content="Выйти"
              id={tooltipId}
              calloutProps={calloutProps}
              styles={hostStyles}
            >
              <button
                className={s.logout}
                aria-describedby={tooltipId}
                onClick={() => setIsModalShow(true)}
              >
                {/* <Icon className={s.logoutIcon} iconName="SignOut" /> */}

                <img className={s.logoutIcon} src={signOutIcon} alt="signout icon" />
              </button>
            </TooltipHost>
          </span>
        </div>
        {isModalShow && (
          <Confirm
            isShow={isModalShow}
            setIsShow={setIsModalShow}
            onSend={logout}
            title="Выйти?"
            subText="Вы действительно хотите выйти?"
            okText="Выйти"
          />
        )}
      </div>
    </>
  );
};
