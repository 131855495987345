import { DatePicker, DayOfWeek, Dropdown, Label, Stack } from 'office-ui-fabric-react';
import React, { useEffect, useState } from 'react';
import productsAPI from '../../../api/productsAPI';
import LegalApi from '../../../api/legalAPI';
import g from '../../../assets/scss/Main.module.scss';
import { DayPickerStrings } from '../../../constants';
import {
  checkDateError,
  convertToUTCDate,
  getDateFromTimestamp,
  mapProductsForDropdown,
  mapItemsForDropdown
} from '../../../functions';
import { useSelect } from '../../../hooks';
import s from '../ConsignmentsFilter/ConsignmentsFilter.module.scss';
import { useDispatch } from 'react-redux';
import { FilterButtons } from '../FilterButtons/FilterButtons';
import {setFilteredData} from 'redux/App/appReducer'

export const AggregatesFilter = ({ query, setQuery, setIsOpenFilters }) => {
  const [productsForDropdown, getProductsForDropdown] = useSelect(
    productsAPI.getAllProducts,
    null,
    mapProductsForDropdown
  );
  const [levelsForDropdown] = useState([
    { key: 1, text: 1 },
    { key: 2, text: 2 }
  ]);

  const [minCreationDate, setMinCreationDate] = useState(
    query.minCreationDate ? new Date(query.minCreationDate) : null
  );
  const [maxCreationDate, setMaxCreationDate] = useState(
    query.maxCreationDate ? new Date(query.maxCreationDate) : null
  );
  const [productId, setProductId] = useState(query.productId);
  const [level, setLevel] = useState(query.level);
  const [dateErrors, setDateErrors] = useState(new Set([]));
  const [companyId, setCompanyId] = useState(query.companyId);
  const [companiesForDropdown, setCompaniesForDropdown] = useState([]);
  const dispatch = useDispatch();

  useEffect(() => {
    getProductsForDropdown();
    LegalApi.getCompanies().then((res) => setCompaniesForDropdown(mapItemsForDropdown(res)));
  }, []);

  const onConfirm = () => {

    const filterObj = {
      productId: productId || undefined,
      level: level || undefined,
      minCreationDate: minCreationDate ? convertToUTCDate(minCreationDate) : undefined,
      maxCreationDate: maxCreationDate ? convertToUTCDate(maxCreationDate) : undefined,
      companyId: companyId || undefined
    };

    if (!dateErrors.size) {
      setQuery(
        (prev) => ({
          ...prev,
          ...filterObj
        }),
        'replace'
      );
      setIsOpenFilters(false);
    }
    dispatch(setFilteredData(filterObj));
  };

  const onDismiss = () => {
    setLevel(null);
    setProductId(null);
    setMinCreationDate(null);
    setMaxCreationDate(null);
    setDateErrors(new Set([]));
    setCompanyId(null);

    const filterObj = {
      productId: undefined,
      level: undefined,
      minCreationDate: undefined,
      maxCreationDate: undefined,
      pageNumber: 1,
      companyId: undefined
    }

    setQuery(
      (prev) => ({
        ...prev,
        ...filterObj
      }),
      'replace'
    );
    dispatch(setFilteredData(filterObj));
    setDateErrors(new Set([]));
    setIsOpenFilters(false);
  };

  const changeFilterValue = (value, fieldName) => {
    switch (fieldName) {
      case 'productId':
        setProductId(value);
        break;
      case 'minCreationDate':
        setMinCreationDate(value);
        break;
      case 'maxCreationDate':
        setMaxCreationDate(value);
        break;
      case 'companyId':
        setCompanyId(value);
        break;
      default:
        setLevel(value);
    }  
  };

  useEffect(() => {
    checkDateError(
      { productCreationDate: [minCreationDate, maxCreationDate] },
      dateErrors,
      setDateErrors
    );
  }, [minCreationDate, maxCreationDate]);

  return (
    <Stack tokens={{ childrenGap: 25 }}>
      <Dropdown
        label="Юридические лица"
        selectedKey={companyId}
        options={companiesForDropdown}
        onChange={(_, item) => changeFilterValue(item.key, 'companyId')}
      />
      {levelsForDropdown && (
        <Dropdown
          label="Уровень агрегации"
          selectedKey={level}
          options={levelsForDropdown}
          onChange={(_, item) => changeFilterValue(item.key, 'level')}
        />
      )}
      {
        <Dropdown
          label="Продукт"
          selectedKey={productId}
          options={productsForDropdown}
          onChange={(_, item) => changeFilterValue(item.key, 'productId')}
        />
      }
      <Label style={{ marginTop: 25 }}>Дата создания</Label>
      <div className={g.dateInputs}>
        <DatePicker
          className={s.date}
          firstDayOfWeek={DayOfWeek.Monday}
          label="От"
          isRequired={false}
          allowTextInput={true}
          formatDate={(date) => {
            return !date
              ? null
              : getDateFromTimestamp(date, false, { delimiter: '.', withoutTime: true });
          }}
          strings={DayPickerStrings}
          value={!minCreationDate ? null : new Date(minCreationDate)}
          onSelectDate={(value) => changeFilterValue(value, 'minCreationDate')}
          maxDate={maxCreationDate}
        />
        <DatePicker
          className={s.date}
          firstDayOfWeek={DayOfWeek.Monday}
          label="До"
          minDate={minCreationDate}
          isRequired={false}
          allowTextInput={true}
          formatDate={(date) => {
            return !date
              ? null
              : getDateFromTimestamp(date, false, { delimiter: '.', withoutTime: true });
          }}
          strings={DayPickerStrings}
          value={!maxCreationDate ? null : new Date(maxCreationDate)}
          onSelectDate={(value) => changeFilterValue(value, 'maxCreationDate')}
        />
        {!!dateErrors.has('productCreationDate') && (
          <span className={s.error}>Неправильный диапазон</span>
        )}
      </div>
      <div>
        <FilterButtons onSubmit={onConfirm} onDismiss={onDismiss} />
      </div>
    </Stack>
  );
};
