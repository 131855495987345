export const validatePermits = (certificateField, setFormErrors, showAlert) => {
  let errors = [];
  if (certificateField?.wellNumber) {
    const alert = () =>
      showAlert(
        'Изменение продукта',
        `Чтобы изменить значение поля "номер скважины" необходимо заполнить поля, "Тип разрешительной документации", "номер документа", "дата документа" или оставить эти три поля незаполненными`
      );
    if (
      certificateField?.certificateType === 'SELECT_TYPE' &&
      !certificateField?.certificateNumber &&
      !certificateField?.certificateDate
    ) {
      return {
        certificateType: null,
        certificateNumber: null,
        certificateDate: null,
        wellNumber: certificateField.wellNumber
      };
    }
    if (
      certificateField?.certificateType === 'SELECT_TYPE' ||
      !certificateField?.certificateNumber ||
      !certificateField?.certificateDate
    ) {
      alert();
      return false;
    }

    if (
      certificateField?.certificateType !== 'SELECT_TYPE' &&
      certificateField?.certificateNumber &&
      certificateField?.certificateDate
    ) {
      if (validateField(certificateField, setFormErrors)) return certificateField;
      else return false;
    }
  } else {
    certificateField.wellNumber = null;
    if (
      !certificateField?.certificateDate &&
      !certificateField?.certificateNumber &&
      certificateField?.certificateType === 'SELECT_TYPE' &&
      !certificateField?.wellNumber
    ) {
      return null;
    }

    for (const prop in certificateField) {
      if (
        (!certificateField[prop] && prop !== 'wellNumber') ||
        (prop === 'certificateType' && certificateField[prop] === 'SELECT_TYPE')
      ) {
        setFormErrors((p) => ({
          ...p,
          [prop]: 'Обязательное поле'
        }));
        errors.push(prop);
      }
    }

    if (Object.keys(errors)?.length) return false;
    if (
      certificateField?.certificateDate &&
      certificateField?.certificateNumber &&
      certificateField?.certificateType !== 'SELECT_TYPE'
    ) {
      if (validateField(certificateField, setFormErrors)) return certificateField;
      else return false;
    }
  }
};

function getMinDate(certificateType) {
  if (certificateType === 'STATE_REGISTRATION_CERTIFICATE') return new Date('01.01.1900');
  else return new Date('01.01.2000');
}

function validateField(fields, setFormErrors) {
  const dateFieldErrorMsg =
    fields.certificateType === 'STATE_REGISTRATION_CERTIFICATE'
      ? 'Укажите дату позже 01.01.1900'
      : 'Укажите дату позже 01.01.2000';
  const fieldsArr = Object.entries(fields);
  let result = true;
  fieldsArr.forEach((entry) => {
    if (entry[0] === 'certificateNumber') {
      result = !!entry[1] && entry[1].toString().length > 0;
      if (!result) setFormErrors((p) => ({ ...p, certificateNumber: 'Обязательное поле' }));
    }
    if (entry[0] === 'certificateDate') {
      result = entry[1] > getMinDate(fields.certificateType);
      if (!result) setFormErrors((p) => ({ ...p, certificateDate: dateFieldErrorMsg }));
    }
  });
  return result;
}
