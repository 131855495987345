import { Checkbox } from 'office-ui-fabric-react';
import React from 'react';
import clsx from 'clsx';
import g from '../../../assets/scss/Main.module.scss';
import { TableAction } from '../../../components/TableAction/TableAction';
import { moreInfoIcon } from '../../../constants';
import {
  cropText,
  getDateFromTimestamp,
  getStatusByEnum,
  isShipped,
  tableCellWidth,
  formatTwoPartDate
} from '../../../functions';
import s from '../../Products/Products.module.scss';
import { setsTableHeader as tableHeader, isChkboxDisbabled, adjustTable } from '../constants';
import { TableItemWithTooltip } from './TableItemWithTooltip';
import { useAlert } from '../../../hooks';
import { Alert } from '../../../components/Alert/Alert';
import dayjs, { customParseFormat } from 'dayjs';
import { wideScreen } from '../../../constants';
import info_button from '../../../assets/img/info_button.svg';

const currentWidth = window.screen.availWidth;

// ввод в оборот

export const SetsTableRow = ({
  report,
  onSelectReport,
  selectedReports,
  rowStyle = s.tableCell
}) => {
  const setsTableHeader = adjustTable(currentWidth, wideScreen, tableHeader);
  const setsTableWidth = tableCellWidth(tableHeader);

  const { alertTitle, alertText, isAlertShowed, showAlert, hideAlert } = useAlert();
  const errorInfoHandler = () => {
    showAlert('Текст ошибки', report?.errorMessage);
  };
  return (
    <div className={g.tableItem}>
      {isAlertShowed && <Alert title={alertTitle} text={alertText} onClose={() => hideAlert()} />}
      <div className={g.tableRow} style={{ width: setsTableWidth(0) }}>
        <Checkbox
          onChange={() => onSelectReport(report)}
          disabled={isShipped(report.status) || isChkboxDisbabled(report.status)}
          checked={selectedReports.has(report.id)}
        />
      </div>
      <TableItemWithTooltip tooltipValue={report.suzId}>
        <div className={clsx(g.tableRow, rowStyle)} style={{ width: setsTableWidth(1) }}>
          {cropText(report.suzId, setsTableHeader[1].maxLength)}
        </div>
      </TableItemWithTooltip>

      <TableItemWithTooltip tooltipValue={formatTwoPartDate(report.creationDate).toolTipPart}>
        <div className={clsx(g.tableRow, rowStyle)} style={{ width: setsTableWidth(2) }}>
          {formatTwoPartDate(report.creationDate).mainPart}
        </div>
      </TableItemWithTooltip>

      <TableItemWithTooltip tooltipValue={formatTwoPartDate(report.sendingDate).toolTipPart}>
        <div className={clsx(g.tableRow, rowStyle)} style={{ width: setsTableWidth(3) }}>
          {formatTwoPartDate(report.sendingDate).mainPart}
        </div>
      </TableItemWithTooltip>

      <TableItemWithTooltip
        tooltipValue={formatTwoPartDate(report.productCreationDate).toolTipPart}
      >
        <div className={clsx(g.tableRow, rowStyle)} style={{ width: setsTableWidth(4) }}>
          {formatTwoPartDate(report.productCreationDate).mainPart}
        </div>
      </TableItemWithTooltip>

      <div
        className={clsx(g.tableRow, g.tableRowFlex, rowStyle)}
        style={{ width: setsTableWidth(5) }}
      >
        <div>{getStatusByEnum(report.status)}</div>
        {report?.status === 'REJECTED' && report.errorMessage && (
          <div>
            <TableAction
              isButton
              onClick={errorInfoHandler}
              tooltipText={'Текст ошибки'}
              icon={info_button}
            />
          </div>
        )}
      </div>
      {/* продукт */}
      <TableItemWithTooltip tooltipValue={report.productName}>
        <div className={clsx(g.tableRow, rowStyle)} style={{ width: setsTableWidth(6) }}>
          {setsTableHeader[5]?.longerProduct
            ? report.productName
            : cropText(report.productName, 15)}
        </div>
      </TableItemWithTooltip>
      {/* продукт */}

      <TableItemWithTooltip tooltipValue={report.productGtin}>
        <div className={clsx(g.tableRow, rowStyle)} style={{ width: setsTableWidth(1) }}>
          {cropText(report.productGtin, setsTableHeader[7].maxLength)}
        </div>
      </TableItemWithTooltip>

      <div className={clsx(g.tableRow, rowStyle)} style={{ width: setsTableWidth(8) }}>
        {report.partNumber}
      </div>
      <div className={clsx(g.tableRow, rowStyle)} style={{ width: setsTableWidth(9) }}>
        {report.numberMarkingCodes}
      </div>
      <div
        style={{ width: setsTableWidth(10) }}
        className={`${clsx(g.tableRow, rowStyle)} ${s.tableActionsItems}`}
      >
        <TableAction
          link={`/reports/sets/${report.id}`}
          params={{
            reportId: report.id,
            type: 'sets'
          }}
          icon={info_button}
        />
      </div>
    </div>
  );
};
