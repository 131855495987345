import { DatePicker, DayOfWeek, Dropdown, Label, Stack } from 'office-ui-fabric-react';
import React, { useEffect, useState } from 'react';
import linesAPI from '../../../api/linesAPI';
import LegalApi from '../../../api/legalAPI';
import productsAPI from '../../../api/productsAPI';
import g from '../../../assets/scss/Main.module.scss';
import { DayPickerStrings } from '../../../constants';
import {
  checkDateError,
  convertToUTCDate,
  getDateFromTimestamp,
  mapItemsForDropdown,
  mapProductsForDropdown
} from '../../../functions';
import { FilterButtons } from '../FilterButtons/FilterButtons';
import { useDispatch } from 'react-redux';
import {setFilteredData} from 'redux/App/appReducer'
import { Loader } from 'components/Loader/Loader';
import s from './ConsignmentsFilter.module.scss';

export const ConsignmentsFilter = ({ query, setQuery, setIsOpenFilters}) => {

  const [productsForDropdown, setProductsForDropdown] = useState([]);
  const [linesForDropdown, setLinesForDropdown] = useState([]);
  const [minProductCreationDate, setMinProductCreationDate] = useState(
    query.minProductCreationDate ? new Date(query.minProductCreationDate) : null
  );
  const [maxProductCreationDate, setMaxProductCreationDate] = useState(
    query.maxProductCreationDate ? new Date(query.maxProductCreationDate) : null
  );
  const [productId, setProductId] = useState(query.productId);
  const [lineId, setLineId] = useState(query.lineId);
  const [dateErrors, setDateErrors] = useState(new Set([]));
  const [companyId, setCompanyId] = useState(query.companyId);
  const [companiesForDropdown, setCompaniesForDropdown] = useState([]);
  const [loading, setLoading] = useState(false)
  const dispatch = useDispatch()

  useEffect(() => {
    LegalApi.getCompanies().then((res) => setCompaniesForDropdown(mapItemsForDropdown(res)));
    getLinesAndProductsUpdated();
  }, []);

  useEffect(() => {
    if (linesForDropdown.length || productsForDropdown.length) {
      getLinesAndProductsUpdated();
    }
  }, [query.productId, query.lineId]);


  const getLinesAndProductsUpdated = async () => {
    setLoading(true)
    let lines, products;

    if (!query.productId && !query.lineId) {
      lines = await linesAPI.getAllLines();
      products = await productsAPI.getAllProductsNoMarking();

      setLinesForDropdown(mapItemsForDropdown(lines));
      setProductsForDropdown(mapProductsForDropdown(products));
    }

    if (
      (query.productId && !query.lineId) ||
      (query.productId && query.lineId)
    ) {
      lines = await linesAPI.getLineByProductId(productId);
      products = await productsAPI.getAllProductsNoMarking();

      setLinesForDropdown(mapItemsForDropdown(lines));
      setProductsForDropdown(mapProductsForDropdown(products));
    }

    if (query.lineId && !query.productId) {
      lines = await linesAPI.getAllLines();
      products = await productsAPI.getProductsByLineId(lineId);

      setLinesForDropdown(mapItemsForDropdown(lines));
      setProductsForDropdown(mapProductsForDropdown(products));
    }
    setLoading(false)
  };



  const onConfirm = () => {
    const filterObj = {
      productId: productId || undefined,
      lineId: lineId || undefined,
      minProductCreationDate: minProductCreationDate
            ? convertToUTCDate(minProductCreationDate)
            : undefined,
      maxProductCreationDate: maxProductCreationDate
            ? convertToUTCDate(maxProductCreationDate)
            : undefined,
      pageNumber: 1,
      companyId: companyId || undefined
    }

    if (!dateErrors.size) {
      setQuery(
        (prev) => ({
          ...prev,
          ...filterObj
        }),
        'replace'
      );
      setIsOpenFilters(false);
    }
    dispatch(setFilteredData(filterObj));
  };

  const onDismiss = () => {
    setLineId(null);
    setProductId(null);
    setMinProductCreationDate(null);
    setMaxProductCreationDate(null);
    setDateErrors(new Set([]));
    setCompanyId(null);
  
    const filterObj = {
      productId: undefined,
      lineId: undefined,
      minProductCreationDate: undefined,
      maxProductCreationDate: undefined,
      companyId: undefined
    }

    setQuery(
      (prev) => ({
        ...prev,
        ...filterObj
      }),
      'replace'
    );
    dispatch(setFilteredData(filterObj));
    setDateErrors(new Set([]));
    setIsOpenFilters(false);
  };

  const changeFilterValue = (value, fieldName) => {
    switch (fieldName) {
      case 'productId':
        setProductId(value);
        break;
      case 'minProductCreationDate':
        setMinProductCreationDate(value);
        break;
      case 'maxProductCreationDate':
        setMaxProductCreationDate(value);
        break;
      case 'companyId':
        setCompanyId(value);
        break;
      default:
        setLineId(value);
    }    
  };

  useEffect(() => {
    checkDateError(
      { productCreationDate: [minProductCreationDate, maxProductCreationDate] },
      dateErrors,
      setDateErrors
    );
  }, [minProductCreationDate, maxProductCreationDate]);

  return (
    <>
    {
      loading ? <Loader filled={true} size={'large'} />
      :
      <Stack tokens={{ childrenGap: 25 }}>
      <Dropdown
        label="Юридические лица"
        selectedKey={companyId}
        options={companiesForDropdown}
        onChange={(_, item) => changeFilterValue(item.key, 'companyId')}
      />
      {linesForDropdown && (
        <Dropdown
          label="Производственная линия"
          selectedKey={lineId}
          options={linesForDropdown}
          onChange={(_, item) => changeFilterValue(item.key, 'lineId')}
        />
      )}
      {
        <Dropdown
          label="Продукт"
          selectedKey={productId}
          options={productsForDropdown}
          onChange={(_, item) => changeFilterValue(item.key, 'productId')}
        />
      }
      <Label style={{ marginTop: 25 }}>Дата производства</Label>
      <div className={g.dateInputs}>
        <DatePicker
          className={s.date}
          firstDayOfWeek={DayOfWeek.Monday}
          label="От"
          isRequired={false}
          allowTextInput={true}
          formatDate={(date) => {
            return !date
              ? null
              : getDateFromTimestamp(date, false, { delimiter: '.', withoutTime: true });
          }}
          strings={DayPickerStrings}
          value={!minProductCreationDate ? null : new Date(minProductCreationDate)}
          onSelectDate={(value) => changeFilterValue(value, 'minProductCreationDate')}
          maxDate={maxProductCreationDate}
        />
        <DatePicker
          className={s.date}
          firstDayOfWeek={DayOfWeek.Monday}
          label="До"
          minDate={minProductCreationDate}
          isRequired={false}
          allowTextInput={true}
          formatDate={(date) => {
            return !date
              ? null
              : getDateFromTimestamp(date, false, { delimiter: '.', withoutTime: true });
          }}
          strings={DayPickerStrings}
          value={!maxProductCreationDate ? null : new Date(maxProductCreationDate)}
          onSelectDate={(value) => changeFilterValue(value, 'maxProductCreationDate')}
        />
        {!!dateErrors.has('productCreationDate') && (
          <span className={s.error}>Неправильный диапазон</span>
        )}
      </div>
      <div>
        <FilterButtons onSubmit={onConfirm} onDismiss={onDismiss} />
      </div>
    </Stack>
    }
    </>
        
  );
};
