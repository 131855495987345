import { useState } from 'react';
import { Alert } from 'components/Alert/Alert';
import { getGlobalSuccAlertBody } from 'redux/App/appSelectors';
import { useSelector, useDispatch } from 'react-redux';
import { setGlobSuccAlert } from 'redux/App/appReducer';

const emptyAlertBody = {
  title: '',
  text: ''
};

const GlobalSuccessAlert = () => {
  const dispatch = useDispatch();
  const globalAlertBody = useSelector(getGlobalSuccAlertBody);
  const hideAlert = () => {
    dispatch(setGlobSuccAlert(emptyAlertBody));
  };

  return (
    <>
      {globalAlertBody.title ? (
        <Alert title={globalAlertBody.title} text={globalAlertBody.text} onClose={hideAlert} />
      ) : null}
    </>
  );
};

export default GlobalSuccessAlert;
