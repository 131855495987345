import { useEffect, useState } from 'react';
import s from './mainSettings.index.module.scss';
import { PrimaryButton } from 'office-ui-fabric-react';
import { Checkbox } from '@fluentui/react';
import { Loader } from 'components/Loader/Loader';
import settingsAPI from 'api/settingsAPI';
import { useLocation } from 'react-router-dom/cjs/react-router-dom.min';
import { useCrumbsContext } from 'context/CrumbsContext';
import { useSelector, useDispatch } from 'react-redux';
import { setGenSettings, sendGenSettings } from 'redux/App/appReducer';
import { getGeneralSettings } from 'redux/App/appSelectors';

const compareTwoObjects = (obj1, obj2) => {
  const keys = Object.keys(obj1);
  for (let key of keys) {
    if (obj1[key] !== obj2[key]) {
      return false;
    }
  }
  return true;
};

const MainSettings = () => {
  const genSettingsFromRedx = useSelector(getGeneralSettings);
  const [genSettings, setSettings] = useState({});
  const [error, setError] = useState('');
  const [isDisabled, setIsDisabled] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const location = useLocation();
  const { setCrumbs } = useCrumbsContext();
  const dispatch = useDispatch();

  useEffect(() => {
    setCrumbs([location.pathname.slice(0, location.pathname.lastIndexOf('/')), 'Общие настройки']);
  }, []);

  useEffect(() => {
    setIsDisabled(compareTwoObjects(genSettings, genSettingsFromRedx));
  }, [genSettings, genSettingsFromRedx]);

  const getGenralSettings = async () => {
    setIsLoading(true);
    const res = await settingsAPI.getGeneralSettings();
    console.log(res, 'sfdsfsdsdfdfdsfdsfsdsdfsdfdfdfdsfdsfsdsdfsdfdfdfd');
    dispatch(setGenSettings(res));
    setSettings(res);
    setIsLoading(false);
  };

  useEffect(() => {
    getGenralSettings();
  }, []);

  const onChange = (name) => {
    setSettings((prev) => {
      const newValue = !prev[name];
      console.log(newValue);

      return { ...prev, [name]: !prev[name] };
    });
  };

  const onSubmit = async () => {
    dispatch(sendGenSettings(genSettings));
    console.log(genSettings);
  };

  return (
    <main className={s.mainWrapper}>
      {isLoading ? (
        <Loader />
      ) : (
        <section>
          <div className={s.fieldWrapper}>
            <Checkbox
              onChange={() => onChange("checkProductionAndExpirationDatesWhenSubmittingUtilisationReports")}
              checked={
                genSettings?.checkProductionAndExpirationDatesWhenSubmittingUtilisationReports
              }
            />
            <p className={s.fieldDescrp}>
              {'Проверять срок годности при отправке отчетов о нанесении'}
            </p>
          </div>
          <p className={s.error}>{error}</p>

          <div className={s.fieldWrapper}>
            <Checkbox
              onChange={() => onChange("specifySeriesWhenCreatingShiftTask")}
              checked={
                genSettings?.specifySeriesWhenCreatingShiftTask ||
                false
              }
            />
            <p className={s.fieldDescrp}>
              {'Указывать серию при создании сменного задания'}
            </p>
          </div>
          <p className={s.error}>{error}</p>

          <PrimaryButton
            disabled={isDisabled}
            text="Сохранить"
            type="button"
            onClick={onSubmit}
            className={s.btn}
          />
        </section>

      )}
    </main>
  );
};

export default MainSettings;
