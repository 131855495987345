import API from './APIService'

class AuthAPI {
    auth = (payload) => API.post('/api/auth/token/', {...payload, api: 'administrator.up-line'})
    checkToken = (token) => API.post('/api/auth/CheckTokenLifeTime', {token})
    refreshToken = () => API.get('/api/auth/refreshToken')
    getSomeFile = () => API.get('/api/OneC/Get')
}

export default new AuthAPI()
