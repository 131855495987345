import React from "react";
import s from "./Header.module.scss";

export const RenderHeaderLogo = () => (
    <svg
    // preserveAspectRatio="xMidYMid meet"
    //   width="4.5rem"
    //   height="4.5rem"
       viewBox="-2 1 27 27"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
       className={s.logo}
    >
      <rect width="29" height="29" />
      <rect
        x="8.5126"
        y="8.15625"
        width="3.02171"
        height="3.17176"
        fill="#34DB93"
      />
      <rect
        x="11.5344"
        y="11.3278"
        width="3.02172"
        height="3.17176"
        fill="#FDFFFF"
      />
      <rect
        x="14.5558"
        y="14.5004"
        width="3.02172"
        height="3.17173"
        fill="#FDFFFF"
      />
      <rect
        x="11.5334"
        y="14.5004"
        width="3.02172"
        height="3.17173"
        fill="#FDFFFF"
      />
      <rect
        x="8.5126"
        y="17.6719"
        width="3.02171"
        height="3.17179"
        fill="#FDFFFF"
      />
      <rect
        x="17.579"
        y="8.15625"
        width="3.02171"
        height="3.17176"
        fill="#FDFFFF"
      />
      <rect
        x="17.579"
        y="17.672"
        width="3.02171"
        height="3.17173"
        fill="#FDFFFF"
      />
    </svg>
  );