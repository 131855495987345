import { Checkbox, SearchBox } from 'office-ui-fabric-react';
import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { NumberParam, StringParam, useQueryParams } from 'use-query-params';
import AggregatesAPI from '../../api/AggregatesAPI';
import g from '../../assets/scss/Main.module.scss';
import s from './Aggregates.module.scss';
import { AggregatesFilter } from '../../components/Filters/AggregatesFilter/AggregatesFilter';
import { FiltersPanel } from '../../components/FiltersPanel/FiltersPanel';
import { Pagination } from '../../components/Pagination/Pagination';
import { Table } from '../../components/Table/Table';
import { TableAction } from '../../components/TableAction/TableAction';
import { aggregateStatuses, moreInfoIcon } from '../../constants';
import { useCrumbsContext } from '../../context/CrumbsContext';
import { getDateFromTimestamp, getStatusById } from '../../functions';
import { useCheckboxFilter, usePaginationAndSearch, useDataFilter } from '../../hooks';
import { useHistory } from 'react-router-dom';
import { getFilteredData } from 'redux/App/appSelectors';
import { isResourseLink } from 'functions';
import clsx from 'clsx';

const tableHeader = [
  {
    title: 'Код агрегата',
    width: '0.17'
  },
  {
    title: 'Дата создания',
    width: '0.13'
  },
  {
    title: 'Ёмкость',
    width: '0.1'
  },
  {
    title: 'Уровень',
    width: '0.1'
  },
  {
    title: 'Статус',
    width: '0.13'
  },
  {
    title: 'Продукт',
    width: '0.25'
  },
  {
    title: 'Действия',
    width: '0.12'
  }
];

const defaultIntitVals = {
  brand: null,
  category: null,
  subCategory: null,
  maxRemainderOfMarkingCodes: null,
  minRemainderOfMarkingCodes: null,
  companyId: null
};

export const Aggregates = ({ isOpenFilters, setIsOpenFilters, status, statusParamName }) => {
  const [aggregates, setAggregates] = useState([]);
  const [loading, setLoading] = useState(false);
  const [query, setQuery] = useDataFilter('aggregates');

  useEffect(() => {
    if (status && !query.status) {
      setQuery((prev) => ({ ...prev, status: status }), 'replace');
    }
  }, [status]);

  const getAggregates = async (query) => {
    setLoading(true);
    let res = await AggregatesAPI.getAggregates(query);

    if (!res?.statusCode) {
      const { list, quantity } = res;
      setAggregates(list);
      setTotalItems(quantity);
    }
    setLoading(false);
  };

  const { onPageChanged, onSearchTermChanged, totalPages, setTotalItems, currentPage } =
    usePaginationAndSearch({ callback: getAggregates, query, setQuery, statusParamName, status });
  const [selectedFilters, onChangeFilter] = useCheckboxFilter(
    aggregateStatuses,
    'status',
    setQuery,
    query
  );

  return (
    <div>
      <div className={g.titleWrapper}>
        <h1 className={g.title}>Агрегаты</h1>
      </div>
      <div className={`${g.header}`} style={{ marginTop: 0 }}>
        <div className={clsx(g.headerFilters)} style={{ width: 370 }}>
          {aggregateStatuses.map((item) => {
            return (
              <Checkbox
                key={item.key}
                label={item.text}
                checked={selectedFilters.has(item.key)}
                onChange={() => onChangeFilter(item.key)}
              />
            );
          })}
        </div>
        <SearchBox
          className={g.search}
          value={query.searchValue}
          placeholder="Поиск по коду агрегата"
          onClear={() => onSearchTermChanged('')}
          onSearch={(newValue) => onSearchTermChanged(newValue)}
        />
      </div>
      <Table headerItems={tableHeader} maxHeight="67vh" loading={loading}>
        {aggregates.map((item) => {
          return (
            <div key={item.id} className={g.tableItem}>
              <div
                className={g.tableCell}
                style={{ width: `calc((100vw - 180px - 3.75rem) * ${tableHeader[0].width})` }}
              >
                {item.unitSerialNumber}
              </div>
              <div
                className={g.tableCell}
                style={{ width: `calc((100vw - 180px - 3.75rem) * ${tableHeader[1].width})` }}
              >
                {getDateFromTimestamp(item.creationDate, null, { withoutTime: true })}
              </div>
              <div
                className={g.tableCell}
                style={{ width: `calc((100vw - 180px - 3.75rem) * ${tableHeader[2].width})` }}
              >
                {item.aggregationUnitCapacity}
              </div>
              <div
                className={g.tableCell}
                style={{ width: `calc((100vw - 180px - 3.75rem) * ${tableHeader[3].width})` }}
              >
                {item.level}
              </div>
              <div
                className={g.tableCell}
                style={{ width: `calc((100vw - 180px - 3.75rem) * ${tableHeader[4].width})` }}
              >
                {getStatusById(item.status, aggregateStatuses)}
              </div>
              <div
                className={g.tableCell}
                style={{ width: `calc((100vw - 180px - 3.75rem) * ${tableHeader[5].width})` }}
              >
                {item?.product?.name}
              </div>
              <div
                className={s.tableActionCell}
                style={{ width: `calc((100vw - 180px - 3.75rem) * ${tableHeader[6].width})` }}
              >
                <TableAction
                  link={`/aggregates/${item.unitSerialNumber}`}
                  text={'Подробнее'}
                  tooltipText={'Подробнее'}
                  iconName={moreInfoIcon}
                />
              </div>
            </div>
          );
        })}
      </Table>
      <div className={g.footer}>
        {totalPages > 1 && (
          <Pagination
            pageCount={totalPages}
            onPageChange={onPageChanged}
            selectedPage={currentPage}
          />
        )}
      </div>
      <FiltersPanel
        isOpenFilters={isOpenFilters}
        setIsOpenFilters={setIsOpenFilters}
        query={query}
        setQuery={setQuery}
      >
        <AggregatesFilter />
      </FiltersPanel>
    </div>
  );
};
