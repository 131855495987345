export const getStatusInfoText = (releaseStatus, releaseVersion, hasBeenRefreshed) => {
  switch (releaseStatus) {
    case 1:
      return 'Ожидается назначения нового релиза';
    case 2:
      return 'Идет подготовка к установке нового релиза';
    case 3:
      return 'Идет загрузка установочных файлов';
    case 4:
      return 'Произошла ошибка загрузки установочных файлов';
    case 5:
      return `Доступно обновление до версии ${releaseVersion}`;
    case 7:
      return 'Произошла ошибка подготовки к установке';
    case 8:
    case 13:
      return 'Произошла ошибка запуска установки нового релиза';
    case 9:
      return 'Установка временно отложена. Новый цикл установки начнется чере 2 минуты.';
    case 14:
    case 15:
    case 16:
      return hasBeenRefreshed ? 'Установка обновления скоро завершится' : '';
    default:
      return '';
  }
};
