import API, { API2 } from './APIService';

const api = new API2();

class SettingsAPI {
  getSuzSettings = () => API.get('/api/Config/GetSuz');
  getGisMTSettings = () => API.get('/api/Config/GetGismt');
  getCatalogSettings = () => API.get('/api/Config/GetNatcat');
  getFTPSettings = () => API.get('/api/Config/GetFtp');
  getDatabaseSettings = () => API.get('/api/v2/administrator/settings/database');
  getCompanySettings = () => API.get('/api/Config/GetCompany');
  getConnectionId = (companyId) => API.post(`/api/Integration/${companyId}`);
  // (`/api/Integration/${companyId}`)

  updateSuzSettings = (settings) => API.put('/api/Config/UpdateSuz', settings);
  updateGisMTSettings = (settings) => API.put('/api/Config/UpdateGismt', settings);
  updateCatalogSettings = (settings) => API.put('/api/Config/UpdateNatcat', settings);
  updateFTPSettings = (settings) => API.put('/api/Config/UpdateFtp', settings);

  updateDatabaseSettings = (settings) =>
    API.post('/api/v2/administrator/settings/database', settings);

  updateCompanySettings = (settings) => API.put('/api/Config/UpdateCompany', settings);

  getGeneralSettings = () => API.get('/api/v2/administrator/settings/general');
  setGeneralSettings = (settingsObject) =>
    API.post('/api/v2/administrator/settings/general', settingsObject);

  isDatabasePage = () => API.get('/api/v2/administrator/settings/show-database-settings');
}

export default new SettingsAPI();
