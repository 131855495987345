import { useEffect, useState } from 'react';
import s from './Report.module.scss';
import { DayPickerStrings } from '../../constants';
import moment from 'moment';
import {
  Modal,
  DatePicker,
  DayOfWeek,
  DefaultButton,
  PrimaryButton,
  TextField,
  TimePicker
} from '@fluentui/react';
import { SimpLoader } from 'components/Loader/Loader';
import { getShouldCheckExpDate } from 'redux/App/appSelectors';
import g from '../../assets/scss/Main.module.scss';
import clsx from 'clsx';

const SendPrintReportModal = ({ isOpen, reports, onDismiss, onSubmit }) => {
  const [data, setData] = useState(reports);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    setData((p) =>
      p.map((rep) => {
        const date = new Date(rep.expirationDate);
        return {
          ...rep,
          expirationDate: new Date(rep.expirationDate).setHours(
            date.getHours(),
            date.getMinutes(),
            date.getSeconds()
          ),
          expirationDateTime: [date.getHours(), date.getMinutes(), date.getSeconds()]
        };
      })
    );
  }, []);

  const handleSubmit = async () => {
    setLoading(true);
    const formattedData = data.map(item => ({
      ...item,
      productCreationDate: item.productCreationDate ?
        moment(item.productCreationDate).format('YYYY-MM-DD') : null,
      expirationDate: item.expirationDate ?
        moment(item.expirationDate).format('YYYY-MM-DD') : null
    }));
    await onSubmit(formattedData);
    setLoading(false);
    onDismiss();
  };
  const onChange = (id, name) => (e, date) => {
    if (name === 'productCreationDate') {
      if (e === null) {
        setData((p) => {
          return p.map((rep) => {
            if (rep.id === id) {
              return { ...rep, productCreationDate: null };
            }
            return rep;
          });
        });
      } else {
        setData((p) => {
          return p.map((rep) => {
            if (rep.id === id) {
              return { ...rep, productCreationDate: e.getTime() };
            }
            return rep;
          });
        });
      }
    }
    if (name === 'expirationDate') {
      if (e === null) {
        setData((p) => {
          return p.map((rep) => {
            if (rep.id === id) {
              return { ...rep, expirationDate: null };
            }
            return rep;
          });
        });
      } else {
        setData((p) => {
          return p.map((rep) => {
            if (rep.id === id) {
              const expDate = rep?.expirationDateTime
                ? e.setHours(
                  rep.expirationDateTime[0],
                  rep.expirationDateTime[1],
                  rep.expirationDateTime[2]
                )
                : e.getTime();
              return { ...rep, expirationDate: expDate };
            }
            return rep;
          });
        });
      }
    }
    if (name === 'expirationDateTime') {
      setData((p) => {
        return p.map((rep) => {
          if (rep.id.toString() === id.toString()) {
            const currentDate = new Date(rep.expirationDate);
            const newDate = new Date(currentDate.setHours(
              date.getHours(),
              date.getMinutes(),
              date.getSeconds()
            ));
            return {
              ...rep,
              expirationDate: newDate.getTime(),
              expirationDateTime: [date.getHours(), date.getMinutes(), date.getSeconds()]
            };
          }
          return rep;
        });
      });
    }
  };

  return (
    <Modal containerClassName={s.modal} isOpen={isOpen} onDismiss={onDismiss} isBlocking={false}>
      {loading ? <SimpLoader size={'5rem'} /> : null}
      <main className={s.modalMainWrapper}>
        <span className={s.modTitle}>
          Проверьте корректность дат производства и окончания срока годности и скорректируйте при
          необходимости
        </span>
        <section className={s.columnTitles}>
          <span className={s.cell}>Номер отчета</span>
          <span className={s.cell}>Номер задания</span>
          <span className={s.cell}>Дата производства</span>
          <span className={s.expCell}>Дата окончания срока годности</span>
        </section>
        <section className={s.formMainWrap}>
          {data.map((report) => (
            <div >
              <DateForm report={report} onChange={onChange} />
              {!report.expirationDate && (
                <div className={s.errorMessage}>
                  <span>добавьте дату срока годности</span>
                </div>
              )}
            </div>
          ))}
        </section>
        <section className={s.buttonRow}>
          <PrimaryButton
            disabled={data.some((r) => !r.productCreationDate || !r.expirationDate)}
            className={s.btn}
            text={'Отправить'}
            onClick={handleSubmit}
          />
          <DefaultButton className={s.btn} text={'Отмена'} onClick={onDismiss} />
        </section>
      </main>
    </Modal>
  );
};

export default SendPrintReportModal;

const DateForm = ({ report, onChange }) => {
  return (
    <section className={s.rowWrapper}>
      <div className={s.cell}>
        <TextField value={report.id} disabled={true} className={s.textField} />
      </div>
      <div className={s.cell}>
        <TextField value={report.partNumber} disabled={true} className={s.textField} />
      </div>

      <DatePicker
        placeholder="Дата производства"
        className={s.date}
        firstDayOfWeek={DayOfWeek.Monday}
        allowTextInput={true}
        formatDate={(date) => {
          return moment(date).format('DD-MM-YYYY');
        }}
        strings={DayPickerStrings}
        defaultValue={'Выберете дату производства'}
        value={report.productCreationDate ? new Date(report.productCreationDate) : null}
        onSelectDate={onChange(report.id, 'productCreationDate')}
      />

      <div className={s.expCell}>
        <DatePicker
          placeholder="Дата срока годности"
          className={s.date}
          firstDayOfWeek={DayOfWeek.Monday}
          allowTextInput={true}
          formatDate={(date) => {
            return moment(date).format('DD-MM-YYYY');
          }}
          strings={DayPickerStrings}
          defaultValue={'Выберете дату срока годности'}
          value={report.expirationDate ? new Date(report.expirationDate) : null}
          onSelectDate={onChange(report.id, 'expirationDate')}
        />

        <TimePicker
          showSeconds
          allowFreeform
          autoComplete="on"
          dateAnchor={new Date(report.expirationDate)}
          defaultValue={new Date(report.expirationDate)}
          onChange={onChange(report.id, 'expirationDateTime')}
        />
      </div>
    </section>
  );
};
