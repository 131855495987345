import React, {useEffect, useState} from 'react'
import AuthAPI from '../../api/authAPI'

export const Debug = () => {
    const [file, setFile] = useState(null)

    useEffect(() => {
        AuthAPI.getSomeFile().then(res => {
            setFile(JSON.parse(res))
        })
    }, [])

    return (
        <div style={{width: '100%'}}>
            <pre style={{width: '100%', padding: 30}}>
            {JSON.stringify(file, null, 2)}
            </pre>
        </div>
    )
}
