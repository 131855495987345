import { Icon, Panel, PanelType } from 'office-ui-fabric-react'
import React, { useEffect } from 'react'
import { useDispatch } from 'react-redux'
import { isNullishObj } from '../../functions'
import { setFiltersAC } from '../../redux/App/appReducer'
import s from './FiltersPanel.module.scss'

const shouldKeepStatus = (componentName) => {
    const pagesWithStatus = ['ConsignmentsFilter', 'AggregatesFilter' ];
    return !!pagesWithStatus.find(name => name === componentName)
}

export const FiltersPanel = ({ isOpenFilters, setIsOpenFilters, query, setQuery, children }) => {

    const dispatch = useDispatch()
    useEffect(() => {      
   
        const obj ={...query, pageNumber: null, quantityOnPage: null}
        if (shouldKeepStatus(children.type.name)) obj.status = null;
        if (isNullishObj(obj)) {
            dispatch(setFiltersAC(false))
        } else {
            dispatch(setFiltersAC(true))
        }
    }, [query])

    useEffect(() => {
        return () => {
            dispatch(setFiltersAC(false))
        }
    }, [])

    return (
        <Panel
            styles={{
                header: {
                    display: 'none',
                }
            }}
            isLightDismiss
            hasCloseButton={false}
            className={s.filterPanel}
            headerText="Выберите фильтры"
            customWidth='450px'
            isOpen={isOpenFilters}
            onDismiss={() => setIsOpenFilters(false)}
            type={PanelType.custom}
        >
            <div className={s.filtersHeader}>
                <span className={s.filtersTitle}>Выберите фильтры</span>
                <button className={s.closeBtn} onClick={() => setIsOpenFilters(false)}>
                    <Icon iconName="Clear" />
                </button>
            </div>
            <div className={s.content}>
                {React.cloneElement(children, {
                    query,
                    setQuery,
                    isOpenFilters,
                    setIsOpenFilters
                })}
            </div>
        </Panel>
    )
}
