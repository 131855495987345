import { Stack } from 'office-ui-fabric-react';
import React, { useEffect, useState } from 'react';
import { NavLink, useParams } from 'react-router-dom';
import AggregatesAPI from '../../../api/AggregatesAPI';
import g from '../../../assets/scss/Main.module.scss';
import { GrayPanel } from 'components/GrayPanel/GrayPanel';
import { Loader } from 'components/Loader/Loader';
import { MARKING_TYPE } from '../../../constants';
import { useCrumbsContext } from '../../../context/CrumbsContext';
import { getCodeType, getDateFromTimestamp } from '../../../functions';
import s from './Aggregate.module.scss';

const aggregateKeys = {
  creationDate: 'Дата создания',
  productName: 'Продукт',
  markingCodeQuantity: 'Количество кодов'
};

export const Aggregate = () => {
  const [loading, setLoading] = useState(false);
  const [aggregate, setAggregate] = useState({});
  const { setCrumbs } = useCrumbsContext();
  const { aggregationCode } = useParams();
  // useBackBtn('/aggregates')

  useEffect(() => {
    setCrumbs(['/aggregates', `Агрегат ${aggregationCode}`]);
  }, [aggregationCode]);

  useEffect(() => {
    if (aggregationCode) {
      getAggregate(aggregationCode);
    }
  }, [aggregationCode]);

  const getAggregate = async (_aggregationCode) => {
    setLoading(true);
    const res = await AggregatesAPI.getAggregateByCode(_aggregationCode);

    if (!res?.statusCode) {
      setAggregate(res);
    }

    setLoading(false);
    return res;
  };

  const renderControl = (fieldName, i) => {
    let key, value;

    if (!aggregateKeys[fieldName]) {
      return;
    }

    key = aggregateKeys[fieldName];
    switch (fieldName) {
      case 'productName':
        value = `${aggregate[fieldName]} (${aggregate?.productGtin})`;
        break;
      case 'creationDate':
        value = getDateFromTimestamp(aggregate[fieldName]);
        break;
      default:
        value = aggregate[fieldName];
    }

    return (
      <div className={s.infoRow} key={i}>
        <span className={s.infoKey}>{key}:</span>
        <span className={s.infoValue}>{value}</span>
      </div>
    );
  };

  return (
    <div>
      {loading ? (
        <Loader />
      ) : (
        <>
          <div className={g.titleWrapper}>
            <h1 className={g.title}>{`Агрегат ${aggregationCode}`}</h1>
          </div>
          <GrayPanel className={s.panel}>
            <div className={s.mainInputs}>
              <Stack tokens={{ childrenGap: 25 }}>
                {Object.keys(aggregate).map((key, i) => {
                  return renderControl(key, i);
                })}
                <span className={s.markingCodesTitle}>Коды маркировки/агрегации:</span>
                <div className={s.infoContent}>
                  {aggregate.markingCodes?.map((code) => {
                    return getCodeType(code.code) === MARKING_TYPE ? (
                      <span key={code.id} className={s.code}>
                        {code.code}
                      </span>
                    ) : (
                      <NavLink to={`/aggregates/${code.code}`} key={code.id} className={s.code}>
                        {code.code}
                      </NavLink>
                    );
                  })}
                </div>
              </Stack>
            </div>
          </GrayPanel>
        </>
      )}
    </div>
  );
};
