// export const getCompanyInfo = (state) => {
//   const {
//     id,
//     name,
//     prefix,
//     legalAddress,
//     manufacturer,
//     manufacturerAddress,
//     apiNatCat,
//     apiSuz,
//     apiGisMt,
//     templates
//   } = state.legal.companyInfo;

//   return {
//     id,
//     name,
//     prefix,
//     legalAddress,
//     manufacturer,
//     manufacturerAddress,
//     apiKey: apiNatCat?.apiKey,
//     activeNatCat: apiNatCat?.active,
//     omsId: apiSuz?.omsId,
//     omsConnection: apiSuz?.omsConnection,
//     clientToken: apiSuz?.clientToken,
//     participantInn: apiSuz?.participantInn,
//     ownerInn: apiSuz?.ownerInn,
//     producerInn: apiSuz?.producerInn,
//     activeSuz: apiSuz?.active,
//     thumbPrint: apiGisMt?.thumbPrint,
//     activeGisMt: apiGisMt?.active,
//     suzDevelopmentApiUrl: apiSuz?.developmentApiUrl,
//     suzProductionApiUrl: apiSuz?.productionApiUrl,
//     suzSelectedProductionApiUrl: apiSuz?.selectedProductionApiUrl,
//     gisDevelopmentApiUrl: apiGisMt?.developmentApiUrl,
//     gisProductionApiUrl: apiGisMt?.productionApiUrl,
//     gisSelectedProductionApiUrl: apiGisMt?.selectedProductionApiUrl,
//     natCatDevelopmentApiUrl: apiNatCat?.developmentApiUrl,
//     natCatProductionApiUrl: apiNatCat?.productionApiUrl,
//     natCatSelectedProductionApiUrl: apiNatCat?.selectedProductionApiUrl,
//     templates,
//     suzId: apiSuz?.id,
//     gisMtId: apiGisMt?.id,
//     natCatId: apiNatCat?.id,
//   };
// };

export const getCompanyInfo = (state) => state.legal.companyInfo;

export const getIsLoadingCompany = (state) => state.legal.isLoading;
export const getErrorCompany = (state) => state.legal.error;
export const getSuccessUpdate = (state) => state.legal.successUpdate;
export const getSuccessCreate = (state) => state.legal.successCreate;
export const getSuccessUpdateConnectionId = (state) => state.legal.successUpdateConnectionId;
