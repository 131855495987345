import { serializeQueryObj } from '../functions';
import API from './APIService';

class ProductsAPI {
  getAllProducts = () => API.get('/api/Product/GetAll');
  getProducts = (query) => API.get(`/api/Product/Get?${serializeQueryObj(query)}`);
  getProductById = (productId) => API.get(`/api/v2/administrator/products/${productId}`);

  getRemainingMarcCodes = (productId) =>
    API.get(`/api/v2/administrator/products/${productId}/get-number-remaining-marking-codes`);

  // /api/v2/administrator/products/{id}
  getProductCatalogInfo = (productGtin) =>
    API.get(`/api/FeedProduct/GetFromGtin/?gtin=${productGtin}`);
  getProductsByLineId = (lineId) => API.get(`/api/productionlines/Products/${lineId}`);

  getAllBrands = () => API.get(`/api/Brand/GetAll`);

  getCategories = () => API.get('/api/Category/GetAll');
  getSubCategories = (categoryId) => API.get(`/api/Category/Subcategories/${categoryId}`);
  getCodesForProduct = (query) => API.get(`/api/MarkingCodes/Get?${serializeQueryObj(query)}`);

  getTypo = (query) => API.get(`/api/ServiceProvider/Get?${serializeQueryObj(query)}`);
  orderCodes = (data) => API.post('/api/Order/MarkingCodes/', { ...data });

  getOrders = (query) => API.get(`/api/Order/Get?${serializeQueryObj(query)}`);
  getOrderById = (query) => API.get(`/api/Order/{id}?${serializeQueryObj(query)}`);

  syncProducts = () => API.get(`/api/FeedProduct/SyncAll`);

  checkDigitalSignature = (id) => API.get(`/api/Token/Check?productId=${id}`);
  // *************
  setMinRemains = (productId, remains, certificateFields, autoCreateOrderParameters, productData) =>
    API.patch(`/api/v2/administrator/products/${productId}`, {
      minimumRemainderMarkingCodes: remains,
      certificateDocuments: certificateFields === null ? null : [certificateFields],
      autoCreateOrderParameters: autoCreateOrderParameters,
      productData
    });

  // PATCH /api/v2/administrator/products/{id}
  // ***************
  setSignedOrders = () => API.post(`/api/Order/SetMarkingCodesDB`, {});
  setGetReportStatus = () => API.get(`/api/Report/Status`, {});
  setSignedOrdersFull = () => API.post(`/api/Order/SetMarkingCodesSUZ`, {});

  printCodes = (data) => API.post(`/api/Printer/PrintCodes`, data);
  getAllProductsNoMarking = () => API.get('/api/Product/GetAllNoMarkingCodesRemainder');

  getPrintingParameters = (id) => API.get(`/api/printer/printing-parameters/?productGTIN=${id}`);
  getPrintingParamsAvailability = (gtin) =>
    API.get(`/api/printer/search-printing-config/?productGTIN=${gtin}`);
  setPrintingParameters = (data) => API.put('/api/printer/printing-codes-with-parameters', data);

  getAllBalances = () => API.get('/api/v2/administrator/balance/get-all-balance');
  updateMinBalances = (data) =>
    API.put('/api/v2/administrator/balance/update-minimum-balance', data);
  getProductChZBalance = (productId) =>
    API.get(`/api/v2/administrator/balance/get-balance-by-product?productId=${productId}`);

  checkIfLowBalance = () => API.get('/api/v2/administrator/balance/check-minimum-balance');

  // ======================= SIDE PRODUCTS START ==========================
  getAllProductOwners = () => API.get('/api/v2/administrator/product-owner/list');
  addProductOwner = (data) => API.post('/api/v2/administrator/product-owner', data);
  addSideProduct = (data) => API.post('/api/v2/administrator/product-owner/product', data);
  // ======================= SIDE PRODUCTS ENDS ==========================

  // ======================= WRITEOFF SETTINGS START ==========================
  getDays = () => API.get('/api/v2/administrator/settings/writing-off-parameters');
  sendDays = (days) =>
    API.post('/api/v2/administrator/settings/writing-off-parameters', { beforeShelfLife: days });

  // ======================= WRITEOFF SETTINGS END ==========================
}

export default new ProductsAPI();
