import React, { useContext, useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { navLinkGroups } from '../components/Menu/navLinkGroups';
import { pagesWithFilters } from '../constants';

const CrumbsContext = React.createContext();

export const useCrumbsContext = () => {
  return useContext(CrumbsContext);
};

export const CrumbsProvider = ({ children }) => {
  const [crumbs, setCrumbs] = useState([]);
  const [renderedCrumbs, setRenderedCrumbs] = useState([]);
  const [currentRenderedCrumb, setCurrentRenderedCrumb] = useState('');
  const [isFiltersDisplayed, setIsFiltersDisplayed] = useState(false);
  const location = useLocation();

  const resArr = [];
  const flatArr = (arr) => {
    for (let i = 0; i < arr.length; i++) {
      if (Array.isArray(arr[i].links)) {
        flatArr(arr[i].links);
      }
      resArr.push(arr[i]);
    }
    return resArr;
  };

  useEffect(() => {
    const newRenderedCrumbs = [];
    setCurrentRenderedCrumb(crumbs[crumbs.length - 1]);

    const flatLinks = flatArr(navLinkGroups[0].links);
    console.log('flatLinks', flatLinks);
    console.log('crumbs', crumbs);

    for (let i = 0; i < flatLinks.length; i++) {
      for (let j = 0; j < crumbs.length; j++) {
        if (flatLinks[i].key === crumbs[j]) {
          newRenderedCrumbs.push(flatLinks[i]);
          break;
        }
      }
    }

    setRenderedCrumbs(newRenderedCrumbs);
  }, [crumbs]);

  useEffect(() => {
    if (pagesWithFilters.includes(location.pathname)) {
      setIsFiltersDisplayed(true);
    } else {
      setIsFiltersDisplayed(false);
    }
  }, [location.pathname]);

  console.log('renderedCrumbs', renderedCrumbs);
  console.log('currentRenderedCrumb', currentRenderedCrumb);

  return (
    <CrumbsContext.Provider
      value={{
        crumbs,
        setCrumbs,
        renderedCrumbs,
        currentRenderedCrumb,
        isFiltersDisplayed,
        setIsFiltersDisplayed
      }}
    >
      {children}
    </CrumbsContext.Provider>
  );
};
