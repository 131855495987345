import { call, put, takeLatest } from 'redux-saga/effects';
import {
  setAggregationIncluded,
  REQUEST_AGGREGATION_INCLUDED,
  setErrorApp,
  setAllBalances,
  GET_ALL_BALANCES,
  SEND_MIN_BALANCES,
  setLoading,
  getAllBalances,
  GET_PRODUCT_BALANCE,
  GET_IS_DB,
  setProductChZBalance,
  CHECK_BALANCE,
  setLowBalance,
  checkIfLowBalance,
  setIsStillSendingBalance,
  SEND_GENERAL_SETTINGS,
  setGenSettings,
  setGlobSuccAlert,
  setIsDb
} from './appReducer';

import AggregatesAPI from 'api/AggregatesAPI';
import productsAPI from 'api/productsAPI';
import settingsAPI from 'api/settingsAPI';

function* getAggregationIncludedData(action) {
  try {
    const isAggregationIncluded = yield call(AggregatesAPI.getAggregationIncluded);

    yield put(setAggregationIncluded(isAggregationIncluded));
  } catch (e) {
    yield put(setErrorApp(e?.message));
  }
}

function* getAllChestZnackBalances(action) {
  try {
    yield put(setLoading(true));
    const balances = yield call(productsAPI.getAllBalances);
    yield put(setAllBalances(balances));
    yield put(setLoading(false));
  } catch (e) {
    yield put(setErrorApp(e?.message));
  }
}

function* getProductChestZnackBalances(action) {
  try {
    const balance = yield call(productsAPI.getProductChZBalance, action.payload);
    yield put(setProductChZBalance(balance));
  } catch (e) {
    yield put(setErrorApp(e?.message));
  }
}

function* sendMinimBalances(action) {
  try {
    yield put(setIsStillSendingBalance(true));
    yield put(setLoading(true));
    const res = yield call(productsAPI.updateMinBalances, action.payload);
    yield put(getAllBalances());
    yield put(checkIfLowBalance());
    yield put(setIsStillSendingBalance(false));
  } catch (e) {
    yield put(setErrorApp(e?.message));
  }
}

function* checkBalance() {
  try {
    yield put(setLoading(true));
    const res = yield call(productsAPI.checkIfLowBalance);
    yield put(setLowBalance(res?.result));
    yield put(setLoading(false));
  } catch (e) {
    yield put(setErrorApp(e?.message));
  }
}

function* sendGeneralSettings(action) {
  try {
    yield put(setLoading(true));
    const res = yield call(settingsAPI.setGeneralSettings, action.payload);
    if (typeof res === 'string') {
      yield put(setGenSettings(action.payload));
      yield put(
        setGlobSuccAlert({ title: 'Общие настройки', text: 'Настройки успешно сохранены' })
      );
    }

    yield put(setLoading(false));
  } catch (e) {
    yield put(setErrorApp(e?.message));
  }
}

function* requestIsDb() {
  try {
    yield put(setLoading(true));
    const res = yield call(settingsAPI.isDatabasePage);
    if ('pageActive' in res) {
      yield put(setIsDb(res.pageActive));
    }

    yield put(setLoading(false));
  } catch (e) {
    yield put(setErrorApp(e?.message));
  }
}

export function* AppSaga() {
  yield takeLatest(REQUEST_AGGREGATION_INCLUDED, getAggregationIncludedData);
  yield takeLatest(GET_ALL_BALANCES, getAllChestZnackBalances);
  yield takeLatest(SEND_MIN_BALANCES, sendMinimBalances);
  yield takeLatest(GET_PRODUCT_BALANCE, getProductChestZnackBalances);
  yield takeLatest(CHECK_BALANCE, checkBalance);
  yield takeLatest(SEND_GENERAL_SETTINGS, sendGeneralSettings);
  yield takeLatest(GET_IS_DB, requestIsDb);
}
