import React from 'react';
import { Loader } from 'components/Loader/Loader';

const EmtpyDiv = ({ isLoading, customStyle }) => (
  <div style={{ height: '4rem', ...customStyle }}>
    {isLoading && (
      <Loader
        style={{
          backgroundColor: 'transparent',
          marginTop: `${isLoading ? '2rem' : '0'}`
        }}
      />
    )}
  </div>
);

export default EmtpyDiv;
