import { Icon } from 'office-ui-fabric-react';
import React from 'react';
import { useSelector } from 'react-redux';
import { NavLink, useLocation } from 'react-router-dom';
import { useCrumbsContext } from '../../context/CrumbsContext';
import { getIsFilters } from '../../redux/App/appSelectors';
import filter_empt from '../../assets/img/filter_empty.svg';
import filter_filled from '../../assets/img/filter_filled.svg';
import s from './Crumbs.module.scss';

export const Crumbs = ({ setIsOpenFilters }) => {
  const { renderedCrumbs, currentRenderedCrumb, isFiltersDisplayed } = useCrumbsContext();
  const isFilters = useSelector(getIsFilters);
  const location = useLocation();

  return (
    <div className={s.container}>
      <div className={s.crumbs}>
        {renderedCrumbs.map((crumb, i) => {
          return (
            <React.Fragment key={i}>
              <NavLink
                className={s.crumb}
                to={{ pathname: `${crumb.key}`, state: { prevPath: location.pathname } }}
              >
                {crumb.name}
              </NavLink>
              <Icon className={s.crumbIcon} iconName="ChevronRight" />
            </React.Fragment>
          );
        })}
        <span className={s.curCrumb}>{currentRenderedCrumb}</span>
      </div>
      {isFiltersDisplayed && (
        <div className={s.filters}>
          <button className={s.filtersBtn} onClick={() => setIsOpenFilters(true)}>
            {/* <Icon className={s.filterIcon} iconName={isFilters ? 'FilterSolid' : 'Filter'} /> */}
            <img
              src={isFilters ? filter_filled : filter_empt}
              alt="filter icon"
              className={s.filterIcon}
            />
            <span className={s.filtersTitle}>Выбрать фильтры</span>
          </button>
        </div>
      )}
    </div>
  );
};
