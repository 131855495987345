import { applyMiddleware, compose, createStore } from 'redux';
import createSagaMiddleware from 'redux-saga';
import rootSaga from './rootSaga';
import { rootReducer } from './store';
import thunk from 'redux-thunk';
import reduxPromiseMiddleware from 'redux-promise-middleware';

const saga = createSagaMiddleware();

//const middleWares = [saga, thunk.default];

const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;
// const composeEnhancers = compose
const middleware = applyMiddleware(reduxPromiseMiddleware, saga, thunk);
//const store = createStore(reducer, middleware);

export const store = createStore(rootReducer, middleware);

saga.run(rootSaga);
