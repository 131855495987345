import { Dropdown, Modal, ComboBox } from 'office-ui-fabric-react';
import React, { useEffect, useState } from 'react';
import ProductsAPI from '../../api/productsAPI';
import StorageAPI from '../../api/storageAPI';
import UsersAPI from '../../api/usersAPI';
import {
  findIntersectionOfArrays,
  mapItemsForDropdown,
  mapProductsForDropdown
} from '../../functions';
import { BottomButtons } from '../BottomButtons/BottomButtons';
import { MiniLoader } from '../MiniLoader/MiniLoader';
import { mapToArray, arrayToString } from 'utils/methods';
import s from './AddItemLineModal.module.scss';
import { string } from 'yup';

const modalConfig = {
  product: {
    headerTitle: 'Добавить продукт',
    selectTitle: 'Выберите продукт из списка'
  },
  storage: {
    headerTitle: 'Добавить склад',
    selectTitle: 'Выберите склад из списка'
  },
  user: {
    headerTitle: 'Добавить пользователя',
    selectTitle: 'Выберите пользователя из списка'
  }
};

export const AddItemLineModal = ({
  isOpened,
  selectedItems,
  setIsOpened,
  type,
  onClose,
  onSubmit
}) => {
  const [selectedItem, setSelectedItem] = useState(null);
  const [items, setItems] = useState([]);
  const [filteredItems, setFilteredItems] = useState(null);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    setLoading(true);
    if (type === 'product') {
      ProductsAPI.getAllProducts().then((res) => {
        setItems(
          findIntersectionOfArrays(
            mapProductsForDropdown(res),
            mapProductsForDropdown(selectedItems)
          )
        );
        setLoading(false);
      });
    }

    if (type === 'storage') {
      StorageAPI.getAllStorage().then((res) => {
        setItems(
          findIntersectionOfArrays(mapItemsForDropdown(res), mapItemsForDropdown(selectedItems))
        );
        setLoading(false);
      });
    }

    if (type === 'user') {
      UsersAPI.getAllUsers().then((res) => {
        setItems(
          findIntersectionOfArrays(mapUsersForDropdown(res), mapUsersForDropdown(selectedItems))
        );
        setLoading(false);
      });
    }
  }, []);

  const mapUsersForDropdown = (items) => {
    if (items) {
      return items.map((item) => {
        const roles = mapToArray(item?.roles, 'name');
        const rolesString = arrayToString(roles, ', ');
        return {
          text: `${item?.surname} ${item?.firstName} ${item?.patronymic} (${item?.userName}) (${rolesString})`,
          label: `${item?.surname} ${item?.firstName} ${item?.patronymic} (${item?.userName}) (${rolesString})`,
          key: item.id,
          firstName: item.firstName,
          surname: item.surname,
          patronymic: item.patronymic,
          roles: item.roles,
          userName: item.userName,
          id: item.id
        };
      });
    }
  };

  return (
    <Modal
      isOpen={isOpened}
      onDismiss={() => setIsOpened(false)}
      isBlocking={false}
      containerClassName={s.container}
    >
      <div className={s.header}>
        <span className={s.title}>{modalConfig[type].headerTitle}</span>
      </div>
      {!loading ? (
        <div className={s.body}>
          <ComboBox
            openOnKeyboardFocus
            allowFreeform
            label={modalConfig[type].selectTitle}
            selectedKey={selectedItem?.key}
            options={filteredItems || items}
            onChange={(e, item) => {
              setSelectedItem(item);
            }}
            onPendingValueChanged={(option, pendingIndex, pendingValue) => {
              if (pendingValue !== undefined) {
                setFilteredItems((p) => {
                  const filtered = items.filter((item) => {
                    const str = item.text.toLowerCase();
                    const substr = pendingValue.toLowerCase();
                    return str.includes(substr);
                  });

                  if (filtered?.length && filtered.length !== items.length) return filtered;
                  else return null;
                });
              }
            }}
          />
          <div className={s.buttons}>
            <BottomButtons
              okText="Добавить"
              closeText="Назад"
              disabledButtons={[!selectedItem]}
              closeHandler={onClose}
              okHandler={() => onSubmit(type, selectedItem)}
            />
          </div>
        </div>
      ) : (
        <MiniLoader />
      )}
    </Modal>
  );
};
