export const createMethodTypes = [
  {
    key: 'SELF_MADE',
    text: 'Самостоятельно'
  },
  {
    key: 'CEM',
    text: 'ЦЭМ'
  },
  {
    key: 'CM',
    text: 'Контрактное производство'
  },
  {
    key: 'CL',
    text: 'Логистический склад'
  },
  {
    key: 'CA',
    text: 'Комиссионная площадка'
  }
];

export const servicesProviderRoles = {
  SELF_MADE: 0,
  CEM: 1,
  CM: 2,
  CL: 3,
  CA: 4
};

export const releaseMethodTypes = [
  { key: 'PRODUCTION', text: 'Производство в РФ' },
  { key: 'IMPORT', text: 'Ввезен в РФ (Импорт)' },
  { key: 'REMAINS', text: 'Маркировка остатков' },
  { key: 'REMARK', text: 'Перемаркировка' },
  { key: 'COMMISSION', text: 'Принят на комиссию от физического лица' }
];

export const paymentTypes = [
  { key: 'EMISSION', text: 'Оплата по эмиссии' },
  { key: 'UTILISATION', text: 'Оплата по нанесению' }
];

export const cisTypes = [
  { key: 'UNIT', text: 'Единица товара' },
  { key: 'BUNDLE', text: 'Комплект' },
  { key: 'GROUP', text: 'Групповая потребительская упаковка' },
  { key: 'SET', text: 'Набор' }
];

export const mapServiceProvidersForDropdown = (items) => {
  return items?.map((item) => ({
    text: item.name,
    key: item.serviceProviderId,
    role: item.role
  }));
};

export const onFormChange = (setterFunc, setFormErrors, fieldName, value) => {
  if (fieldName === 'active') {
    setterFunc((p) => {
      return {
        ...p,
        active: typeof p.active === 'boolean' ? !p?.active : true
      };
    });
  } else if (
    fieldName === 'serviceProviderId' ||
    fieldName === 'releaseMethodType' ||
    fieldName === 'paymentType' ||
    fieldName === 'cisType'
  ) {
    setFormErrors((p) => ({ ...p, [fieldName]: '' }));
    setterFunc((p) => ({ ...p, [fieldName]: value?.key }));
  } else {
    setFormErrors((p) => {
      return { ...p, [fieldName]: '' };
    });
    setterFunc((p) => ({ ...p, [fieldName]: value }));
  }
};
