import {Dropdown, Stack} from 'office-ui-fabric-react'
import React, {useEffect, useState} from 'react'
import UsersAPI from '../../../api/usersAPI'
import {mapItemsForDropdown} from '../../../functions'
import {FilterButtons} from '../FilterButtons/FilterButtons'
import {setFilteredData} from 'redux/App/appReducer'
import { useDispatch } from 'react-redux';

export const UsersFilter = ({query, saveValues, setIsOpenFilters, setQuery}) => {
    const [rolesForFilter, setRolesForFilter] = useState([])
    const [rawRoles, setRawRoles] = useState([])
    const [_role, _setRole] = useState(query.roleName)
    const [selectedRole, setSelectedRole] = useState(null)
    const dispatch = useDispatch()

    useEffect(() => {
        UsersAPI.getRoles().then(res => {
            if (res) {
                setRawRoles(res)
                setRolesForFilter(mapItemsForDropdown(res))

                const isSelectedRole = res.find(item => item.name === query.roleName)

                if (isSelectedRole) {
                    _setRole(isSelectedRole.id)
                }
            }
        })
    }, [])

    useEffect(() => {
        const rawRole = rawRoles.find(item => item.id === _role)
        setSelectedRole(rawRole)

    }, [_role])

    const onConfirm = () => {
        const filterObj = {roleName: selectedRole?.name, pageNumber: 1};        
        setIsOpenFilters(false)
        setQuery(prev => ({...prev, ...filterObj}), 'replace')
        dispatch(setFilteredData(filterObj));
    }

    const onDismiss = () => {
        _setRole(null)
       const filterObj = {roleName: undefined}
        setQuery(prev => ({...prev, ...filterObj}), 'replace')
        dispatch(setFilteredData(filterObj));
        setIsOpenFilters(false)
    }

    const changeFilterValue = (value, fieldName) =>  _setRole(value);

    return (
        <Stack tokens={{childrenGap: 25}}>
            {rolesForFilter && <Dropdown
                label="Роль"
                selectedKey={_role}
                options={rolesForFilter}
                onChange={(_, item) => changeFilterValue(item.key, 'role')}
            />}
            <div>
                <FilterButtons onSubmit={onConfirm} onDismiss={onDismiss}/>
            </div>
        </Stack>
    )
}
