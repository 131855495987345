import { useLayoutEffect } from 'react';
import { Field, useField } from 'formik';
import s from './Expiration.module.scss';
import ComboSelect from 'components/Forms/customSelect/comboSelect';

const customStyles = {
  ///.....
  control: (provided, state) => ({
    ...provided,
    background: '#fff',
    borderColor: '#9e9e9e',
    minHeight: '36px',
    height: '2.2rem',
    display: 'flex',
    alignItems: 'center',
    marginTop: '1rem',
    border: state.focused ? '1px solid blue' : '1px solid rgb(50, 49, 48)',
    borderRadius: '0'
  }),
  menuPortal: (provided) => ({ ...provided, zIndex: 19999 }),
  menu: (provided) => ({ ...provided, zIndex: 19999 }),
  indicatorSeparator: () => null,
  valueContainer: (provided) => ({
    ...provided,
    paddingBottom: '0.1rem',
    color: 'rgb(50, 49, 48)'
  }),
  placeholder: (provided) => ({ ...provided, paddingBottom: '0.1rem', color: 'rgb(50, 49, 48)' })
  ///.....
};

const CompanyField = ({ companies }) => {
  const [companyIdField, __, companyHelpers] = useField('companyId');
  const newCompanies = companies.map((comp) => ({ value: comp.key, label: comp.text }));
  const onChange = (arg) => companyHelpers.setValue(arg.value);

  useLayoutEffect(() => {
    if (!companyIdField.value) {
      companyHelpers.setValue(newCompanies[0]?.value);
    }
  }, []);

  return (
    <section className={s.expiration}>
      <Field name={'companyId'}>
        {(props) => {
          return (
            <>
              <ComboSelect
                options={newCompanies}
                onChange={onChange}
                label="Организация"
                placeholder={
                  newCompanies.find((comp) => comp.value === companyIdField.value)?.label || ''
                }
              />
            </>
          );
        }}
      </Field>
    </section>
  );
};

export default CompanyField;
