import {serializeQueryObj} from '../functions'
import API from './APIService'

class StorageAPI {
    getAllStorage = () => API.get('/api/storage/all')
    getStorage = (query) => API.get(`/api/storage/allpages?${serializeQueryObj(query)}`)
}


export default new StorageAPI()
