import s from './ProductsCard.module.scss';
import { sepThousands } from '../core';

const periodsInfo = [
  {
    nameRus: '1-5 дней:',
    nameVar: 'remainingRange1',
    data: ''
  },
  {
    nameRus: '5-10 дней:',
    nameVar: 'remainingRange2',
    data: ''
  },
  {
    nameRus: '10-15 дней:',
    nameVar: 'remainingRange3',
    data: ''
  },
  {
    nameRus: 'Более 15 дней:',
    nameVar: 'remainingRange4',
    data: ''
  }
];
const RemainingQuantMarcCodes = ({ data }) => {
  return (
    <main className={s.markCodeWrapper}>
      <div className={s.markeingCodesHeadLine}>До истечения срока годности:</div>
      <section className={s.periodsWrapper}>
        {periodsInfo.map((p) => (
          <div className={s.singleOptionRow}>{`${p.nameRus} ${sepThousands(data[p.nameVar])}`}</div>
        ))}
      </section>
    </main>
  );
};

export default RemainingQuantMarcCodes;
