import { memo, useState } from 'react';
import { Field } from 'formik';
import { Dropdown } from 'office-ui-fabric-react';

const dummyArr = [
  { key: 0, text: 'one' },
  { key: 1, text: 'two' }
];

const CustomSelect = ({ name, label, onChange, selectOptions }) => {
  return (
    <Field name={name}>
      {(props) => {
        const { field, form } = props;

        return (
          <Dropdown
            label={label}
            selectedKey={dummyArr[1].key}
            options={dummyArr}
            onChange={onChange}
          />
        );
      }}
    </Field>
  );
};

export default memo(CustomSelect);
