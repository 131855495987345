import { SearchBox } from 'office-ui-fabric-react'
import React, { useEffect, useState } from 'react'
import { NumberParam, StringParam, useQueryParams } from 'use-query-params'
import LinesAPI from '../../api/linesAPI'
import g from '../../assets/scss/Main.module.scss'
import { LinesFilter } from '../../components/Filters/LinesFilter/LinesFilter'
import { FiltersPanel } from '../../components/FiltersPanel/FiltersPanel'
import { Pagination } from '../../components/Pagination/Pagination'
import { Table } from '../../components/Table/Table'
import { TableAction } from '../../components/TableAction/TableAction'
import { addIcon, moreInfoIcon, searchBoxStyles } from '../../constants'
import { useCrumbsContext } from '../../context/CrumbsContext'
import { formatLineStatus, cellWidth } from '../../functions'
import { usePaginationAndSearch, useDataFilter } from '../../hooks'
import s from './Lines.module.scss'

const tableHeader = [
    {
        title: 'Название',
        width: '0.3'
    },
    {
        title: 'Статус',
        width: '0.25'
    },
    {
        title: 'Продукт',
        width: '0.3'
    },
    {
        title: 'Действия',
        width: '0.15'
    }
]

export const Lines = ({ setIsOpenFilters, isOpenFilters }) => {    
    const [lines, setLines] = useState([])
    const [loading, setLoading] = useState(false)
    const [query, setQuery] = useDataFilter('lines')


    const getLines = async (query) => {
        setLoading(true)
        let res = await LinesAPI.getLines(query)
        const { list, quantity } = res
         setLines(list)
         setTotalItems(quantity)
         setLoading(false)
    }

    const { onPageChanged, onSearchTermChanged, totalPages, setTotalItems, currentPage } = usePaginationAndSearch({ callback: getLines, query, setQuery })

    return (
        <div>
            <div className={g.titleWrapper}>
                <h1 className={g.title}>Производственные линии</h1>
                <SearchBox
                    styles={searchBoxStyles}
                    value={query.searchValue}
                    placeholder="Поиск по названию"
                    onClear={() => {
                        onSearchTermChanged('')
                    }}
                    onSearch={newValue => {
                        onSearchTermChanged(newValue)
                    }}
                />
            </div>
            <Table headerItems={tableHeader} maxHeight='67vh' loading={loading}>
                {!!lines.length && lines.map(item => {
                    return <div key={item.id} className={g.tableItem}>
                        <div className={g.tableCell} style={{ width: cellWidth(tableHeader, 0), justifyContent: 'flex-start', marginLeft: '2rem'}}>{`${item?.name} (№${item?.number})`}</div>
                        <div className={g.tableCell}
                            style={{ width: cellWidth(tableHeader, 1), ...formatLineStatus(item.status).style }}>{formatLineStatus(item.status).status}</div>
                        <div className={g.tableCell} style={{ width: cellWidth(tableHeader, 2) }}>{item?.selectedProductName}</div>
                        <div className={s.tableActionCell} style={{ width: cellWidth(tableHeader, 3) }} >
                            {item.status === 'STOP' &&
                                <TableAction link={`/lines/${item.id}`} iconName={moreInfoIcon} text='Подробнее' />}
                        </div>
                    </div>
                })}
            </Table>

            <div className={g.footer}>
                {totalPages > 1 &&
                    <Pagination pageCount={totalPages} onPageChange={onPageChanged} selectedPage={currentPage} />}
                <TableAction iconName={addIcon} link={`lines/add`} text='Добавить линию' />
            </div>
            <FiltersPanel isOpenFilters={isOpenFilters}
                setIsOpenFilters={setIsOpenFilters}
                query={query}
                setQuery={setQuery}>
                <LinesFilter />
            </FiltersPanel>
        </div>
    )
}
