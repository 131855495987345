import { serializeQueryObj } from '../functions';

import API, { API2 } from './APIService';

//const api = new API2();

class LegalAPI {
  getCompanies = (query) => {
    return API.get(`/api/config/getCompanies?${serializeQueryObj(query)}`);
  };
  getCompanies2 = (isActive = true, isArchived = true) =>
    API.get(`/api/v2/administrator/company/list?isActive=${isActive}&isArchived=${isArchived}`);
  getCompanyById = (id) => API.get(`/api/config/getCompany?companyid=${id}`);
  getCompanyById2 = (id) => API.get(`/api/v2/administrator/company/${id}`);
  getSuzById = (id) => API.get(`/api/config/getsuz?apisuzId=${id}`);
  getConnectionId = (companyId) => API.post(`/api/Integration/${companyId}`);

  // update
  putCompany = (companyId, data) => API.put(`/api/v2/administrator/company/${companyId}`, data);

  putCatalog = (data) => API.put('/api/config/updatenatcat', data);
  putGismt = (data) => API.put('/api/config/updategismt', data);
  putSuz = (data) => API.put('/api/config/updatesuz', data);

  addCompany = (data) => API.post('/api/v2/administrator/company', data);
}

export default new LegalAPI();
