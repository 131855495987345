import { Icon } from 'office-ui-fabric-react';
import React from 'react';
import ReactPaginate from 'react-paginate';
import s from './Pagination.module.scss';
import chevron_left from '../../assets/img/ChevronLeft.svg';
import chevron_right from '../../assets/img/ChevronRight.svg';

export const Pagination = ({ pageCount, onPageChange, selectedPage }) => {
  const handlePageClick = (data) => {
    let selectedPage = data.selected + 1;
    onPageChange(selectedPage);
  };

  return (
    <ReactPaginate
      previousLabel={<img src={chevron_left} alt="chevron left icon" className={s.chevronIcons} />}
      nextLabel={<img src={chevron_right} alt="chevron left icon" className={s.chevronIcons} />}
      breakLabel={'...'}
      breakClassName={'break-me'}
      pageCount={pageCount}
      marginPagesDisplayed={1}
      pageRangeDisplayed={4}
      forcePage={selectedPage - 1}
      initialPage={selectedPage - 1}
      onPageChange={handlePageClick}
      containerClassName={s.pagination}
      subContainerClassName={'pages pagination'}
      activeClassName={s.paginationActivePage}
    />
  );
};

export const ProductPagination = ({ pageCount, onPageChange, selectedPage }) => {
  const handlePageClick = (data) => {
    let selectedPage = data.selected + 1;
    onPageChange(selectedPage);
  };

  return (
    <ReactPaginate
      previousLabel={<img src={chevron_left} alt="chevron left icon" className={s.chevronIcons} />}
      nextLabel={<img src={chevron_right} alt="chevron left icon" className={s.chevronIcons} />}
      breakLabel={null}
      // breakClassName={'break-me'}
      pageCount={pageCount}
      marginPagesDisplayed={null}
      pageRangeDisplayed={2}
      forcePage={selectedPage - 1}
      initialPage={selectedPage - 1}
      onPageChange={handlePageClick}
      containerClassName={s.pagination}
      subContainerClassName={'pages pagination'}
      activeClassName={s.paginationActivePage}
    />
  );
};
