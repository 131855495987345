import { PrimaryButton, Stack, TextField } from 'office-ui-fabric-react';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { GrayPanel } from '../../components/GrayPanel/GrayPanel';
import { Loader } from '../../components/Loader/Loader';
import { getGlobalErrorText } from '../../redux/App/appSelectors';
import { loginAC, setErrorAC } from '../../redux/Auth/authReducer';
import { getAuthError, getIsAuthLoading } from '../../redux/Auth/authSelectors';
import s from './Auth.module.scss';

export const Auth = () => {
  const [username, setLogin] = useState('');
  const [password, setPassword] = useState('');

  const error = useSelector(getAuthError);
  const loading = useSelector(getIsAuthLoading);
  const globalErrorText = useSelector(getGlobalErrorText);
  const dispatch = useDispatch();

  useEffect(() => {
    if (globalErrorText) {
      setErrorAC(globalErrorText);
    }
  }, [globalErrorText]);

  const _login = (e) => {
    e.preventDefault();
    dispatch(loginAC({ username, password }));
  };

  return (
    <div className={s.auth}>
      <svg
        width="120"
        height="120"
        viewBox="0 0 120 120"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <rect width="120" height="120" rx="25" fill="#5589FF" />
        <rect x="32.3018" y="30" width="13.8135" height="14.4995" fill="#34DB93" />
        <rect x="46.1162" y="44.4983" width="13.8136" height="14.4995" fill="#FDFFFF" />
        <rect x="59.9277" y="59.002" width="13.8136" height="14.4994" fill="#FDFFFF" />
        <rect x="46.1113" y="59.002" width="13.8136" height="14.4994" fill="#FDFFFF" />
        <rect x="32.3018" y="73.5" width="13.8135" height="14.4995" fill="#FDFFFF" />
        <rect x="73.748" y="30" width="13.8135" height="14.4995" fill="#FDFFFF" />
        <rect x="73.748" y="73.5005" width="13.8135" height="14.4995" fill="#FDFFFF" />
      </svg>

      <span className={s.logoCompanyName}>HI-MARK</span>
      <div className={s.pageDescription}>Административный модуль управления маркировкой</div>
      <form className={s.form} onSubmit={_login}>
        <main className={s.inputContainer}>
          <TextField label="Логин" onChange={(e) => setLogin(e.target.value)} />
          <TextField
            label="Пароль"
            type="password"
            value={password}
            onChange={(e) => setPassword(e.target.value)}
          />
          <div className={s.footer}>
            {/* <span className={s.error}>{error}</span> */}
            <PrimaryButton text="Войти" type="submit" className={s.btn} onClick={_login} />
          </div>
        </main>

        {loading && <Loader />}
      </form>
    </div>
  );
};
