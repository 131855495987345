import { memo, useCallback, useEffect, useLayoutEffect, useMemo, useState } from 'react';
import {
  TextField,
  Checkbox,
  Dropdown,
  PrimaryButton,
  Modal,
  DefaultButton
} from 'office-ui-fabric-react';
import sMain from '../ProductsCard.module.scss';
import s from './AutoOrder.module.scss';
import productAPI from 'api/productsAPI';
import {
  mapServiceProvidersForDropdown,
  createMethodTypes,
  releaseMethodTypes,
  paymentTypes,
  cisTypes,
  onFormChange
} from './AutoCreateOrders.constants';
import { Loader } from 'components/Loader/Loader';
// import { providersResponse } from './mockData';

const AutoCreateOrderFields = ({ initFormState, setterFunc, productId }) => {
  const [isOpenModal, setIsOpenModal] = useState(false);

  const openModal = () => {
    setIsOpenModal(true);
  };

  const closeModal = () => {
    setIsOpenModal(false);
  };

  return (
    <main className={s.autocreateOrderWrapper}>
      <PrimaryButton
        disabled={false}
        className={s.preliminaryButton}
        text="Автоматический заказ КМ"
        onClick={openModal}
        style={{ marginTop: 30, width: '100%' }}
      />

      {isOpenModal && (
        <AutoCreateOrdersModal
          initState={initFormState}
          setterFunc={setterFunc}
          onDismiss={closeModal}
          isOpen={isOpenModal}
          productId={productId}
        />
      )}
    </main>
  );
};

export default memo(AutoCreateOrderFields);

const AutoCreateOrdersModal = ({ initState, setterFunc, onDismiss, isOpen, productId }) => {
  const [isLoading, setLoading] = useState(false);
  const [autoOrderParams, setAutoOrderParams] = useState(initState);
  const [autoOrderErrors, setErrors] = useState({});
  const [providerServicesForDropdown, setProviderServicesForDropdown] = useState([
    { key: '', text: 'Не выбрано', role: 'SELF_MADE' }
  ]);

  const shouldDisable = () => {
    let res = false;
    const arr = Object.values(autoOrderErrors);
    arr.forEach((mes) => {
      if (!!mes) res = true;
    });
    return res;
  };

  const saveValuesOnExit = () => {
    setterFunc(autoOrderParams);
    onDismiss();
  };

  const getTypos = async (productId) => {
    setLoading(true);
    const res = await productAPI.getTypo({ productId });
    const providers = mapServiceProvidersForDropdown(res.providers) || [];
    setProviderServicesForDropdown((prev) => [...prev, ...providers]);
    setLoading(false);
  };

  useEffect(() => {
    if (autoOrderParams?.active && autoOrderParams?.quantity <= 0) {
      setErrors((p) => ({
        ...p,
        quantity: 'Данное поле может принимать значения только больше 0'
      }));
      onFormChange(setAutoOrderParams, setErrors, 'quantity', 1);
    }

    if (autoOrderParams?.active && autoOrderParams?.minimumRemainder < 0) {
      onFormChange(setAutoOrderParams, setErrors, 'minimumRemainder', 0);
    }

    if (autoOrderParams?.active && autoOrderParams?.quantity > 150000) {
      setErrors((p) => ({
        ...p,
        quantity: 'Данное поле может принимать значения 1 - 150 000'
      }));
    }
  }, [autoOrderParams?.active, autoOrderParams?.quantity, autoOrderParams?.minimumRemainder]);

  useEffect(() => {
    getTypos(productId);
  }, [productId]);

  useEffect(() => {
    const key = autoOrderParams?.serviceProviderId;
    const role = providerServicesForDropdown.find((prov) => prov.key === key)?.role;
    onFormChange(setAutoOrderParams, setErrors, 'createMethodType', role);
  }, [autoOrderParams?.serviceProviderId, providerServicesForDropdown.length]);

  return (
    <Modal containerClassName={s.modal} isOpen={isOpen} onDismiss={onDismiss} isBlocking={false}>
      {isLoading ? (
        <Loader />
      ) : (
        <>
          <div className={s.singleCheckbox}>
            <Checkbox
              onChange={() =>
                onFormChange(setAutoOrderParams, setErrors, 'active', !autoOrderParams.active)
              }
              disabled={false}
              checked={autoOrderParams?.active}
            />
            <span className={s.checkboxText}>{'Автоматически заказывать КМ'}</span>
          </div>

          <section className={s.texFieldsWrapper}>
            <TextField
              key={'Заказывать при остатке'}
              label="Заказывать при остатке"
              required
              disabled={!autoOrderParams?.active}
              value={autoOrderParams?.minimumRemainder}
              type="number"
              onWheel={(e) => e.target.blur()}
              onChange={(e) =>
                onFormChange(setAutoOrderParams, setErrors, 'minimumRemainder', +e.target.value)
              }
              errorMessage={autoOrderErrors.minimumRemainder}
            />
            <TextField
              key={'Количество КМ в заказе'}
              label="Количество КМ в заказе"
              required
              disabled={!autoOrderParams?.active}
              value={autoOrderParams?.quantity}
              type="number"
              onWheel={(e) => e.target.blur()}
              onChange={(e) =>
                onFormChange(setAutoOrderParams, setErrors, 'quantity', +e.target.value)
              }
              errorMessage={autoOrderErrors?.quantity}
            />
            <Dropdown
              className={s.rowDropdown}
              label="Сервис-провайдер"
              selectedKey={autoOrderParams?.serviceProviderId || providerServicesForDropdown[0].key}
              onChange={(_, item) =>
                onFormChange(setAutoOrderParams, setErrors, 'serviceProviderId', item)
              }
              options={providerServicesForDropdown}
              disabled={!autoOrderParams?.active}
            />

            <Dropdown
              className={s.rowDropdown}
              label="Способ изготовления"
              selectedKey={autoOrderParams?.createMethodType || createMethodTypes[0].key}
              onChange={(_, item) =>
                onFormChange(setAutoOrderParams, setErrors, 'createMethodType', item)
              }
              options={createMethodTypes}
              disabled
            />

            <Dropdown
              className={s.rowDropdown}
              label="Способ выпуска товаров в оборот"
              selectedKey={autoOrderParams?.releaseMethodType || releaseMethodTypes[0].key}
              onChange={(_, item) =>
                onFormChange(setAutoOrderParams, setErrors, 'releaseMethodType', item)
              }
              options={releaseMethodTypes}
              disabled
            />
            <Dropdown
              className={s.rowDropdown}
              label="Тип оплаты"
              selectedKey={autoOrderParams?.paymentType || paymentTypes[0].key}
              onChange={(_, item) =>
                onFormChange(setAutoOrderParams, setErrors, 'paymentType', item)
              }
              options={paymentTypes}
              disabled
            />
            <Dropdown
              className={s.rowDropdown}
              label="Тип кода маркировки"
              selectedKey={autoOrderParams?.cisType || cisTypes[0].key}
              onChange={(_, item) => onFormChange(setAutoOrderParams, setErrors, 'cisType', item)}
              options={cisTypes}
              disabled
            />
            <div className={s.buttons}>
              <PrimaryButton
                disabled={isLoading || shouldDisable()}
                text="Сохранить"
                style={{ flexGrow: 1 }}
                onClick={saveValuesOnExit}
              />
              <DefaultButton
                text="Отмена"
                style={{ marginLeft: '10px', flexGrow: 1 }}
                onClick={onDismiss}
              />
            </div>
          </section>
        </>
      )}
    </Modal>
  );
};
