import React, { useEffect, useState, useMemo, useCallback } from 'react';
import { useQueryParams, NumberParam, StringParam } from 'use-query-params';
import s from './index.module.scss';
import g from '../../assets/scss/Main.module.scss';
import { SearchBox } from 'office-ui-fabric-react';
import { moreInfoIcon, searchBoxStyles } from '../../constants';
import { Table } from '../../components/Table/Table';
import { TableAction } from '../../components/TableAction/TableAction';
import { Pagination } from '../../components/Pagination/Pagination';
import { useCrumbsContext } from '../../context/CrumbsContext';
import LegalAPI from '../../api/legalAPI';
import { usePaginationAndSearch } from '../../hooks';
import { Checkbox } from 'office-ui-fabric-react';
import { addIcon } from '../../constants';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min';
import { getCompanyInfo } from 'redux/LegalFaces/legalFacesSelectors';
import clsx from 'clsx';
import legalAPI from '../../api/legalAPI';
import { setCompanyAC, setErrorAC } from 'redux/LegalFaces/legalFacesReducer';

const tableHeader = [
  {
    title: 'Название',
    width: '0.4'
  },
  {
    title: 'ИНН',
    width: '0.3'
  },
  {
    title: 'Количество продуктов',
    width: '0.15'
  },
  {
    title: 'Действия',
    width: '0.15'
  }
];

const LegalFacesPage = () => {
  const { setCrumbs } = useCrumbsContext();
  const [legalFaces, setLegalFaces] = useState([]);
  const [loading, setLoading] = useState(false);
  const [query, setQuery] = useQueryParams({
    pageNumber: NumberParam,
    searchValue: StringParam,
    quantityOnPage: NumberParam,
    roleName: StringParam
  });
  const dispatch = useDispatch()
  const {id:companyId} = useSelector(getCompanyInfo)
  const history = useHistory()

  const [isActive, setIsActive] = useState(true);
  const [isArchived, setIsArchived] = useState(false);

  const onToggleBox = useCallback(
    (isActive, isArchived, type) => {
      if (type === 'isActive') {
        setIsActive((p) => !p);
        getLegalFaces(!isActive, isArchived);
      } else {
        setIsArchived((p) => !p);
        getLegalFaces(isActive, !isArchived);
      }
    },
    [isActive, isArchived]
  );

  const checkboxes = useMemo(
    () => [
      {
        title: 'Активные',
        variableName: 'isActive',
        value: isActive,
        onChange: () => onToggleBox(isActive, isArchived, 'isActive')
      },
      {
        title: 'Архивные',
        variableName: 'isArchived',
        value: isArchived,
        onChange: () => onToggleBox(isActive, isArchived, 'isArchived')
      }
    ],
    [isActive, isArchived]
  );

  useEffect(() => {
    setCrumbs([]);
    setQuery(query, 'replace');
  }, []);

  const getLegalFaces = async (isActive, isArchived) => {
    setLoading(true);
    //let res = await LegalAPI.getCompanies(query);
    let res = await LegalAPI.getCompanies2(isActive, isArchived);
    setLegalFaces(res);
    setLoading(false);
  };

  const { onPageChanged, onSearchTermChanged, totalPages, currentPage } = usePaginationAndSearch({
    callback: () => getLegalFaces(isActive, isArchived),
    query,
    setQuery
  });

  const handleDetailsClick = async (id) => {
    setLoading(true)
    try {
      const legalFace = await legalAPI.getCompanyById2(id);
      dispatch(setCompanyAC(legalFace)); 
      history.push(`/legal/${legalFace.id}`)
    }catch (e) {
      dispatch(setErrorAC(e?.message))
    }
   setLoading(false)
  }

  return (
    <div>
      <div className={g.titleWrapper}>
        <h1 className={g.title}>Юридические лица</h1>
        <SearchBox
          styles={searchBoxStyles}
          placeholder="Поиск по Названию компании"
          value={query.searchValue}
          onClear={() => {
            onSearchTermChanged('');
          }}
          onSearch={(newValue) => onSearchTermChanged(newValue)}
        />
      </div>
      <div className={s.checkBoxWrapper}>
        {checkboxes.map((option) => (
          <div className={s.singleCheckbox}>
            <Checkbox onChange={option.onChange} disabled={false} checked={option.value} />
            <span className={s.checkboxText}>{option.title}</span>
          </div>
        ))}
      </div>
      <Table headerItems={tableHeader} maxHeight="50vh" loading={loading}>
        {legalFaces?.map((item) => {
          return (
            <div key={item.id} className={g.tableItem}>
              <div
                className={g.tableCell}
                style={{ width: `calc((100vw - 180px - 3.75rem) * ${tableHeader[0].width})` }}
              >
                {item.name}
              </div>
              <div
                className={g.tableCell}
                style={{ width: `calc((100vw - 180px - 3.75rem) * ${tableHeader[1].width})` }}
              >
                {item.inn}
              </div>
              <div
                className={g.tableCell}
                style={{ width: `calc((100vw - 180px - 3.75rem) * ${tableHeader[2].width})` }}
              >
                {item.productNumber}
              </div>
              <div
                className={clsx(g.tableCell, `${s.productsActionsItems}`)}
                style={{ width: `calc((100vw - 180px - 3.75rem) * ${tableHeader[3].width})` }}
              >
                <TableAction 
                iconName={moreInfoIcon} 
                text="Подробнее" 
                onClick={()=>handleDetailsClick(item.id)} 
                isButton />
              </div>
            </div>
          );
        })}
      </Table>
      <div className={g.footer}>
        {totalPages > 1 && (
          <Pagination
            pageCount={totalPages}
            onPageChange={onPageChanged}
            selectedPage={currentPage}
          />
        )}
        <TableAction iconName={addIcon} link={`legal/add`} text="Добавить юрлицо" />
      </div>
    </div>
  );
};

export default LegalFacesPage;
