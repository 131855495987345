import React from 'react';
import s from '../index.module.scss';
import Input from '../../../components/Forms/Input';
import SwitchCheckbox from '../../../components/Forms/Toggle';
import CertificatesField from './certificates/Certificates.field';

const GismtConfig = ({ isAdding = false }) => {
  return (
    <div className={s.gismt}>
      {/* <h3 className={s.title}>Конфигурация ГИС МТ</h3> */}

      {!isAdding && <CertificatesField />}
      {/* <Input name="thumbPrint" type="text" label="Отпечаток ЭЦП" /> */}

      {/* <SwitchCheckbox name="activeGisMt" label="Активность" />

      <Input name="gisDevelopmentApiUrl" type="text" label="Тестовый адрес API" disabled />
      <Input name="gisProductionApiUrl" type="text" label="Боевой адрес API" disabled />

      <SwitchCheckbox
        name="gisSelectedProductionApiUrl"
        label="Выбор API"
        onText="Боевой адрес API"
        offText="Тестовый адрес API"
      /> */}
    </div>
  );
};

export default GismtConfig;
