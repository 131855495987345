export const SET_LOADING = 'LEGAL/SET_LOADING';
export const SET_SUCCESS = 'LEGAL/SET_SUCCESS';
export const SET_ERROR = 'LEGAL/SET_ERROR';
export const SET_COMPANY = 'LEGAL/SET_COMPANY';
export const SET_SUZ = 'LEGAL/SET_SUZ';
export const SET_GISMT = 'LEGAL/SET_GISMT';
export const SET_CATALOG = 'LEGAL/SET_CATALOG';
export const START_UPDATE_COMPANY = 'LEGAL/START_UPDATE_COMPANY';
export const START_ADD_COMPANY = 'START_ADD_COMPANY';
export const SUCCESS_UPDATE = 'LEGAL/SUCCESS_UPDATE';
export const SUCCESS_CREATE = 'LEGAL/SUCCESS_CREATE';
export const RESET_STATE = 'LEGAL/RESET_STATE';
export const SUCCESS_CONNECTION_ID = 'LEGAL/SUCCESS_CONNECTION_ID';
export const RESET_CREATE = 'LEGAL/RESET_CREATE';
export const START_FETCHING_COMPANY
 = 'START_FETCHING_COMPANY';

const initialState = {
  isLoading: false,
  error: null,
  companyInfo: {},
  successUpdate: false,
  successCreate: false,
  successUpdateConnectionId: false
};

export const legalFacesReducer = (state = initialState, action) => {
  switch (action.type) {
    case RESET_STATE:
      return {
        ...state,
        isLoading: false,
        error: null,
        companyInfo: {},
        successUpdate: false
      };

    case SET_LOADING:
      case START_FETCHING_COMPANY:
      return {
        ...state,
        isLoading: true
      };

    case SET_SUCCESS:
      return {
        ...state,
        isLoading: false,
        error: null
      };

    case SET_ERROR:
      return {
        ...state,
        error: action.payload,
        isLoading: false
      };

    case SET_COMPANY:
      return {
        ...state,
        companyInfo: action.payload
      };

    case SET_SUZ:
      return {
        ...state,
        suzInfo: action.payload
      };

    case SET_GISMT:
      return {
        ...state,
        gismtInfo: action.payload
      };

    case SET_CATALOG:
      return {
        ...state,
        catalogInfo: action.payload
      };

    case START_UPDATE_COMPANY:
      return {
        ...state,
        isLoading: true
      };

    case START_ADD_COMPANY:
      return {
        ...state,
        isLoading: true
      };

    case SUCCESS_UPDATE:
      return {
        ...state,
        successUpdate: true,
        isLoading: false,
        error: null
      };

    case SUCCESS_CREATE:
      return {
        ...state,
        successCreate: true,
        isLoading: false,
        error: null
      };

    case RESET_CREATE:
      return {
        ...state,
        successCreate: false
      };

    case SUCCESS_CONNECTION_ID:
      return {
        ...state,
        successUpdate: false,
        successUpdateConnectionId: true,
        isLoading: false,
        error: null
      };

    default:
      return state;
  }
};

export const startLoadingCompanyInfoAC = (payload) => ({ type: SET_LOADING, payload });
export const setErrorAC = (payload) => ({ type: SET_ERROR, payload });
export const setSuccessAC = () => ({ type: SET_SUCCESS });
export const setSuccessConnectionId = () => ({ type: SUCCESS_CONNECTION_ID });
export const setCompanyAC = (payload) => ({ type: SET_COMPANY, payload });
export const startFetchingCompany = (id) => ({type: START_FETCHING_COMPANY, id})
export const startUpdateCompanyAC = (payload) => ({ type: START_UPDATE_COMPANY, payload });
export const startAddCompnay = (payload) => ({ type: START_ADD_COMPANY, payload });
export const successUpdateAC = () => ({ type: SUCCESS_UPDATE });
export const successCreate = () => ({ type: SUCCESS_CREATE });
export const resetSuccessCreate = () => ({ type: RESET_CREATE });
export const resetStateAC = () => ({ type: RESET_STATE });
export const setSuzAC = (payload) => ({ type: SET_SUZ, payload });
export const setGismtAC = (payload) => ({ type: SET_GISMT, payload });
export const setCatalogAC = (payload) => ({ type: SET_CATALOG, payload });
