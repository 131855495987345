import React, { useState } from 'react';
import s from '../index.module.scss';
import st from './options.module.scss';
import Input from '../../../components/Forms/Input';
import InputWithProps from '../../../components/Forms/InputWithProps';
import SwitchCheckbox from '../../../components/Forms/Toggle';
// import { PrimaryButton } from 'office-ui-fabric-react';
import SettingsAPI from '../../../api/settingsAPI';
import { SimpleConfirm } from '../../../components/Confirm/SimpleConfirm';
import { useField } from 'formik';

const SuzConfig = ({ companyId, values }) => {
  const [clientToken, setClientToken] = useState(null);
  // const [omsConnection, setOmsConnection] = useState(null);
  const [isOmsConnection, setIsOmsConnection] = useState(false);
  const [isConfirmShowed, setIsConfirmShowed] = useState(false);
  const [message, setMessage] = useState(null);
  const [omsConnectionField, _, omsIdFieldHelpers] = useField('omsConnection');

  const getNewConnectionId = async (companyId) => {
    const res = await SettingsAPI.getConnectionId(companyId);
    // const res = {
    //   omsConnection: '5b6d37db-2276-4e93-96bd-d24e30408acc',
    //   message:
    //     'Получен новый omsConnection "5b6d37db-2276-4e93-96bd-d24e30408acc". Для корректной работы перезапустите приложение.',
    //   status: true
    // };
    if (res.status) {
      setClientToken('');
      omsIdFieldHelpers.setValue(res.omsConnection);
      setIsOmsConnection(true);
      setMessage(res.message);
      setIsConfirmShowed(true);
    }
  };

  // console.log('values-oms-connnection', omsConnectionField.value);

  return (
    <div className={s.suz}>
      <h3 className={s.title}>Конфигурация СУЗ</h3>
      {/* <PrimaryButton
        type="button"
        className={s.btn}
        disabled={false}
        onClick={() => getNewConnectionId(companyId)}
        text="Получить новый идентификатор соединения"
      /> */}
      <button onClick={() => getNewConnectionId(companyId)} type="button" className={st.fieldLabel}>
        Получить идентификатор соединения
      </button>
      <Input name="omsId" type="text" label="Уникальный идентификатор СУЗ" />
      {/* <InputWithProps name="clientToken" type="text" label="Токен авторизации" omsConnectionProp={clientToken} isOmsConnection={isOmsConnection} /> */}
      <InputWithProps
        name="omsConnection"
        type="text"
        label="Идентификатор соединения"
        omsConnectionProp={omsConnectionField.value}
        isOmsConnection={isOmsConnection}
      />
      <Input name="participantInn" type="text" label="ИНН Участника" />
      <Input name="ownerInn" type="text" label="ИНН Компании" />
      <Input name="producerInn" type="text" label="ИНН Производителя" />

      {/* <SwitchCheckbox name="activeSuz" label="Активность" />

      <Input name="suzDevelopmentApiUrl" type="text" label="Тестовый адрес API" disabled />
      <Input name="suzProductionApiUrl" type="text" label="Боевой адрес API" disabled /> */}

      <SwitchCheckbox
        name="isProductionApi"
        label="Выбор API"
        onText="Боевой адрес API"
        offText="Тестовый адрес API"
      />
      {isConfirmShowed && (
        <SimpleConfirm
          subText={message}
          okText="OK"
          onSend={() => setIsConfirmShowed(false)}
          // setIsShow={setIsConfirmShowed}
        />
      )}
    </div>
  );
};

export default SuzConfig;
